import React, { FC } from 'react'
import { Score } from './Score/Score'
import styles from './TeamVsTeamSection.module.scss'
import { Team } from './Team/Team'
import { Team as TeamProps } from 'components/cards/GameScheduleCard/gameScheduleType'

interface Props {
  homeTeam: TeamProps
  awayTeam: TeamProps
  showScore: boolean
  hasGameStarted: boolean
}

export const TeamVsTeamSection: FC<Props> = ({
  homeTeam,
  awayTeam,
  showScore,
  hasGameStarted
}) => {
  return (
    <div className={styles.TeamVsTeamContainer}>
      <Team
        logo={homeTeam.logo}
        name={homeTeam.name}
        homeTeam={true}
        abbreviation={homeTeam.abbreviation}
      />
      <Score
        homeScore={homeTeam.score}
        awayScore={awayTeam.score}
        showScore={showScore}
        hasGameStarted={hasGameStarted}
      />
      <Team
        logo={awayTeam.logo}
        name={awayTeam.name}
        homeTeam={false}
        abbreviation={awayTeam.abbreviation}
      />
    </div>
  )
}
