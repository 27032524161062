import React, { FC } from 'react'
import { SearchField, ColorType } from 'components/Searchfield/SearchField'
import styles from './MobileSearchField.module.scss'
interface Props {
  onSearch: (searchInput: string) => void
  searchFieldIsFocused: boolean
  searchFieldColor: ColorType
  onBackArrowClick: () => void
}

export const MobileSearchField: FC<Props> = ({
  onSearch,
  searchFieldIsFocused,
  searchFieldColor,
  onBackArrowClick
}) => (
  <div className={styles.SearchFieldContainer}>
    <SearchField
      type={searchFieldColor}
      onSearch={onSearch}
      isFocused={searchFieldIsFocused}
      showBackArrow={true}
      onBackArrowClick={onBackArrowClick}
    />
  </div>
)
