import React, { FC } from 'react'

const ShareIcon: FC = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8962 5.85709H12.6462C11.8506 5.85709 11.0875 6.17316 10.5249 6.73577C9.96228 7.29838 9.64621 8.06144 9.64621 8.85709V12.6071"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3962 10.3571L20.8962 5.85709L16.3962 1.35709"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8962 14.8571V22.3571C17.8962 22.7549 17.7382 23.1364 17.4569 23.4177C17.1756 23.6991 16.794 23.8571 16.3962 23.8571H2.89621C2.49838 23.8571 2.11685 23.6991 1.83555 23.4177C1.55425 23.1364 1.39621 22.7549 1.39621 22.3571V10.3571C1.39621 9.95926 1.55425 9.57773 1.83555 9.29643C2.11685 9.01512 2.49838 8.85709 2.89621 8.85709H5.14621"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShareIcon
