import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import useCurrentUser from '../../../../hooks/CurrentUser'
import MessageInput from '../../Chat/MessageInput/MessageInput'
import styles from './CommentsInput.module.scss'
import authenticationActions from '../../../../redux-store/authentication/authentication.actions'
import { postCommentMessage } from '../../../../api/viewer/Comments/postComment'
import { Comment } from '../../../../interfaces'

interface CommentsInputProps {
  mediaObjectId: string
  onPostedComment: (comment: Comment) => void
}
const CommentsInput: FC<CommentsInputProps> = ({
  mediaObjectId,
  onPostedComment
}) => {
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()

  const sendMessage = async (textMessage: string) => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      return false
    }
    if (textMessage !== '') {
      const newComment = await postCommentMessage({
        text: textMessage,
        mediaObjectIdent: mediaObjectId
      })
      onPostedComment(newComment.data)
    }
    return false
  }

  return (
    <div
      className={styles.CommentsInput}
      data-testid="comments-input-container"
    >
      <MessageInput
        onSuccess={(text) => sendMessage(text)}
        userIsBlocked={false}
      />
    </div>
  )
}
export default CommentsInput
