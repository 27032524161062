import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface MediaObjectParams {
  company?: string
  game_ident?: string
  description_url?: string
  media_object_slug?: string
}

export const getMediaObject = async (params: MediaObjectParams) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'media_object/watch',
      method: ApiEndpoint.METHODS.GET,
      authenticatable: false,
      params
    })
    return resultResponse.data
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
