import React, { FC } from 'react'
import BroadcastThumbnail from '../../../../components/cards/BroadcastThumbnail'
import { BroadcastThumbnailInterface } from '../../../../components/cards/BroadcastThumbnail/broadcastCardTypes'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'
import CompetitionText from './CompetionText'
import { CompetitionInterface } from './CompetionText/competitionInterface'
import { ProducedBy } from './ProducedBy/ProducedBy'

import styles from './BroadcastSectionDetails.module.scss'

interface Props {
  title: string
  producer: string | undefined
  competition: CompetitionInterface
  thumbnailContent: BroadcastThumbnailInterface
}
export const BroadcastSectionDetails: FC<Props> = ({
  title,
  producer,
  competition,
  thumbnailContent
}) => {
  const currentBreakpoint = useCurrentBreakpoint()

  const thumbnailSize =
    currentBreakpoint > Breakpoint.sm ? 'medium' : 'keepRatio'

  return (
    <div
      className={styles.BroadcastSectionDetails}
      data-testid="broadcast-section-details-container"
    >
      <BroadcastThumbnail
        content={thumbnailContent}
        shared={false}
        size={thumbnailSize}
        showDetails={false}
        disableLink
      />

      <div className={styles.BottomContainer}>
        {competition.type && competition.name && (
          <CompetitionText type={competition.type} name={competition.name} />
        )}
        <h1 className={styles.Title}>{title}</h1>

        {producer && <ProducedBy producer={producer} />}
      </div>
    </div>
  )
}
