import React, { FC, useEffect, useState } from 'react'
import { Country } from 'api/countries/getCountriesList'
import styles from './SearchableCountryList.module.scss'
import classNames from 'classnames'
import { ColorType, SearchField } from 'components/Searchfield/SearchField'
import CheckIcon from 'assets/icons/CheckIcon'
import { Trans } from '@lingui/macro'

interface Props {
  countryList: Country[]
  selectedAlpha2: string
  onCountrySelect: (country: Country) => void
}

export const SearchableCountrylist: FC<Props> = ({
  countryList,
  selectedAlpha2,
  onCountrySelect
}) => {
  const [searchText, setSearchText] = useState('')
  const [searchParam] = useState(['name', 'countryCode'])
  const [filteredCountryList, setFilteredCountryList] = useState(countryList)
  const search = (text: string) => {
    if (text === '') {
      setFilteredCountryList(countryList)
    } else {
      setFilteredCountryList(filteredList())
    }
  }
  const filteredList = () => {
    return countryList.filter((item: any) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
        )
      })
    })
  }

  useEffect(() => {
    search(searchText)
  }, [searchText])

  return (
    <div className={styles.Container}>
      <SearchField
        type={ColorType.Light}
        onSearch={(searchText: string) => setSearchText(searchText)}
        onClearSearchText={() => setSearchText('')}
        showBackArrow={false}
        onBackArrowClick={() => null}
      />
      <div className={styles.List}>
        {filteredCountryList.length > 0 ? (
          filteredCountryList?.map((country: Country) => {
            const isSelected = country.alpha2 === selectedAlpha2
            return (
              <div
                className={classNames(styles.Item)}
                key={country.alpha2}
                onClick={() => onCountrySelect(country)}
                tabIndex={0}
                onKeyDown={() => onCountrySelect(country)}
              >
                <div className={styles.CountryContent}>
                  <img
                    className={styles.Flag}
                    src={country.flagUrl}
                    alt={country.name}
                  />
                  <div className={styles.Name}>{country.name}</div>
                  <div
                    className={styles.CountryCode}
                  >{`+ ${country.countryCode}`}</div>
                </div>
                {isSelected && (
                  <div className={styles.IsSelectedIcon}>
                    <CheckIcon />
                  </div>
                )}
              </div>
            )
          })
        ) : (
          <div className={styles.Item}>
            <Trans>No results for {searchText}</Trans>
          </div>
        )}
      </div>
    </div>
  )
}
