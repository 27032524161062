import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import { t } from '@lingui/macro'
import { ViewType } from '../../../../redux-store/stream/stream.interface'
import { EventSummary } from '../ActionPanel.interface'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)

export const formatDateInText = (startAt: number, dateFormat?: string) => {
  const todaysDate = dayjs(new Date()).unix()

  const getDateString = () => {
    dayjs.unix(startAt)
    if (dayjs.unix(startAt).isTomorrow()) {
      return `${t`Tomorrow at`} ${dayjs.unix(startAt).format('HH:mm')}`
    }
    return dayjs.unix(startAt).format(dateFormat || 'D MMM HH:mm')
  }
  return { getDateString, todaysDate }
}

export const getActionLabelText = (
  event: EventSummary,
  viewType: ViewType,
  isVideoPlaying: boolean
) => {
  const title = event.title || `${event.homeTeamName} - ${event.awayTeamName}`

  if (isVideoPlaying) {
    return title
  }

  switch (viewType) {
    case ViewType.REPLAY:
      return `${t`Replay from `} ${formatDateInText(
        event.liveStartsAt,
        'D MMM'
      ).getDateString()}`
    case ViewType.VIDEO:
      return `${t`Video uploaded`} ${formatDateInText(
        event.publishedAt,
        'D MMM'
      ).getDateString()}`
    case ViewType.UPCOMING:
      return `${t`Broadcast starts`} ${formatDateInText(
        event.liveStartsAt
      ).getDateString()}`
    case ViewType.LIVE:
      return t`Live now`
    default:
      return title
  }
}
