import React, { FC } from 'react'
import classNames from 'classnames'
import { ViewType } from '../../../../redux-store/stream/stream.interface'

import styles from './ActionLabel.module.scss'
import { ActionLabelBadge } from './ActionLabelBadge'
import { ActionLabelIcon } from './ActionLabelIcon'
import { ActionLabelText } from './ActionLabelText'
import { BroadcastInformation, EventSummary } from '../ActionPanel.interface'
import { BroadcastMessage } from 'pages/ViewerPage/BroadcastMessage/BroadcastMessage'

interface ActionLabelProps {
  event: EventSummary
  isVideoPlaying: boolean
  isReport: boolean
  broadcastMessage: BroadcastInformation
  viewType: ViewType
}

export const ActionLabel: FC<ActionLabelProps> = ({
  event,
  isVideoPlaying,
  isReport,
  broadcastMessage,
  viewType
}) => {
  return broadcastMessage ? (
    <BroadcastMessage
      severity={broadcastMessage.color}
      message={broadcastMessage.message}
      lightBackground={isVideoPlaying}
    />
  ) : (
    <div
      className={classNames(styles.ActionLabelContainer, {
        [styles.ActionLabelContainerIsPlaying]: isVideoPlaying
      })}
    >
      {isVideoPlaying ? (
        <ActionLabelBadge
          event={event}
          viewType={viewType}
          isReport={isReport}
        />
      ) : (
        <ActionLabelIcon viewType={viewType} />
      )}
      <ActionLabelText
        event={event}
        isVideoPlaying={isVideoPlaying}
        viewType={viewType}
      />
    </div>
  )
}
