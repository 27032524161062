/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { MediaObject } from '../../../../interfaces'

import GameThumbNail from '../../../../components/cards/BroadcastThumbnail/Game/TeamVsTeam/GameThumbNail'
import { BroadcastThumbnailType } from '../../../../components/cards/BroadcastThumbnail/broadcastCardTypes'

import styles from './ChatHeader.module.scss'

interface Props {
  title: string
  subText: string
  thumbnailHeader: string
  mediaObject: MediaObject
}
const ChatHeader: FC<Props> = ({
  title,
  subText,
  thumbnailHeader,
  mediaObject
}) => {
  const gameContent = mediaObject && {
    duration: mediaObject.duration,
    homeTeam: {
      // @ts-ignore
      name: mediaObject.game
        ? mediaObject.game?.home_team?.name
        : // @ts-ignore
          mediaObject.home_team.name,
      // @ts-ignore
      badge_url: mediaObject.game
        ? mediaObject.game?.home_team?.badge_url
        : // @ts-ignore
          mediaObject.home_team.badge_url,
      slug: '',
      id: 1,
      country_code: 'XX',
      shirt_colors: []
    },
    awayTeam: {
      // @ts-ignore
      name: mediaObject.game
        ? mediaObject.game?.away_team?.name
        : // @ts-ignore
          mediaObject.away_team.name,
      badge_url: mediaObject.game
        ? mediaObject.game?.away_team?.badge_url
        : // @ts-ignore
          mediaObject.away_team.badge_url,
      slug: '',
      id: 2,
      country_code: 'XX',
      shirt_colors: []
    }
  }

  return (
    <>
      <div className={styles.ChatHeader}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.SubText}> {subText}</div>

        <div className={styles.ChatThumbnail}>
          {gameContent && gameContent.homeTeam && (
            <div className={styles.ChatThumbnailContainer}>
              <div className={styles.ChatThumbnailHeader}>
                {thumbnailHeader}
              </div>

              <GameThumbNail
                homeTeam={gameContent.homeTeam}
                awayTeam={gameContent.awayTeam}
                thumbnailSize="xxSmall"
                isReplay={false}
                startTime={0}
                duration={gameContent.duration}
                isLive={false}
                highlightFromDate={0}
                type={BroadcastThumbnailType.Game}
                hasHoverEffect={false}
                showRootDomainLabel={false}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default ChatHeader
