import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import styles from './BroadcastMessage.module.scss'
import { MessageIcon } from './MessageIcon'
import { getAlertType } from './getAlertType'

type BroadcastMessageProps = {
  severity: string
  message: string
  lightBackground: boolean
}

export const BroadcastMessage: React.FC<BroadcastMessageProps> = ({
  severity,
  message,
  lightBackground
}) => {
  const alertType = getAlertType(severity)
  const newMessage = useSelector(
    (state: any) => state.subscriber.newBroadcastMessage
  )
  const [alertTypeState, setAlertTypeState] = useState(alertType)
  const [messageState, setMessageState] = useState(message)

  useEffect(() => {
    if (newMessage) {
      const alertType = getAlertType(newMessage.color)
      setAlertTypeState(alertType)
      setMessageState(newMessage.text)
    }
  }, [newMessage])

  return (
    <div
      className={classNames(styles.BroadcastMessage, {
        [styles.LightBackground]: lightBackground
      })}
    >
      <div className={styles.Icon}>
        <MessageIcon alertType={alertTypeState} />
      </div>
      <div className={styles.TextContent}>{messageState}</div>
    </div>
  )
}
