/* eslint-disable react/require-default-props */
import React, { FC } from 'react'
import dayjs from 'dayjs'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { Game } from '../../../../interfaces'
import { ScoreboardTeamBadge } from './ScoreboardTeamBadge/ScoreboardTeamBadge'
import { ScoreboardDetails } from './ScoreboardDetails/ScoreboardDetails'
import styles from './Scoreboard.module.scss'

interface ScoreboardProps {
  game: Game
  leagueName?: string
}

export const Scoreboard: FC<ScoreboardProps> = ({ game, leagueName }) => {
  const isBeforeStart = dayjs().isBefore(dayjs.unix(game.start_at))
  const isAfterEnd = dayjs().isAfter(dayjs.unix(game.end_at))
  const showScore =
    game.show_results &&
    isAfterEnd &&
    !(game.home_score === null || game.away_score === null)

  let infoMessage
  if (isBeforeStart) {
    infoMessage = dayjs.unix(game.start_at).format('D MMMM HH:mm')
  } else if (isAfterEnd) {
    infoMessage = t`Final result`
  } else {
    infoMessage = t`Live now`
  }

  return (
    <div className={styles.Scoreboard}>
      <div className={styles.ScoreboardTitleContainer}>
        {!!leagueName && (
          <h5 data-test-id="league-name" className={styles.ScoreboardTitle}>
            {leagueName}
          </h5>
        )}
        <div className={styles.ScoreboardSubtitle}>{infoMessage}</div>
      </div>
      <div className={styles.ScoreboardTeamsContainer}>
        <div className={styles.ScoreboardTeam}>
          <ScoreboardTeamBadge team={game.home_team!} />
          {showScore && (
            <div
              data-testid="home-team-score"
              className={styles.ScoreboardTeamsScore}
            >
              {game.home_score}
            </div>
          )}
        </div>
        <div className={classNames(styles.ScoreboardTeam, styles.IsAwayTeam)}>
          <ScoreboardTeamBadge team={game.away_team!} isAwayTeam />
          {showScore && (
            <div
              data-testid="away-team-score"
              className={styles.ScoreboardTeamsScore}
            >
              {game.away_score}
            </div>
          )}
        </div>
      </div>
      <ScoreboardDetails location={game?.location?.name} />
    </div>
  )
}
