import { i18n } from '@lingui/core'
import { en, sv, fi, de } from 'make-plural/plurals'
import dayjs from 'dayjs'
import { Locale } from 'dayjs/locale/*'
import svLocale from 'dayjs/locale/sv'
import fiLocale from 'dayjs/locale/fi'
import deLocale from 'dayjs/locale/de'
import { User } from '../interfaces'

export const locales = [
  { abbr: 'en', name: 'English' },
  { abbr: 'sv', name: 'Svenska' },
  { abbr: 'fi', name: 'Suomi' },
  { abbr: 'de', name: 'Deutsch' }
]

export function activateDateSettings(locale: string) {
  dynamicActivate(locale)
  // Set locale on dayjs globally
  let preferredDayjsLocale: string | Locale = 'en'
  switch (getCurrentLocale()) {
    case 'sv':
      preferredDayjsLocale = svLocale
      break
    case 'fi':
      preferredDayjsLocale = fiLocale
      break
    case 'de':
      preferredDayjsLocale = deLocale
      break
    default:
      preferredDayjsLocale = 'en'
  }
  dayjs.locale(preferredDayjsLocale)
}

const findDefaultLocale = (): string => {
  if (navigator.language) {
    // Try to use browser default
    const browserLang = navigator.language.substring(0, 2)
    if (matchLocale(browserLang)) {
      return browserLang
    }
  }
  // Fallback to 'en'
  return 'en'
}

const findPreferedLocale = (
  suggested: string | undefined = undefined
): string => {
  const sessionLocale = sessionStorage.getItem('language') || undefined
  if (sessionLocale && matchLocale(sessionLocale)) {
    return sessionLocale
  }
  if (suggested && matchLocale(suggested)) {
    return suggested
  }
  return defaultLocale
}

const matchLocale = (locale: string): boolean => {
  for (let i = 0; i < locales.length; i += 1) {
    if (locales[i].abbr === locale) {
      return true
    }
  }
  return false
}

export const defaultLocale = findDefaultLocale()

export const getCurrentLocale = (user?: User): string => {
  const sessionLocale = sessionStorage.getItem('language') || user?.locale
  if (sessionLocale && matchLocale(sessionLocale)) {
    return sessionLocale
  }
  return defaultLocale
}

export const getFullCurrentLocale = (): string => {
  switch (getCurrentLocale()) {
    case 'sv':
      return 'sv_SE'
    case 'en':
      return 'en_US'
    case 'fi':
      return 'fi_FI'
    case 'de':
      return 'de_DE'
    default:
      return 'en_US'
  }
}

i18n.loadLocaleData({
  en: { plurals: en },
  sv: { plurals: sv },
  fi: { plurals: fi },
  de: { plurals: de }
})
// i18n needs to load "something" initially or it will complain, making things look broken.
i18n.load(defaultLocale, {})
i18n.activate(defaultLocale)

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(suggestedLocale: string | undefined) {
  if (suggestedLocale) {
    // Prefer user choice for this session, then user language, then browser language, then english
    // currentLocale = sessionStorage.getItem('language') || suggestedLocale || defaultLocale
    const preferred = findPreferedLocale(suggestedLocale)
    window.document.documentElement.lang = suggestedLocale
    const { messages } = await import(`../locales/${preferred}/messages`)
    i18n.load(preferred, messages)
    i18n.activate(preferred)
    sessionStorage.setItem('language', preferred)
  }
}
