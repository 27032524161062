import React, { FC, useEffect, useState } from 'react'
import { User, UserCompany } from 'interfaces'
import styles from './UserSection.module.scss'
import { t } from '@lingui/macro'
import { UploadUserImgButton } from 'components/buttons/UploadUserImgButton/UploadUserImgButton'
import { useDispatch } from 'react-redux'
import userActions from 'redux-store/user/user.actions'
interface Props {
  currentUser: User
}

export const UserSection: FC<Props> = ({ currentUser }) => {
  const dispatch = useDispatch()

  const [userImg, setUserImg] = useState(currentUser.thumbnail)
  const amountFollowing = currentUser.followed_companies.length || 0
  const amountSubscriptions =
    currentUser.followed_companies && currentUser.followed_companies.length > 0
      ? currentUser.supported_and_followed_companies.filter(
          (item: UserCompany) => item.is_monthly_supporter
        ).length
      : 0

  useEffect(() => {
    setUserImg(currentUser.thumbnail)
  }, [currentUser])
  const subscriptionText = amountSubscriptions
    ? t`${amountSubscriptions} Subscriptions`
    : t`0 Subscriptions`

  const followingText = amountFollowing
    ? t`${amountFollowing} Following`
    : t`0 Following`
  return (
    <div className={styles.UserSection}>
      <div className={styles.ImageContainer}>
        <img src={userImg} alt={t`proflie`} data-testid="user-image" />
        <div className={styles.UploadIcon}>
          <UploadUserImgButton
            onUploadSuccess={() => dispatch(userActions.updateUser())}
          />
        </div>
      </div>
      <div className={styles.TextContent}>
        <div data-testid="user-name" className={styles.Name}>
          {currentUser.name}
        </div>
        <div className={styles.Email}>{currentUser.email}</div>
        <div className={styles.UserEngagement}>
          <div data-testid="user-subscriptions">{subscriptionText}</div>
          <div>|</div>
          <div data-testid="user-following">{followingText}</div>
        </div>
      </div>
    </div>
  )
}
