import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Route, Switch, useLocation } from 'react-router-dom'
import { getSelectedLink } from '../../ViewerPage/getSelectedLink'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import Breadcrumb from '../Header/Breadcrumb/Breadcrumb'
import { SettingsContentHeader } from '../../../components/page/header/SettingsContentHeader/SettingsContentHeader'
import {
  SettingsMenuItemId,
  settingsSubmenuLinks
} from './settingsSubmenuLinks'
import { Submenu } from '../../../components/page/Submenu/Submenu'
import { AccountForm } from './AccountDetails/AccountForm'
import { Branding } from './Branding/Branding'
import { getFilteredSettingsMenuLinks } from './getFilteredSettingsLinks'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'

import { RolesState } from '../../../redux-store/roles/roles.interface'
import { rolesSelector } from '../../../redux-store/roles/roles.reducer'
import { OverviewForm } from './Overview/OverviewForm'
import { useFeatureFlags } from '../../../featureFlags/useFeatureFlags'
import { FeatureFlagsAvailable } from '../../../featureFlags/FeatureFlagsAvailable'
import { InvoiceContactForm } from './InvoiceContact/InvoiceContactForm'
import { SettingsTabContainer } from './SettingsTabContainer'
import { getAdminPath } from 'helpers/getAdminPath'
import styles from './ChannelSettings.module.scss'

export const ChannelSettings = () => {
  const location = useLocation()
  const currentBreakpoint = useCurrentBreakpoint()
  const channel = useSelector(channelSelector)
  const channelName = channel.data?.name
  const channelSlug = channel.data?.subdomain
  const { pathname } = location

  const selectedLink = getSelectedLink(settingsSubmenuLinks, pathname)
  const [selectedMenu, setSelectedMenu] = useState(
    !selectedLink ? SettingsMenuItemId.Account : selectedLink.id
  )
  const handleSelectSubmenu = (id: number) => {
    setSelectedMenu(id)
  }

  const roles: RolesState = useSelector(rolesSelector)

  const { getFlag } = useFeatureFlags()

  const showOverviewSettingFlag = getFlag(
    FeatureFlagsAvailable.ChannelOverviewSetting
  )

  const filteredMenuItems = getFilteredSettingsMenuLinks(
    settingsSubmenuLinks,
    roles
  )
  const adminPath = getAdminPath()
  const advancedSettingsHref = `${adminPath}/admin/settings/playpage/playpage_setting/edit?select_company=${channelSlug}`

  const showLightBackground = currentBreakpoint > Breakpoint.sm

  return (
    <>
      <div className={styles.BreadcrumbContainer}>
        <Breadcrumb />
      </div>
      <SettingsContentHeader
        title={t`Settings`}
        buttonLabel={t`Advanced settings`}
        href={advancedSettingsHref}
      />
      <Submenu
        selectedItemId={selectedMenu}
        menuItems={filteredMenuItems}
        onItemSelect={(id: number) => handleSelectSubmenu(id)}
        currentPage={`${channelSlug}/settings`}
      />
      <Switch>
        <Route exact path="/:company/settings/channel">
          <SettingsTabContainer showLightBackground={showLightBackground}>
            <OverviewForm
              channelSlug={channelSlug}
              onSubmitSuccess={() => null}
            />
          </SettingsTabContainer>
        </Route>
        <Route exact path="/:company/settings/account">
          <SettingsTabContainer showLightBackground={showLightBackground}>
            <AccountForm
              channelSlug={channelSlug}
              channelName={channelName}
              onSubmitSuccess={() => null}
            />
          </SettingsTabContainer>
        </Route>
        {showOverviewSettingFlag && (
          <Route exact path="/:company/settings/invoice">
            <SettingsTabContainer showLightBackground={showLightBackground}>
              <InvoiceContactForm
                channelSlug={channelSlug}
                onSubmitSuccess={() => {}}
              />
            </SettingsTabContainer>
          </Route>
        )}
        {roles.isSuperadmin && (
          <Route exact path="/:company/settings/branding">
            <SettingsTabContainer showLightBackground={showLightBackground}>
              <Branding />
            </SettingsTabContainer>
          </Route>
        )}
      </Switch>
    </>
  )
}
