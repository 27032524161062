/* eslint-disable @typescript-eslint/indent */
import { FeatureFlagsAvailable } from './featureFlags/FeatureFlagsAvailable'
import { getCustomChannelUrl, getIsSpecialDomain } from './getCustomChannelUrl'

export const determineClientUrlAndRedirectIfNeeded = async (
  getFlag: (flag: FeatureFlagsAvailable) => boolean,
  setFlag: (flag: FeatureFlagsAvailable, value: boolean) => void
): Promise<string | undefined> => {
  const isPoweredBy = getFlag(FeatureFlagsAvailable.PoweredBy)

  const now = Date.now() / 1000
  const isSpecialDomain = getIsSpecialDomain(window.location.hostname)
  if (isSpecialDomain && isPoweredBy) {
    setFlag(FeatureFlagsAvailable.PoweredBy, false)
  }

  const redirect = await getCustomChannelUrl(
    window.location,
    isPoweredBy,
    now,
    getFlag,
    setFlag
  ).catch((error: any) => {
    console.log('getCustomChannelUrl', error)
  })

  if (redirect && redirect.isPrerelease && !isSpecialDomain) {
    setFlag(FeatureFlagsAvailable.PoweredBy, true)
  }

  if (redirect && redirect.url && redirect.url !== window.location.href) {
    window.location.href = redirect.url
  }
  if (
    redirect &&
    redirect.keyRemoved &&
    redirect.url !== window.location.href
  ) {
    window.location.reload()
  }
  // return redirect
  return undefined
}
