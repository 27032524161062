export const getBreadcrumbTitle = (mediaPlayerData: any) => {
  const breadcrumbMediaText = mediaPlayerData?.mediaObject?.game?.title
    ? mediaPlayerData?.mediaObject?.game?.title
    : mediaPlayerData?.mediaObject?.title
  const breadcrumbReporterText = mediaPlayerData?.game?.title
    ? mediaPlayerData?.game?.title
    : ''

  const breadcrumbTitle = mediaPlayerData?.mediaObjectAvailable
    ? breadcrumbMediaText
    : breadcrumbReporterText
  return breadcrumbTitle
}
