import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const useCountdown = (
  countdownTo: number,
  format: string = 'HH:mm:ss'
) => {
  const [remainingTime, setRemainingTime] = useState<string>('')

  useEffect(() => {
    const intervalId = setInterval(() => {
      const diff = dayjs.unix(countdownTo).diff(dayjs())

      if (diff > 0) {
        setRemainingTime(dayjs.duration(diff).format(format))
      } else if (diff < 0 && !!remainingTime) {
        setRemainingTime('')
      }
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [countdownTo])

  return { remainingTime }
}
