import { t } from '@lingui/macro'
import React, { FC } from 'react'
import classNames from 'classnames'
import { formatCurrency } from '../../../../../helpers/formatHelper'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import styles from '../ActionButtons.module.scss'
import TicketsIcon from '../../Icons/TicketsIcon'
import { EventSummary } from '../../ActionPanel.interface'
import { AccessRestrictionType } from 'interfaces'

interface BuyStreamButtonProps {
  event: EventSummary
  onPurchase: () => void
  isFluid: boolean
}

export const BuyStreamButton: FC<BuyStreamButtonProps> = ({
  event,
  isFluid,
  onPurchase
}) => {
  const isPPVandSubscriptionRequired =
    event.accessRestriction &&
    [AccessRestrictionType.PaymentAndSubscriptionRequired].includes(
      event.accessRestriction
    )
  const purchaseButtonText = isPPVandSubscriptionRequired
    ? t`Buy stream`
    : `${t`Buy stream from `} ${formatCurrency(
        event.minEventAmount,
        event.amountCurrency
      )}`

  return (
    <div
      data-testid="ticket-btn"
      className={classNames({
        [styles.IsFluid]: isFluid
      })}
    >
      <IconTextButton
        externalStyle={styles.PurchaseButton}
        buttonSize="Medium"
        id="buyBroadcastBtn"
        label={purchaseButtonText}
        onClick={onPurchase}
        type={ButtonType.CTA}
        isFluid={isFluid}
      >
        <div className={styles.TicketIcon}>
          <TicketsIcon />
        </div>
      </IconTextButton>
    </div>
  )
}
