import React from 'react'

const GeoBlockedIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1162_66437)">
      <path
        d="M49.5 23.832C49.5 40.1654 28.5 54.1654 28.5 54.1654C28.5 54.1654 7.5 40.1654 7.5 23.832C7.5 18.2625 9.71249 12.9211 13.6508 8.98279C17.589 5.04452 22.9305 2.83203 28.5 2.83203C34.0695 2.83203 39.411 5.04452 43.3492 8.98279C47.2875 12.9211 49.5 18.2625 49.5 23.832Z"
        stroke="#2F3135"
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 30.832C32.366 30.832 35.5 27.698 35.5 23.832C35.5 19.966 32.366 16.832 28.5 16.832C24.634 16.832 21.5 19.966 21.5 23.832C21.5 27.698 24.634 30.832 28.5 30.832Z"
        stroke="#2F3135"
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1162_66437">
        <rect
          width="56"
          height="56"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default GeoBlockedIcon
