import React, { FC } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import styles from './ActionPanel.module.scss'
import { ActionButtons } from './ActionButtons/ActionButtons'
import { ActionLabel } from './ActionLabel/ActionLabel'
import { streamViewTypeSelector } from '../../../redux-store/stream/stream.selectors'
import { BroadcastInformation, EventSummary } from './ActionPanel.interface'

interface ActionPanelProps {
  event: EventSummary
  isVideoPlaying: boolean
  isReport: boolean
  isLiked: boolean
  broadcastMessage: BroadcastInformation
  onDownload: () => void
  onLike: () => void
  onShare: () => void
  onWatch?: () => void
  onEdit: () => void
  onHelp: () => void
  onPurchase?: () => void
  onReportStream: () => void
}

export const ActionPanel: FC<ActionPanelProps> = ({
  isReport,
  isLiked,
  isVideoPlaying,
  event,
  broadcastMessage,
  onDownload,
  onLike,
  onShare,
  onWatch,
  onEdit,
  onHelp,
  onPurchase,
  onReportStream
}) => {
  const viewType = useSelector(streamViewTypeSelector)
  return (
    <div
      className={classNames(
        styles.ActionPanelContainer,
        !isVideoPlaying ? styles.futureEvent : null
      )}
    >
      <ActionLabel
        broadcastMessage={broadcastMessage}
        event={event}
        isVideoPlaying={isVideoPlaying}
        isReport={isReport}
        viewType={viewType}
      />
      <ActionButtons
        isLiked={isLiked}
        event={event}
        onPurchase={onPurchase!}
        onDownload={onDownload}
        onLike={onLike}
        onShare={onShare}
        onWatch={onWatch!}
        onEdit={onEdit}
        onHelp={onHelp}
        onReportStream={onReportStream}
      />
    </div>
  )
}

ActionPanel.defaultProps = {
  onWatch: () => {},
  onPurchase: () => {}
}
