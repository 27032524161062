/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { BroadcastThumbnailInterface } from './broadcastCardTypes'
import BroadcastContentTypeSelector from './BroadcastContentTypeSelector'
import EmptyContent from './Empty'

import styles from './index.module.scss'
import { ThumbnailLink } from './ThumbnailLink'

interface Props {
  content: BroadcastThumbnailInterface | undefined
  shared: boolean
  showDetails: boolean
  size: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'keepRatio'
  showRootDomainLabel?: boolean
  disableLink: boolean
}
const BroadcastThumbnail: FC<Props> = ({
  content,
  shared,
  size,
  showRootDomainLabel,
  showDetails = true,

  disableLink = false
}) => {
  if (!content) return <EmptyContent size={size} />

  const linkPath = content.targetUrl
  const rootDomain = showRootDomainLabel ? content.rootDomain : ''
  return (
    <>
      {shared ? (
        <div className={styles.SharedContainer}>
          <div className={styles.ChannelContainer}>
            <div className={styles.ChannelImageWrapper}>
              <img
                data-testid="channel-img"
                src={content.channelImg}
                alt={content.channelName}
              />
            </div>
            <Link to={content.channelUrl}>
              <div className={styles.ChannelName}>{content.channelName}</div>
            </Link>
          </div>
          <ThumbnailLink link={linkPath} rootDomain={rootDomain}>
            <BroadcastContentTypeSelector
              content={content}
              size={size}
              showDetails={showDetails}
              showRootDomainLabel={showRootDomainLabel}
            />
          </ThumbnailLink>
        </div>
      ) : disableLink ? (
        <BroadcastContentTypeSelector
          content={content}
          size={size}
          showDetails={showDetails}
          showRootDomainLabel={showRootDomainLabel}
        />
      ) : (
        <ThumbnailLink link={linkPath} rootDomain={rootDomain}>
          <BroadcastContentTypeSelector
            content={content}
            size={size}
            showDetails={showDetails}
            showRootDomainLabel={showRootDomainLabel}
          />
        </ThumbnailLink>
      )}
    </>
  )
}
export default BroadcastThumbnail
