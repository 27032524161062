import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import ReporterSection from './ReporterSection/ReporterSection'
import styles from './ReportPage.module.scss'
import { Game } from '../../../interfaces/Game.interface'
import useCurrentUser from '../../../hooks/CurrentUser'
import { userInterface } from '../../../redux-store/userInterface/userInterface.actions'
import authenticationActions from '../../../redux-store/authentication/authentication.actions'
import { onHelpClick } from '../VideoPlayerSection/onHelpClick'
import { ReporterSectionType } from './ReporterSection/reporterSectionType'

interface Props {
  game: Game
  companySlug: string
}

const ReportView: FC<Props> = ({ game, companySlug }) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const reporterData: ReporterSectionType = {
    id: game?.id!,
    liveStartAt: game?.live_start_at!,
    publishedAt: game?.start_at!,
    title: game?.title!,
    broadcastMessage: undefined!,
    isLive: game?.is_live!,
    companySlug: companySlug
  }

  const onEdit = () => {
    const baseURL = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}/admin`
    const trailURL = `${game.id}?select_company=${companySlug}`

    window.location.href = `${baseURL}/games/${trailURL}`
  }

  const report = () => {
    if (currentUser) {
      dispatch(userInterface.openReportContentDialog())
    } else {
      dispatch(authenticationActions.openLoginLayer())
    }
  }
  return (
    <div className={styles.ReportPage}>
      <ReporterSection
        reporterData={reporterData}
        isLiked={false}
        onLike={() => null}
        onDownload={() => console.log('download')}
        onEdit={onEdit}
        onHelp={onHelpClick}
        onReportStream={report}
      />
    </div>
  )
}
export default ReportView
