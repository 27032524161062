import React from 'react'

const Global = () => (
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_982_2385"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="15"
    >
      <path
        d="M19 0H2C0.89543 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H19C20.1046 15 21 14.1046 21 13V2C21 0.89543 20.1046 0 19 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_982_2385)">
      <path
        d="M19 0H2C0.89543 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H19C20.1046 15 21 14.1046 21 13V2C21 0.89543 20.1046 0 19 0Z"
        fill="white"
      />
      <path
        d="M19 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V13C0.5 13.8284 1.17157 14.5 2 14.5H19C19.8284 14.5 20.5 13.8284 20.5 13V2C20.5 1.17157 19.8284 0.5 19 0.5Z"
        stroke="black"
        strokeOpacity="0.1"
      />
    </g>
    <path
      d="M7.5835 7.50001C7.5835 8.27356 7.89079 9.01542 8.43777 9.56241C8.98475 10.1094 9.72661 10.4167 10.5002 10.4167C11.2737 10.4167 12.0156 10.1094 12.5626 9.56241C13.1095 9.01542 13.4168 8.27356 13.4168 7.50001C13.4168 6.72646 13.1095 5.9846 12.5626 5.43762C12.0156 4.89063 11.2737 4.58334 10.5002 4.58334C9.72661 4.58334 8.98475 4.89063 8.43777 5.43762C7.89079 5.9846 7.5835 6.72646 7.5835 7.50001V7.50001Z"
      stroke="#656B6F"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.79742 10.3314C9.40283 9.75271 9.13916 8.70116 9.13916 7.50001C9.13916 6.29886 9.40283 5.24731 9.79742 4.66864"
      stroke="#656B6F"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5835 7.5H13.4168"
      stroke="#656B6F"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.03271 9.05557H12.968"
      stroke="#656B6F"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.03271 5.94446H12.968"
      stroke="#656B6F"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2031 4.66864C11.5977 5.24731 11.8614 6.29886 11.8614 7.50001C11.8614 8.70116 11.5977 9.75271 11.2031 10.3314"
      stroke="#656B6F"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Global
