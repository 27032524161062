/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'

import { Trans } from '@lingui/macro'
import { MediaObject } from '../../interfaces/MediaObject.interface'
import MEDIA_OBJECT from '../../constants/media_object.constants'
import Chat from './Chat/Chat'
import Comments from './Comments/Comments'

import styles from './GameInteractions.module.scss'

interface Props {
  mediaObject: MediaObject
}

const GameInteractions: FC<Props> = ({ mediaObject }) => {
  if (mediaObject.type === MEDIA_OBJECT.TYPES.GAME) {
    return (
      <div className={styles.NoChat}>
        <Trans>Chat is not yet available for this game</Trans>
      </div>
    )
  }

  return mediaObject.enable_comments &&
    (mediaObject.type === MEDIA_OBJECT.TYPES.LIVESTREAM ||
      mediaObject.is_replay) ? (
    <Chat mediaObject={mediaObject} />
  ) : (
    <Comments mediaObject={mediaObject} />
  )
}

export default GameInteractions
