import React, { FC } from 'react'
import { UserInfoSection } from 'components/UserInfoSection/UserInfoSection'
import { User } from 'interfaces'
import { SignOutButton } from 'components/buttons/SignOutButton/SignOutButton'
import { ClickableTextButton } from 'components/buttons/ClickableTextButton/ClickableTextButton'
import { t } from '@lingui/macro'
import {
  MenuItem,
  SidebarMenuLink
} from 'components/SidebarNavigation/MenuItem/MenuItem'

import { Divider } from 'components/page/header/navigationHeader/hamburgerMenuContent/divider/Divider'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import styles from './NavigationContent.module.scss'
import { LanguageMenuLink } from 'components/SidebarNavigation/MenuItem/LanguageMenuLink/LanguageMenuLink'

interface Props {
  menuLinks: SidebarMenuLink[]
  currentUser: User
  onHelpClick: () => void
  onMenuItemClick: () => void
  onSignOutClick: () => void
  showLanguageSelector?: boolean
  onLanguageSelectorClick?: () => void
}

export const SignedInContent: FC<Props> = ({
  menuLinks,
  currentUser,
  onHelpClick,
  onMenuItemClick,
  onSignOutClick,
  showLanguageSelector = false,
  onLanguageSelectorClick
}) => {
  const dispatch = useDispatch()
  return (
    <div className={styles.Container}>
      <div className={styles.TopContainer}>
        <div className={styles.UserSection}>
          <UserInfoSection
            firstName={currentUser?.firstname}
            lastName={currentUser?.lastname}
            email={currentUser?.email}
          />
        </div>
        <Divider />
        {menuLinks && menuLinks.length > 0 && (
          <div className={styles.MenuLinks} data-testid="menu-links-list">
            {menuLinks.map((menuLink: SidebarMenuLink) => (
              <MenuItem
                key={menuLink.translationText}
                {...menuLink}
                onClick={onMenuItemClick}
              />
            ))}
            {showLanguageSelector && onLanguageSelectorClick && (
              <LanguageMenuLink onClick={onLanguageSelectorClick} />
            )}
          </div>
        )}
      </div>
      <div className={styles.SecondDivider}>
        <Divider />
      </div>
      <div className={styles.BottomContainer}>
        <SignOutButton
          onClick={() => {
            dispatch(authenticationActions.logout())
            onSignOutClick()
          }}
        />
        <div className={styles.HelpText}>
          <ClickableTextButton
            defaultText={t`Need any help?`}
            clickableText={t`Click here!`}
            onClick={onHelpClick}
          />
        </div>
      </div>
    </div>
  )
}
