import React, { FC } from 'react'
import { ViewType } from '../../../../redux-store/stream/stream.interface'
import ClockIcon from '../Icons/ClockIcon'
import HighlightIcon from '../Icons/HighlightIcon'
import LiveIcon from '../Icons/LiveIcon'
import ReplayIcon from '../Icons/ReplayIcon'
import styles from './ActionLabel.module.scss'

interface ActionLabelIconProps {
  viewType: ViewType
}

export const ActionLabelIcon: FC<ActionLabelIconProps> = ({ viewType }) => {
  switch (viewType) {
    case ViewType.LIVE:
      return (
        <div
          className={styles.ActionLabelIconLive}
          data-testid="action-label-icon-live"
        >
          <LiveIcon />
        </div>
      )
    case ViewType.REPLAY:
      return <ReplayIcon />
    case ViewType.UPCOMING:
      return <ClockIcon />
    case ViewType.HIGHLIGHT:
      return <HighlightIcon />
    default:
      return null
  }
}
