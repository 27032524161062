import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'

import IconTextButton, {
  ButtonType
} from '../../../components/buttons/TextIconButton/index'
import HeartIcon from '../../../components/buttons/TextIconButton/GlobeHeartIcon'
import FilledHeartIcon from './Images/FilledHeartIcon'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'

import styles from './Header.module.scss'

interface Props {
  id: string
  followed: boolean
  onClick: (id: string) => void
  hasTheme: boolean
}

const FollowButton: FC<Props> = ({ id, followed, onClick, hasTheme }) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const following = t`Following`
  const follow = t`Follow`
  const unfollow = t`Unfollow`

  const label = followed ? following : follow

  const [textToShow, setTextToShow] = useState(label)
  const size = currentBreakpoint > Breakpoint.sm ? 'Medium' : 'Small'
  const followClick = (channelId: string) => {
    onClick(channelId)
  }

  const onMouseEnter = () => {
    if (label === following) setTextToShow(unfollow)
  }
  const onMouseLeave = () => {
    setTextToShow(label)
  }
  return (
    <div data-testid="channel-follow-button" className={styles.FollowButton}>
      <IconTextButton
        id={id}
        type={hasTheme ? ButtonType.ThemedInverted : ButtonType.Ghost}
        buttonSize={size}
        label={textToShow}
        onClick={() => followClick(id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {followed ? <FilledHeartIcon /> : <HeartIcon />}
      </IconTextButton>
    </div>
  )
}

export default FollowButton
