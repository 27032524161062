const STREAM = {
  STREAM_FETCH_START: 'STREAM_FETCH_START',
  STREAM_FETCH_SUCCESS: 'STREAM_FETCH_SUCCESS',
  STREAM_FETCH_ERROR: 'STREAM_FETCH_ERROR',
  STREAM_MAKE_VOUCHER_PAYMENT_START: 'STREAM_MAKE_VOUCHER_PAYMENT_START',
  STREAM_MAKE_VOUCHER_PAYMENT: 'STREAM_MAKE_VOUCHER_PAYMENT',
  STREAM_MAKE_VOUCHER_PAYMENT_ERROR: 'STREAM_MAKE_VOUCHER_PAYMENT_ERROR',
  CLEAR_STREAM: 'CLEAR_STREAM',
  INCREMENT_STREAM_USER_LIKE_COUNT: 'INCREMENT_STREAM_USER_LIKE_COUNT',
  DECREMENT_STREAM_USER_LIKE_COUNT: 'DECREMENT_STREAM_USER_LIKE_COUNT'
}

export default STREAM
