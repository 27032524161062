export interface UserInterface {
  menuOpen: boolean
  reportContentModalIsOpen: boolean
}

export const uiConstants = {
  OPEN_MENU: 'OPEN_MENU',
  CLOSE_MENU: 'CLOSE_MENU',
  OPEN_REPORT_CONTENT_DIALOG: 'OPEN_REPORT_CONTENT_DIALOG',
  CLOSE_REPORT_CONTENT_DIALOG: 'CLOSE_REPORT_CONTENT_DIALOG',
  OPEN_BROADCAST_INFROMATION_MODAL: 'OPEN_BROADCAST_INFROMATION_MODAL',
  CLOSE_BROADCAST_INFROMATION_MODAL: 'CLOSE_BROADCAST_INFROMATION_MODAL',
  CLOSE_QUICK_EDIT_DIALOG: 'CLOSE_QUICK_EDIT_DIALOG',
  OPEN_QUICK_EDIT_DIALOG: 'OPEN_QUICK_EDIT_DIALOG'
}

export const initialState = {
  menuOpen: false,
  reportContentModalIsOpen: false,
  broadcastInformationModalIsOpen: false,
  quickEditModalIsOpen: false
}

function userInterface(state = initialState, action: any) {
  switch (action.type) {
    case uiConstants.OPEN_MENU:
      return {
        ...state,
        menuOpen: true
      }
    case uiConstants.CLOSE_MENU:
      return {
        ...state,
        menuOpen: false
      }
    case uiConstants.OPEN_REPORT_CONTENT_DIALOG:
      return {
        ...state,
        reportContentModalIsOpen: true
      }
    case uiConstants.CLOSE_REPORT_CONTENT_DIALOG:
      return {
        ...state,
        reportContentModalIsOpen: false
      }
    case uiConstants.OPEN_BROADCAST_INFROMATION_MODAL:
      return {
        ...state,
        broadcastInformationModalIsOpen: true
      }
    case uiConstants.CLOSE_BROADCAST_INFROMATION_MODAL:
      return {
        ...state,
        broadcastInformationModalIsOpen: false
      }
    case uiConstants.OPEN_QUICK_EDIT_DIALOG:
      return {
        ...state,
        quickEditModalIsOpen: true
      }
    case uiConstants.CLOSE_QUICK_EDIT_DIALOG:
      return {
        ...state,
        quickEditModalIsOpen: false
      }
    default:
      return state
  }
}

export default userInterface
