import React, { FC } from 'react'
import { MenuItems } from './menuItems/MenuItems'

import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'

interface Props {
  isRootPageorChild: boolean
  hasBackground: boolean
  useTheme: any
  backgroundIsTransparentAtStart: boolean
  rootChannelSubdomain: string
  rootChannelType: ChannelTypes
}

export const HeaderMenuItems: FC<Props> = ({
  isRootPageorChild,
  hasBackground,
  useTheme,
  backgroundIsTransparentAtStart,
  rootChannelSubdomain,
  rootChannelType
}) => {
  if (rootChannelType === ChannelTypes.club) {
    return null
  }

  return (
    <MenuItems
      isRootPageorChild={isRootPageorChild}
      themed={useTheme}
      hasBackground={hasBackground || !backgroundIsTransparentAtStart}
      rootChannelSubdomain={rootChannelSubdomain}
      rootChannelType={rootChannelType}
    />
  )
}
