export enum FeatureFlagsAvailable {
  FeatureFlags = 'featureFlags',
  FeatureFlagsExpanded = 'featureFlagsExpanded',
  VideoDebug = 'videoDebug',
  ShowChannelAdminGuidance = 'showChannelAdminGuidance',
  HideComingSoon = 'hideComingSoon',
  ComingSoon = 'comingSoon',
  ChannelOverviewSetting = 'channelOverviewSetting',
  PoweredBy = 'poweredBy',
  ShowQuickEdit = 'showQuickEdit'
}
