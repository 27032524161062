import React, { FC, memo } from 'react'
import { countryFlags, Country, CountryCode } from '../../../countryFlags'
import { locales } from '../../../../../translations/i18n'

import styles from './CountryLanguage.module.scss'

interface Props {
  country: CountryCode
  language: string
}

const CountryLanguage: FC<Props> = ({ country, language }) => {
  const hideForFirstRelease = true
  const newFlag = countryFlags.find((c: Country) => c.shortCode === country)!
  const DisplayFlag = newFlag
    ? newFlag.flag
    : countryFlags.find((c: Country) => c.shortCode === 'XX')!.flag
  const displayLanguage = locales.find((l: any) => l.abbr === language)?.name
  return (
    <div className={styles.CountryLanguage}>
      {hideForFirstRelease &&
        !(
          <div className={styles.Flag}>
            <DisplayFlag />
          </div>
        )}
      <div className={styles.Language} data-testid="top-header-language">
        {displayLanguage}
      </div>
    </div>
  )
}
export default memo(CountryLanguage)
