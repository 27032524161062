import React, { FC } from 'react'
import { TargetingOptions, useAdUnit } from '../useAdUnit'
import styles from './NativeAd.module.scss'

interface Props {
  adId: string
  slotPath: string
  size: number[] | string[]
  targetingOptions?: TargetingOptions
}
export const NativeAd: FC<Props> = ({
  adId,
  slotPath,
  size,
  targetingOptions
}) => {
  useAdUnit({ path: slotPath, id: adId, size, targetingOptions })
  const style =
    typeof size[0] === 'string' ? {} : { height: size[1], width: size[0] }

  return <div className={styles.AdContainer} id={adId} style={style} />
}
NativeAd.defaultProps = {
  targetingOptions: undefined
}
