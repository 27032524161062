import { t } from '@lingui/macro'
import {
  Theme,
  UserProfileAvatar
} from 'components/UserProfileAvatar/UserProfileAvatar'
import React, { FC } from 'react'
import styles from './UserInfoSection.module.scss'

interface Props {
  firstName: string
  lastName: string
  email: string
  showFullName?: boolean
}

export const UserInfoSection: FC<Props> = ({
  firstName,
  lastName,
  email,
  showFullName = false
}) => {
  const userNameText = showFullName
    ? `${firstName} ${lastName}`
    : t`Hi, ${firstName}!`
  return (
    <div className={styles.Container} data-testid="user-info-section">
      <UserProfileAvatar
        firstName={firstName}
        lastName={lastName}
        theme={Theme.Red}
      />
      <div className={styles.TextContainer}>
        <div className={styles.Name} data-testid="user-name">
          {userNameText}
        </div>
        <div className={styles.Email} data-testid="user-email">
          {email}
        </div>
      </div>
    </div>
  )
}
