import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import CheckIcon from '../../../../assets/icons/CheckIcon'
import styles from './ReportContentSuccess.module.scss'

export const ReportContentSuccess: FC = () => {
  return (
    <div className={styles.ReportContentSuccessContainer}>
      <div className={styles.ReportContentSuccessCheckmark}>
        <CheckIcon />
      </div>
      <h2 className={styles.ReportContentSuccessHeader}>
        <Trans>Thanks for your report!</Trans>
      </h2>
      <div className={styles.ReportContentSuccessText}>
        <Trans>Our support team is looking into the matter</Trans>
      </div>
    </div>
  )
}
