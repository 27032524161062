import { t } from '@lingui/macro'
import { ActivityLog } from '../../../interfaces'
import { getActivityTime } from './getActivityTime'

export const reporterText = (activityLog: ActivityLog) => {
  const score = `${activityLog.home_score}-${activityLog.away_score}`
  const playerNumber = `#${activityLog.player_number}`
  const playerName = activityLog.player_name
  const numberAndName = `${playerNumber} ${playerName}`.trim()
  const homeTeamName = activityLog.home_team_name
  const awayTeamName = activityLog.away_team_name
  const actionTeamName = activityLog.team_name

  const activityTime = getActivityTime(activityLog)

  const actionTime = activityLog.game_time !== '0' ? t`at ${activityTime}` : ''
  switch (activityLog.activity_type) {
    case 'comment':
      return activityLog.value
    case 'game_start':
      return t`The game has started!`
    case 'game_end':
      return t`End of the game. ${homeTeamName} - ${awayTeamName} ends ${score}.`
    case 'overtime':
      return t`The game has gone to overtime.`
    case 'period_start':
      return t`Start of period ${activityLog.period}.`
    case 'period_end':
      return t`End of period ${activityLog.period}.`
    case 'score':
      if (activityLog.player_name && activityLog.player_number) {
        return t`Goal!!! ${score} to ${actionTeamName}, scored by ${numberAndName} ${actionTime}`
      }
      if (activityLog.player_number) {
        return t`Goal!!! ${score} to ${actionTeamName}, scored by ${playerNumber} ${actionTime}`
      }
      if (activityLog.player_name) {
        return t`Goal!!! ${score} to ${actionTeamName}, scored by ${playerName} ${actionTime}`
      }
      return t`Goal!!! ${score} to ${actionTeamName} ${actionTime}`

    case 'penalty':
      if (activityLog.player_name && activityLog.player_number) {
        return t`Penalty to ${actionTeamName}, by ${numberAndName} ${actionTime}`
      }
      if (activityLog.player_number) {
        return t`Penalty to ${actionTeamName}, by ${playerNumber} ${actionTime}`
      }
      if (activityLog.player_name) {
        return t`Penalty to ${actionTeamName}, by ${playerName} ${actionTime}`
      }
      return t`Penalty to ${actionTeamName} ${actionTime}`
    case 'penalties':
      return t`The game has gone to penalties.`
    default:
      return score
  }
}
