import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { GameScheduleCardType } from './gameScheduleType'

export const getViewerChannelSlug = (
  channelSlug: string,
  channelType: ChannelTypes,
  content: GameScheduleCardType
) => {
  switch (channelType) {
    case ChannelTypes.association:
    case ChannelTypes.district:
    case ChannelTypes.league:
    case ChannelTypes.club:
    case ChannelTypes.event:
      return content.channelSlug
    case ChannelTypes.team:
      return channelSlug
    default:
      return channelSlug
  }
}
