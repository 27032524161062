import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import useCurrentUser from '../../../../hooks/CurrentUser'
import authenticationActions from '../../../../redux-store/authentication/authentication.actions'
import MessageInput from '../MessageInput/MessageInput'
import { postChatMessage } from '../../../../api/viewer/Chat/postChatMessage'
import styles from './InputContainer.module.scss'

interface InputContainerProps {
  mediaObjectId: string
}

export enum SpammingStatus {
  None = 0,
  SameMessage = 406,
  TooManyMessages = 429
}

const InputContainer: FC<InputContainerProps> = ({ mediaObjectId }) => {
  const currentUser = useCurrentUser()
  const [userIsBlocked, setUserIsBlocked] = useState(false)
  const [spammingStatus, setSpammingStatus] = useState<number>(
    SpammingStatus.None
  )
  const dispatch = useDispatch()

  const sendMessage = async (textMessage: string, file: string) => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      return false
    }
    if (textMessage !== '' || file) {
      setSpammingStatus(SpammingStatus.None)
      const result = await postChatMessage({
        text: textMessage,
        file,
        mediaObjectIdent: mediaObjectId
      })
      if (result.status === 403) {
        setUserIsBlocked(true)
      } else if (result.status === SpammingStatus.SameMessage) {
        setSpammingStatus(SpammingStatus.SameMessage)
      } else if (result.status === SpammingStatus.TooManyMessages) {
        setSpammingStatus(SpammingStatus.TooManyMessages)
      }
    }
    return false
  }
  return (
    <div
      className={styles.InputContainer}
      data-testid="chat-message-input-container"
    >
      <MessageInput
        onSuccess={(text, file) => sendMessage(text, file)}
        userIsBlocked={userIsBlocked}
        spammingStatus={spammingStatus}
      />
    </div>
  )
}
export default InputContainer
