import React, { FC } from 'react'

const ReplayIcon: FC = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 13.248V17.748H1.5"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.71701 17.751C4.16998 16.1337 3.22625 14.0335 3.04418 11.8028C2.86211 9.57216 3.45277 7.34674 4.71706 5.49996C5.98135 3.65318 7.84234 2.29741 9.98778 1.66014C12.1332 1.02287 14.4326 1.14286 16.5 1.99999"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.71201 17.746C4.04976 16.0087 3.09042 13.717 3.01936 11.3136C2.94829 8.91019 3.77055 6.56576 5.32726 4.73328C6.88396 2.9008 9.06461 1.71034 11.4479 1.39191C13.8311 1.07349 16.2478 1.6497 18.231 3.00922C20.2142 4.36874 21.6231 6.41506 22.1855 8.7528C22.7479 11.0905 22.4238 13.5538 21.276 15.6665C20.1282 17.7793 18.238 19.3916 15.9706 20.192C13.7033 20.9924 11.2198 20.924 9.00001 20"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7.66439V14.3332C10.5 14.4512 10.5305 14.5671 10.5885 14.669C10.6464 14.771 10.7297 14.8553 10.8298 14.9135C10.9299 14.9716 11.0433 15.0015 11.1583 14.9999C11.2733 14.9984 11.3859 14.9656 11.4845 14.9049L16.6846 11.5714C16.7808 11.5121 16.8604 11.4283 16.9157 11.3281C16.9709 11.228 17 11.1148 17 10.9997C17 10.8845 16.9709 10.7714 16.9157 10.6712C16.8604 10.571 16.7808 10.4872 16.6846 10.4279L11.4845 7.09443C11.386 7.03406 11.2736 7.0015 11.1589 7.00005C11.0441 6.9986 10.931 7.02832 10.831 7.0862C10.731 7.14407 10.6478 7.22803 10.5896 7.32957C10.5315 7.43112 10.5006 7.54663 10.5 7.66439Z"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ReplayIcon
