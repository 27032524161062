import React, { FC, useEffect } from 'react'
import IconTextButton, { ButtonType } from '../TextIconButton'
import PlusIcon from 'pages/ViewerPage/Chat/MessageInput/UploadButton/PlusIcon'
import { t } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'
import useCurrentUser from 'hooks/CurrentUser'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import paymentActions from 'redux-store/payments/payments.actions'
import PAYMENTS from 'redux-store/payments/payments.constants'

interface Props {
  onAddedCardSuccess: () => void
}
export const AddCreditCardButton: FC<Props> = ({ onAddedCardSuccess }) => {
  const paymentSuccess = useSelector((state: any) => state.payments.unlockedId)
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  useEffect(() => {
    if (paymentSuccess) {
      onAddedCardSuccess()
    }
  }, [paymentSuccess])
  const initiatePayment = () => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
    } else {
      dispatch(
        paymentActions.openPayment({
          type: PAYMENTS.TYPE_ADD_CARD
        })
      )
    }
  }
  return (
    <IconTextButton
      id={'add-new-credit-card'}
      type={ButtonType.Ghost}
      buttonSize={'Medium'}
      label={t`Add new card`}
      onClick={() => initiatePayment()}
    >
      <PlusIcon />
    </IconTextButton>
  )
}
