import React, { FC } from 'react'
import { TargetingOptions, useAdUnit } from '../useAdUnit'
import styles from './BannerAd.module.scss'
import classNames from 'classnames'

type NumberPair = [number, number]
export enum BackgroundTheme {
  White = 'White',
  Grey = 'Grey',
  LightGrey = 'LightGrey'
}
interface Props {
  adId: string
  slotPath: string
  size?: number[] | string[]
  multiSize: NumberPair[]
  targetingOptions?: TargetingOptions
  backgroundTheme?: BackgroundTheme
}
export const BannerAd: FC<Props> = ({
  adId,
  slotPath,
  size,
  multiSize,
  targetingOptions,
  backgroundTheme = BackgroundTheme.White
}) => {
  useAdUnit({ path: slotPath, id: adId, size, multiSize, targetingOptions })
  return (
    <div
      className={classNames(styles.BannerAdContainer, styles[backgroundTheme])}
      id={adId}
    />
  )
}
BannerAd.defaultProps = {
  targetingOptions: undefined
}
