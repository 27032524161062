import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { BroadcastSection } from '../BroadcastSection/BroadcastSection'
import { VideoPlayerSection } from '../VideoPlayerSection/VideoPlayerSection'
import { VideoPlayerData } from '../viewerPage.interfaces'
import { downloadStream } from '../../../api/viewer/downloadStream'

import useCurrentUser from '../../../hooks/CurrentUser'
import { toggleLike } from '../../../api/viewer/toggleLikeForUser'
import VideoPlayerPreloader from '../Preloaders/videoPlayerPreloader/VideoPlayerPreloader'
import authenticationActions from '../../../redux-store/authentication/authentication.actions'
import { useCountdownTime } from '../../../components/live/Countdown/useCountdownTime'
import { AccessRestrictionType } from '../../../interfaces'
import { userInterface } from '../../../redux-store/userInterface/userInterface.actions'

interface ViewerPageProps {
  mediaPlayerData: VideoPlayerData | undefined
  isLoading: boolean
  isLiked: boolean
  setIsLiked: any
  viewAsAdmin: boolean
  channelName: string
  setViewAsAdmin: () => void
  isPaymentModalDefaultOpen: boolean
}

const BroadcastView: FC<ViewerPageProps> = ({
  mediaPlayerData,
  isLiked,
  setIsLiked,
  isLoading,
  viewAsAdmin,
  channelName,
  setViewAsAdmin,
  isPaymentModalDefaultOpen
}) => {
  const [hasStarted, setHasStarted] = useState(false)
  const dispatch = useDispatch()
  const competitionData = mediaPlayerData?.mediaObject.competition
    ? mediaPlayerData?.mediaObject.competition
    : { type: '', name: '' }
  const isLiveAndHasStarted =
    mediaPlayerData?.mediaObject?.is_live_and_started || false
  const currentUser = useCurrentUser()

  const countdownStartTime = useCountdownTime()
  const preliminaryStartTime =
    mediaPlayerData?.mediaObject?.live_start_at || Infinity

  const streamType = mediaPlayerData?.mediaObject?.type
  useEffect(() => {
    let interval: any
    if (streamType !== 'livestream') {
      setHasStarted(true)
    } else if (!hasStarted) {
      interval = setInterval(() => {
        const now = Date.now() / 1000
        const isReadyToStart =
          now > countdownStartTime ||
          now > preliminaryStartTime ||
          isLiveAndHasStarted
        setHasStarted(isReadyToStart)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [
    hasStarted,
    countdownStartTime,
    preliminaryStartTime,
    streamType,
    isLiveAndHasStarted
  ])

  const download = async () => {
    if (mediaPlayerData && currentUser) {
      /*
      // commented out for now, we need to add back later
      // when Tag Manager specs are better defined
      TagManager.dataLayer({
        dataLayer: {
          event: 'download',
          link_text: mediaPlayerData.mediaObject.title,
          link_domain: mediaPlayerData.mediaObject.company.subdomain
        }
      })
      */
      try {
        window.location.href = await downloadStream(
          mediaPlayerData.mediaObject.company.subdomain,
          mediaPlayerData.mediaObject.id
        )
      } catch {
        console.error('error in stream download')
      }
    }
  }
  const like = async () => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
    } else {
      try {
        await toggleLike(isLiked, mediaPlayerData!.mediaObject.id)
        setIsLiked(!isLiked)
      } catch (err: any) {
        setIsLiked(isLiked)
        console.log('media object like error:', err)
      }
    }
  }

  const report = () => {
    if (currentUser) {
      dispatch(userInterface.openReportContentDialog())
    } else {
      dispatch(authenticationActions.openLoginLayer())
    }
  }

  const isFree =
    mediaPlayerData?.mediaObject?.access_restriction ===
    AccessRestrictionType.None

  if (
    (((mediaPlayerData?.streamUrl?.requirePayment && !viewAsAdmin) ||
      !currentUser ||
      !hasStarted) &&
      !isFree) ||
    !mediaPlayerData?.streamUrl?.urls
  ) {
    const producer = mediaPlayerData?.mediaObject?.game?.producer

    return (
      <BroadcastSection
        channelName={channelName}
        // @ts-ignore
        title={mediaPlayerData.mediaObject.title}
        producer={producer}
        competition={competitionData}
        // @ts-ignore
        mediaPlayerData={mediaPlayerData}
        onWatch={setViewAsAdmin}
        onDownload={download}
        onLike={like}
        isLiked={isLiked}
        onReportStream={report}
        isPaymentModalDefaultOpen={isPaymentModalDefaultOpen}
      />
    )
  }
  return mediaPlayerData &&
    mediaPlayerData.streamUrl !== null &&
    mediaPlayerData.streamUrl.urls &&
    mediaPlayerData.streamUrl.urls.hls &&
    mediaPlayerData.streamUrl.urls.dash &&
    !isLoading ? (
    <VideoPlayerSection
      mediaPlayerData={mediaPlayerData}
      isLiked={isLiked}
      onLike={like}
      onDownload={download}
      onWatch={setViewAsAdmin}
      onReportStream={report}
    />
  ) : (
    <VideoPlayerPreloader />
  )
}
export default BroadcastView
