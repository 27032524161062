import React, { FC } from 'react'
import styles from './Team.module.scss'
import classNames from 'classnames'
import { DefaultTeamBadge } from 'components/DefaultTeamBadge/DefaultTeamBadge'

interface Props {
  logo: string
  name: string
  homeTeam: boolean
  abbreviation: string
}

export const Team: FC<Props> = ({ logo, name, homeTeam, abbreviation }) => (
  <div
    className={classNames(styles.TeamContainer, {
      [styles.HomeTeam]: homeTeam
    })}
  >
    {logo && logo !== '' ? (
      <img src={logo} alt={name} />
    ) : (
      <div className={styles.DefaultTeamBadgeContainer}>
        <DefaultTeamBadge teamName={name} abbreviation={abbreviation} />
      </div>
    )}
    <div className={styles.Name}>{name}</div>
  </div>
)
