import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { useLocation } from 'react-router-dom'
import {
  TextFormModal,
  TextFormModalSize
} from '../TextFormModal/TextFormModal'
import { ShareComponent } from './ShareComponent/ShareComponent'
import styles from './ShareModal.module.scss'

interface ShareModal {
  defaultOpen: boolean
  companySlug: string
  onClose: () => void
  mediaId: string
  mediaTitle: string
}

const ShareModal: FC<ShareModal> = ({
  defaultOpen,
  companySlug,
  onClose,
  mediaId,
  mediaTitle
}) => {
  const location = useLocation()
  const [isModalRequired, setIsModalRequired] = useState(false)

  useEffect(() => {
    if ((window as any).navigator.share) {
      navigator.share({
        title: mediaTitle,
        text: mediaTitle,
        url: location.pathname
      })
      onClose()
    } else {
      setIsModalRequired(true)
    }
  }, [location.pathname, mediaId, mediaTitle])

  return (
    <TextFormModal
      defaultOpen={defaultOpen && isModalRequired}
      description={t`Make it easy for your viewers to find your match and broadcast. Share where your supporters are.`}
      onClose={onClose}
      subTitle={t`Share Broadcast`}
      title={t`Invite your supporters`}
      modalSize={TextFormModalSize.Medium}
      childStyle={styles.OuterStyle}
    >
      <div className={styles.Form} data-testid="share-modal-container">
        <ShareComponent
          mediaId={mediaId}
          mediaTitle={mediaTitle}
          onClose={onClose}
          companySlug={companySlug}
        />
      </div>
    </TextFormModal>
  )
}

export default ShareModal
