import React, { FC, useState } from 'react'
import { PaymentMethods, PaymentPropsType } from '../../viewerPage.interfaces'
import { PaymentDetails } from '../PaymentModal'
import VoucherSection from '../VoucherSection/VoucherSection'
import { AllAccessView } from './AllAccessView'
import styles from './BuyBannerView.module.scss'
import NormalSubscriptionView from './NormalSubscriptionView'
import { PayPerStreamView } from './PayPerStreamView'
import VoucherView from './VoucherView'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

interface BuybannerViewProps {
  mediaObjectId?: string | undefined
  paymentOptions: PaymentMethods
  defaultCurrency: string
  channelSlug: string
  onClose: () => void
  onSelect: (payment: PaymentPropsType, paymentDetails: PaymentDetails) => void
}
const BuyBannersView: FC<BuybannerViewProps> = ({
  mediaObjectId,
  paymentOptions,
  defaultCurrency,
  onClose,
  channelSlug,
  onSelect
}) => {
  const [showVoucherField, setShowVoucherField] = useState(false)
  const channel = useSelector(channelSelector)
  const channelType = channel.data?.type
  const isRootChannel = channel.data?.isRootChannel

  const onSelectVoucher = () => {
    setShowVoucherField(true)
  }
  const goBackToPaymentOption = () => {
    setShowVoucherField(false)
  }
  return (
    <div
      className={styles.BuyBannerContent}
      data-testid="buy-banner-view-container"
    >
      {showVoucherField ? (
        <VoucherSection
          mediaObjectIdent={mediaObjectId!}
          channelSlug={channelSlug}
          onGoBackClick={goBackToPaymentOption}
          onClose={onClose}
        />
      ) : (
        <>
          <AllAccessView
            subscriptions={paymentOptions.subscriptions}
            defaultCurrency={defaultCurrency}
            onSelect={onSelect}
          />
          <NormalSubscriptionView
            subscriptions={paymentOptions.subscriptions}
            defaultCurrency={defaultCurrency}
            isRootChannel={isRootChannel}
            channelType={channelType}
            onSelect={onSelect}
          />

          {paymentOptions.ppv.price && (
            <PayPerStreamView
              payPerView={paymentOptions.ppv}
              defaultCurrency={defaultCurrency}
              onSelect={onSelect}
              mediaObjectId={mediaObjectId!}
            />
          )}
          <VoucherView
            payPerView={paymentOptions.ppv}
            defaultCurrency={defaultCurrency}
            onSelect={onSelectVoucher}
          />
        </>
      )}
    </div>
  )
}
BuyBannersView.defaultProps = {
  mediaObjectId: undefined
}
export default BuyBannersView
