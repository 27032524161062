// https://jasonwatmore.com/post/2020/03/02/react-hooks-redux-user-registration-and-login-tutorial-example#user-service-js
// import config from 'config'

import Base64 from 'base-64'
import utf8 from 'utf8'
import store from '../store'
import { ChatMessage } from '../../interfaces'

import subscriberActions from './subscriber.actions'
import SUBSCRIBER from './subscriber.constants'

const subscriberService = {
  connect,
  disconnect
}

async function connect(uri: string) {
  const connected = (subscription: any) => {
    return { type: SUBSCRIBER.CONNECTED, subscription: subscription }
  }
  const failed = (error: any) => {
    return { type: SUBSCRIBER.ERROR, error }
  }
  const disconnected = () => {
    return { type: SUBSCRIBER.DISCONNECTED }
  }

  // @ts-ignore
  const NChan = store.getState().subscriber.nchan
  const nchanOpts = {
    subscriber: 'websocket',
    reconnect: 'session'
    // shared: true (removed to stop spamming of localStorage)
  }
  const subscription = new NChan(uri, nchanOpts)
  // eslint-disable-next-line
  subscription.on('message', (message: any, message_metadata: any) => {
    let parsedMessage
    try {
      parsedMessage = JSON.parse(utf8.decode(Base64.decode(message)))
    } catch (e: any) {
      // eslint-disable-next-line no-empty
    }

    if (parsedMessage) {
      if (parsedMessage.context === SUBSCRIBER.CONTEXT.CHAT) {
        const chatMessage = parsedMessage as ChatMessage
        if (
          chatMessage.type === 'chat_message' ||
          chatMessage.type === 'chat_activity_log'
        ) {
          store.dispatch(subscriberActions.newChatMessage(parsedMessage))
        }
        if (chatMessage.type === 'hide_chat_message') {
          store.dispatch(subscriberActions.hideChatMessage(parsedMessage))
        }
      }
      if (parsedMessage.context === SUBSCRIBER.CONTEXT.BROADCAST_MESSAGE) {
        store.dispatch(subscriberActions.newBroadcastMessage(parsedMessage))
      }
      if (parsedMessage.context === SUBSCRIBER.CONTEXT.COUNTDOWN_MESSAGE) {
        store.dispatch(subscriberActions.newCountdownMessage(parsedMessage))
      }
    }
  })
  // eslint-disable-next-line
  subscription.on('connect', (event: any) => {
    store.dispatch(connected(subscription))
  })
  // eslint-disable-next-line
  subscription.on('disconnect', (event: any) => {
    store.dispatch(disconnected())
  })
  subscription.on('error', (event: any, error_description: any) => {
    store.dispatch(failed(error_description))
  })
  subscription.reconnect = true
  subscription.reconnectTimeout = 10000
  subscription.start()
}

async function disconnect() {
  // @ts-ignore
  const { subscription } = store.getState().subscriber
  if (subscription?.running) {
    subscription.stop()
  }
}

export default subscriberService
