import React, { FC, useState } from 'react'

import { Trans, t } from '@lingui/macro'
import { TextArea } from 'components/Forms/TextArea/TextArea'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import Button, { ButtonType } from 'components/buttons/button/Button'
import BroadcastThumbnail from 'components/cards/BroadcastThumbnail'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { VideoPlayerData } from 'pages/ViewerPage/viewerPage.interfaces'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { QuickEdit, patchQuickEdit } from 'api/stream/patchQuickEdit'
import dayjs from 'dayjs'
import { FormActionButtons } from 'components/Forms/FormActionButtons/FormActionButtons'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { generalStreamInfoValidationSchema } from './GeneralStreamInfoValidationSchema'
import { FormDropFileZone } from 'components/DropFileZone/FormDropZone'
import { UPLOAD_TYPE } from 'components/DropFileZone/UploadType'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { Divider } from 'components/page/header/navigationHeader/hamburgerMenuContent/divider/Divider'
import styles from './GeneralStreamInformationForm.module.scss'
import { FormValidationError } from 'components/Forms/FormValidationError/FormValidationError'

interface Props {
  onClose: () => void
}

interface GeneralStreamInfoValues {
  thumbnailImageUrl: string
  imageFile?: any
  title: string
  description: string
  startTime: string
  endTime: string
  manualEdit?: boolean
}
export const GeneralStreamInformationForm: FC<Props> = ({ onClose }) => {
  const stream = useSelector(streamSelector)
  const [showUploadZone, setShowUploadZone] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const mediaPlayerData: VideoPlayerData = stream.data
  const showManualEdit = mediaPlayerData.mediaObject.game !== null
  const showDateContainer =
    !mediaPlayerData.mediaObject.is_replay ||
    mediaPlayerData.mediaObject.type !== 'video'

  const formatTime = (timestamp: number) =>
    dayjs.unix(timestamp).format('YYYY-MM-DD HH:mm')

  const initialValues: GeneralStreamInfoValues = {
    thumbnailImageUrl: mediaPlayerData.mediaObject?.thumbnail_url || '',
    title: mediaPlayerData.mediaObject?.title || '',
    description: mediaPlayerData.mediaObject.description,
    startTime: formatTime(mediaPlayerData.thumbnailObject?.startTime!) || '',
    endTime: formatTime(mediaPlayerData.thumbnailObject?.endTime!) || '',
    imageFile: undefined,
    manualEdit: showManualEdit ? false : true
  }

  const onSubmit = async (
    values: GeneralStreamInfoValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)

    const startTime = dayjs(values.startTime).unix()
    const endTime = dayjs(values.endTime).unix()
    const params: QuickEdit = {
      ident: mediaPlayerData.mediaObject.id,
      title: values.title,
      description: values.description,
      startTime: startTime,
      endTime: endTime,
      imageFile: values.imageFile,
      manualEdit: values.manualEdit
    }
    const response = await patchQuickEdit({
      ...params
    })
    if (!response.isSuccess) {
      if (response.message) {
        setErrorMessage(response.message)
      } else {
        setErrorMessage(
          t`Something went wrong when tying to update the stream information, please try again`
        )
      }
    } else {
      onClose()
    }
    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={generalStreamInfoValidationSchema}
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => {
        const handleUploadImageClick = () => {
          if (showUploadZone) {
            setShowUploadZone(false)
            props.setFieldValue('imageFile', undefined)
          } else {
            setShowUploadZone(true)
          }
        }
        const onManualEditChange = () => {
          props.setFieldValue('manualEdit', !props.values.manualEdit)
          props.setFieldTouched('manualEdit', true, false)
        }
        return (
          <Form className={styles.FormContainer}>
            {mediaPlayerData.thumbnailObject && (
              <div className={styles.ThumbnailContainer}>
                <BroadcastThumbnail
                  content={mediaPlayerData.thumbnailObject}
                  shared={false}
                  showDetails={false}
                  size={isMobile ? 'keepRatio' : 'small'}
                  disableLink={true}
                />
                {showUploadZone && (
                  <FormDropFileZone
                    formFieldName="imageFile"
                    fileUrlFieldName="thumbnailImageUrl"
                    type={UPLOAD_TYPE.IMAGE}
                    formik={props}
                    recommendedImageHeight="1920px"
                    recommendedImageWidth="1080px"
                  />
                )}
                <Button
                  type={ButtonType.Secondary}
                  label={showUploadZone ? t`cancel` : t`Upload thumbnail`}
                  buttonSize={'Small'}
                  onClick={handleUploadImageClick}
                />
              </div>
            )}
            <div className={styles.Fields}>
              {showManualEdit && (
                <div className={styles.ToggleSwitchContainer}>
                  {isMobile && (
                    <div className={styles.Text}>
                      <Divider />
                      <Trans>
                        This game is automatically updated. Turn on manual edit
                        if you want to change the data in the fields below.
                      </Trans>
                    </div>
                  )}
                  <ToggleSwitch
                    isOn={props.values.manualEdit}
                    id="manualEdit"
                    label={t`Manual edit`}
                    onToggle={onManualEditChange}
                  />
                </div>
              )}
              <Field
                component={TextInput}
                id="title"
                name="title"
                label={t`Title`}
                placeholder={t`Write the title of the stream here`}
                onChange={props.handleChange}
                textValue={props.values.title}
                isValid={!props.errors.title}
                validationMessage={props.errors.title}
                isRequired={false}
                readonly={!props.values.manualEdit}
              />
              <Field
                component={TextArea}
                id="description"
                label={t`Description`}
                placeholder={t`Write the description of the stream here`}
                readonly={!props.values.manualEdit}
              />
              {showDateContainer && (
                <div className={styles.DateContainer}>
                  <Field
                    component={TextInput}
                    id="startTime"
                    name="startTime"
                    label={t`Start time`}
                    placeholder={t`When does the stream start?`}
                    onChange={props.handleChange}
                    textValue={props.values.startTime}
                    isValid={!props.errors.startTime}
                    validationMessage={props.errors.startTime}
                    isRequired={false}
                    readonly={!props.values.manualEdit}
                  />
                  <Field
                    component={TextInput}
                    id="endTime"
                    name="endTime"
                    label={t`End time`}
                    placeholder={t`Write the title of the stream here`}
                    onChange={props.handleChange}
                    textValue={props.values.endTime}
                    isValid={!props.errors.endTime}
                    validationMessage={props.errors.endTime}
                    isRequired={false}
                    readonly={!props.values.manualEdit}
                  />
                </div>
              )}
              <div className={styles.Buttons}>
                {errorMessage && (
                  <FormValidationError errorMessage={errorMessage} />
                )}
                <FormActionButtons
                  formik={props}
                  cancelButtonLabel={t`Cancel`}
                  submitButtonLabel={t`Save`}
                  onCancel={onClose}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
