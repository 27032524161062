import React, { FC, useCallback, useMemo } from 'react'
import { FormikHelpers, FormikProps, Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import { t, Trans } from '@lingui/macro'
import {
  Team,
  getTeamsForCompetition
} from '../../../api/sportData/getTeamsForCompetition'

import { useDataApi } from '../../../api/useDataApi'
import { DropdownList } from '../../../components/Forms/DropdownListField/DropdownList'

import { Loader } from '../../../components/Loader/Loader'

import styles from './AddGamesForm.module.scss'
import { LockIcon } from './LockIcon'
import {
  ConnectTeamRequest,
  putConnectGamesToTeam
} from '../../../api/sportData/putConnectGamesToTeam'
import { FormActionButtons } from '../../../components/Forms/FormActionButtons/FormActionButtons'
import TagManager from 'react-gtm-module'

interface Props {
  onSubmitSuccess: () => void
  onCloseForm: () => void
  competitionId: number
  channelSlug: string
}

export const AddGamesForm: FC<Props> = ({
  onSubmitSuccess,
  onCloseForm,
  competitionId,
  channelSlug
}) => {
  const { data, isLoading, isError } = useDataApi({
    apiCall: useCallback(getTeamsForCompetition, []),
    initialData: {},
    parameters: useMemo(
      () => ({ competitionId, channelSlug }),
      [channelSlug, competitionId]
    )
  })

  if ((!data || Object.keys(data).length === 0) && !isLoading) return null

  if (isLoading) return <Loader />

  const channelAccountSchema = yup.object({
    teamId: yup.string().required(t`Please select a team`)
  })

  interface AddGames {
    teamId?: number
  }
  const initialValues: AddGames = {
    teamId: undefined
  }

  const onSubmit = async (
    values: AddGames,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const connectTeamValues: ConnectTeamRequest = {
      teamId: values.teamId!.toString(),
      channelSlug,
      competitionId: competitionId.toString()
    }

    const handleGTMEvent = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sports-data-added-games-to-channel-success',
          channel_slug: channelSlug,
          competion_id: competitionId,
          selected_team_id: values.teamId
        }
      })
    }
    const response = await putConnectGamesToTeam(connectTeamValues)
    if (response.hasError) {
      // @ts-ignore
      formikHelpers.setErrors(response.errors)
    } else {
      formikHelpers.setSubmitting(false)
      handleGTMEvent()
      onSubmitSuccess()
    }
  }

  return (
    <div>
      {isError && (
        <div>
          <Trans>
            There was a problem retriving the team list. Please try again
          </Trans>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={channelAccountSchema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => {
          const teamList = data.map((item: Team) => ({
            id: item.teamId,
            label: item.teamName,
            disabled: item.isConnected,
            icon: item.isConnected && LockIcon
          }))

          return (
            <Form>
              <div className={styles.AddGamesFormContainer}>
                <Field
                  component={DropdownList}
                  dropdownListData={teamList}
                  value={props.values.country}
                  id="teamId"
                  name="teamId"
                  placeholder={t`please select a team`}
                  isValid={!props.errors.teamId}
                  validationMessage={props.errors.teamId}
                  type="dark"
                  onChange={(id: string) => props.setFieldValue('teamId', id)}
                />

                <div className={styles.FormInformation}>
                  <div className={styles.LockIcon}>
                    <LockIcon />
                  </div>
                  <div>
                    <Trans>
                      This icon means that another channel has already claimed
                      that team in an competition
                    </Trans>
                  </div>
                </div>

                <FormActionButtons
                  cancelButtonLabel={t`Cancel`}
                  submitButtonLabel={t`Add games`}
                  onCancel={onCloseForm}
                  formik={props}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
