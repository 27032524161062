import React, { FC } from 'react'

import { AlertType } from './getAlertType'
import { SuccesIcon } from './icons/SuccessIcon'
import { ErrorIcon } from './icons/ErrorIcon'
import { InformationIcon } from './icons/InformationIcon'
import { NoticeIcon } from './icons/NoticeIcon'
import { GeneralIcon } from './icons/GeneralIcon'

interface Props {
  alertType: AlertType
}
export const MessageIcon: FC<Props> = ({ alertType }) => {
  switch (alertType) {
    case AlertType.Error:
      return <ErrorIcon />
    case AlertType.Success:
      return <SuccesIcon />
    case AlertType.Warning:
      return <NoticeIcon />
    case AlertType.General:
      return <GeneralIcon />
    case AlertType.Info:
      return <InformationIcon />
    default:
      return null
  }
}
