import React, { FC } from 'react'
import { ChannelListItemLink } from 'components/ChannelListItem/ChannelListItemLink'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import styles from './ChannelResult.module.scss'
export interface SearchResultChannelItem {
  name: string
  logoUrl: string
  sportName: string
  channelType: ChannelTypes
  subdomain: string
  rootDomain?: string
}
interface Props {
  channelList: SearchResultChannelItem[]
  isRootOrChildChannel: boolean
}

export const ChannelResult: FC<Props> = ({
  channelList,
  isRootOrChildChannel
}) =>
  channelList.length > 0 ? (
    <div className={styles.ListContainer} data-testid="channel-result-list">
      {channelList.map((channel: SearchResultChannelItem) => {
        const rootDomain = isRootOrChildChannel ? '' : channel.rootDomain
        const sportLabel = isRootOrChildChannel ? '' : channel.sportName
        return (
          <ChannelListItemLink
            key={channel.subdomain}
            channelSubdomain={channel.subdomain}
            channelName={channel.name}
            channelLogo={channel.logoUrl}
            rootDomain={rootDomain}
            sportLabel={sportLabel}
            channelType={channel.channelType}
          />
        )
      })}
    </div>
  ) : null
