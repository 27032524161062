import authenticationReducer from './authentication/authentication.reducer'
import paymentsReducer from './payments/payments.reducer'
import userReducer from './user/user.reducer'
import userInterfaceReducer from './userInterface/userInterface.reducer'
import nchanSubscriberReducer from './NchanSubscriber/reducer'
import countryReducer from './country/country.reducer'
import languageReducer from './language/language.reducer'
import sportsReducer from './sports/sports.reducer'
import channelThemeReducer from './channelTheme/channelTheme.reducer'
import channelReducer from './channel/channel.reducer'
import streamReducer from './stream/stream.reducer'
import redirectReducer from './redirect/redirect.reducer'
import channelFontsReducer from './channelFonts/channelFonts.slice'
import rolesReducer from './roles/roles.reducer'
import permissionsReducer from './permissions/permissions.reducer'
import channelAboutReducer from './channelAbout/channelAbout.slice'

export const storeReducer = {
  authentication: authenticationReducer,
  payments: paymentsReducer,
  user: userReducer,
  userInterface: userInterfaceReducer,
  subscriber: nchanSubscriberReducer,
  country: countryReducer,
  language: languageReducer,
  sports: sportsReducer,
  channelTheme: channelThemeReducer,
  channel: channelReducer,
  stream: streamReducer,
  redirect: redirectReducer,
  channelFonts: channelFontsReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  channelAbout: channelAboutReducer
}
