import React, { FC, useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import IconTextButton, {
  ButtonType
} from '../../../../../../components/buttons/TextIconButton'
import styles from '../../ActionButtons.module.scss'
import MeatballsIcon from '../../../Icons/MeatballsIcon'
import { Popout } from '../../../../../../components/Popout/Popout'
import { OptionsMenuItem } from './OptionsMenuItem'
import { useFeatureFlags } from 'featureFlags/useFeatureFlags'
import { FeatureFlagsAvailable } from 'featureFlags/FeatureFlagsAvailable'

interface OptionsButtonProps {
  showDownloadButton: boolean
  showEditButton: boolean
  showBroadcastInfoButton: boolean
  onDownload: () => void
  onEdit: () => void
  onBroadastInformation: () => void
  onQuickEdit: () => void
  onHelp: () => void
  onReportStream: () => void
}

export const OptionsButton: FC<OptionsButtonProps> = ({
  showDownloadButton,
  showEditButton,
  showBroadcastInfoButton,
  onDownload,
  onEdit,
  onBroadastInformation,
  onQuickEdit,
  onHelp,
  onReportStream
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const { getFlag } = useFeatureFlags()
  const showQuickEdit = getFlag(FeatureFlagsAvailable.ShowQuickEdit)
  const toggleIsOpen = () => {
    setIsOpen((open) => !open)
  }

  return (
    <div className={styles.OptionsMenuAnchor} ref={anchorRef}>
      <IconTextButton
        buttonSize="Medium"
        id="optionsBtn"
        label={null}
        type={ButtonType.Ghost}
        onClick={toggleIsOpen}
      >
        <div className={styles.MeatballsIcon} data-testid="options-btn">
          <MeatballsIcon />
        </div>
      </IconTextButton>
      <Popout
        anchor="SE"
        isOpen={isOpen}
        onClose={toggleIsOpen}
        anchorRef={anchorRef}
      >
        {showEditButton && showQuickEdit && (
          <OptionsMenuItem
            icon="edit"
            onClick={onQuickEdit}
            dataTestId="quick-edit-btn"
          >
            <Trans>Quick edit</Trans>
          </OptionsMenuItem>
        )}
        {showEditButton && (
          <OptionsMenuItem
            icon="settings"
            onClick={onEdit}
            dataTestId="edit-btn"
          >
            {showQuickEdit ? (
              <Trans>Advanced settings</Trans>
            ) : (
              <Trans>Edit</Trans>
            )}
          </OptionsMenuItem>
        )}
        {showBroadcastInfoButton && (
          <OptionsMenuItem
            icon="broadcastInfo"
            onClick={onBroadastInformation}
            dataTestId="broadcast-info-btn"
          >
            <Trans>Broadcast information</Trans>
          </OptionsMenuItem>
        )}

        {showDownloadButton && (
          <OptionsMenuItem
            icon="download"
            onClick={onDownload}
            dataTestId="download-btn"
          >
            <Trans>Download</Trans>
          </OptionsMenuItem>
        )}

        <OptionsMenuItem icon="help" onClick={onHelp} dataTestId="help-btn">
          <Trans>Help</Trans>
        </OptionsMenuItem>
        <OptionsMenuItem
          icon="flag"
          onClick={onReportStream}
          dataTestId="report-btn"
        >
          <Trans>Report stream</Trans>
        </OptionsMenuItem>
      </Popout>
    </div>
  )
}
