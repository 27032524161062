import { t } from '@lingui/macro'
import { DropDownListItem } from 'components/Forms/DropdownListField/DropdownItemList/DropdownItemList'
import { AccessRestrictionType } from 'interfaces'

export const accessRestrictionList: DropDownListItem[] = [
  {
    label: t`Login required`,
    id: AccessRestrictionType.LoginRequired
  },
  {
    label: t`Payment required`,
    id: AccessRestrictionType.PaymentRequired
  },
  {
    label: t`Subscription required`,
    id: AccessRestrictionType.SubscriptionRequired
  },

  {
    label: t`Payment and subscription`,
    id: AccessRestrictionType.PaymentAndSubscriptionRequired
  },
  {
    label: t`None`,
    id: AccessRestrictionType.None
  }
]
