import ROLES from './roles.constants'
import { UpdateRolesPayload } from './roles.interface'

const updateRoles = (payload: UpdateRolesPayload) => {
  return {
    type: ROLES.UPDATE_ROLES,
    ...payload
  }
}

const setViewingAsAdmin = () => {
  return {
    type: ROLES.SET_VIEWING_AS_ADMIN
  }
}

const rolesActions = {
  updateRoles,
  setViewingAsAdmin
}

export default rolesActions
