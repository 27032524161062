import React, { FC } from 'react'
import Button, { ButtonProps, ButtonType } from '../button/Button'
import styles from './LinkButton.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

interface LinkButtonProps extends Omit<ButtonProps, 'onClick'> {
  href: string
  linkTarget?: LinkTarget
}

export const LinkButton: FC<LinkButtonProps> = ({
  href,
  linkTarget,
  ...props
}) => {
  const target =
    linkTarget && [LinkTarget.NewWindow].includes(linkTarget)
      ? LinkTarget.NewWindow
      : LinkTarget.CurrentWindow
  return (
    <a className={styles.LinkButton} href={href} target={target}>
      <Button {...props} onClick={() => null} />
    </a>
  )
}

export { ButtonType }
