import { useSelector } from 'react-redux'
import { ViewType } from '../../redux-store/stream/stream.interface'
import { AccessRestrictionType } from '../../interfaces'
import {
  streamAccessRestrictionSelector,
  streamViewTypeSelector
} from '../../redux-store/stream/stream.selectors'
import {
  ActionPanelState,
  ActionPanelStateContext
} from './ActionPanel.interface'
import {
  getShowBroadcastInfoButton,
  getShowBuyStreamButton,
  getShowDownloadButton,
  getShowEditButton,
  getShowLoginButton,
  getShowWatchButton
} from './getShowActionButtons'
import { RolesState } from '../../redux-store/roles/roles.interface'
import { PermissionsState } from '../../redux-store/permissions/permissions.interface'
import { rolesSelector } from '../../redux-store/roles/roles.reducer'
import { permissionsSelector } from '../../redux-store/permissions/permissions.reducer'

export const useActionPanel = (): ActionPanelState => {
  const permissions: PermissionsState = useSelector(permissionsSelector)

  const roles: RolesState = useSelector(rolesSelector)
  const viewType: ViewType = useSelector(streamViewTypeSelector)
  const accessRestriction: AccessRestrictionType = useSelector(
    streamAccessRestrictionSelector
  )

  const context: ActionPanelStateContext = {
    permissions,
    roles,
    viewType,
    accessRestriction
  }

  const showLikeButton = true
  const showShareButton = true
  const showBuyStreamButton = getShowBuyStreamButton(context)
  const showLoginButton = getShowLoginButton(context)
  const showEditButton = getShowEditButton(context)
  const showWatchButton = getShowWatchButton(context)
  const showDownloadButton = getShowDownloadButton(context)
  const showBroadcastInfoButton = getShowBroadcastInfoButton(context)
  const actionPanelState: ActionPanelState = {
    showLikeButton,
    showShareButton,
    showBuyStreamButton,
    showLoginButton,
    showEditButton,
    showBroadcastInfoButton,
    showWatchButton,
    showDownloadButton
  }

  return actionPanelState
}
