import React, { FC, useState } from 'react'
import { ReporterSectionType } from './reporterSectionType'
import { ActionPanel } from '../../ActionPanel/ActionPanel'
import ShareModal from '../../../../components/Modals/ShareModal'
import { EventSummary } from '../../ActionPanel/ActionPanel.interface'

interface ReporterSectionProps {
  reporterData: ReporterSectionType
  isLiked: boolean
  onLike: () => void
  onDownload: () => void
  onEdit: () => void
  onHelp: () => void
  onReportStream: () => void
}
const ReporterSection: FC<ReporterSectionProps> = ({
  reporterData,
  isLiked,
  onEdit,
  onLike,
  onHelp,
  onDownload,
  onReportStream
}) => {
  const ActionPanelEvent: EventSummary = {
    liveStartsAt: 0,
    publishedAt: reporterData.publishedAt || 0,
    isReplay: false,
    isLive: reporterData.isLive,
    title: reporterData.title || 'Reporter',
    isVideo: false,
    minEventAmount: 0,
    amountCurrency: ''
  }
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false)
  const handleOnShare = () => {
    setShareModalIsOpen(true)
  }

  return (
    <div>
      <ActionPanel
        broadcastMessage={reporterData.broadcastMessage}
        isVideoPlaying
        isReport
        isLiked={isLiked}
        event={ActionPanelEvent}
        onDownload={onDownload}
        onLike={onLike}
        onShare={handleOnShare}
        onEdit={onEdit}
        onHelp={onHelp}
        onReportStream={onReportStream}
      />
      {shareModalIsOpen && (
        <ShareModal
          defaultOpen={shareModalIsOpen}
          mediaId={reporterData.id}
          mediaTitle={reporterData.title}
          companySlug={reporterData.companySlug}
          onClose={() => setShareModalIsOpen(false)}
        />
      )}
    </div>
  )
}
export default ReporterSection
