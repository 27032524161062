import { Dispatch } from 'redux'
import {
  setVoucherPaymentError,
  setVoucherPaymentStart,
  setVoucherPaymentSuccess
} from '../../../../redux-store/stream/stream.actions'
import { getStreamUrls } from '../../../../api/viewer/getStreamUrls'

export const createUpdateVoucherPaymentActions = async (
  dispatch: Dispatch<any>,
  channelSlug: string,
  mediaObject: any,
  adminAcesss: boolean
) => {
  try {
    dispatch(setVoucherPaymentStart())
    const streamUrl = await getStreamUrls(mediaObject, channelSlug, adminAcesss)
    dispatch(setVoucherPaymentSuccess(streamUrl))
  } catch (err: any) {
    dispatch(setVoucherPaymentError)
  }
}
