import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './ActionButtons.module.scss'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'
import { useActionPanel } from '../../../../hooks/ActionPanel/ActionPanel'
import { BuyStreamButton } from './ButtonComponents/BuyStreamButton'
import { LoginButton } from './ButtonComponents/LoginButton'
import { LikeButton } from './ButtonComponents/LikeButton'
import { ShareButton } from './ButtonComponents/ShareButton'
import { WatchButton } from './ButtonComponents/WatchButton'
import { EventSummary } from '../ActionPanel.interface'
import { OptionsButton } from './ButtonComponents/OptionsButton/OptionsButton'
import { useDispatch } from 'react-redux'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'

interface ActionButtonsProps {
  isLiked: boolean
  event: EventSummary
  onPurchase: () => void
  onLike: () => void
  onShare: () => void
  onDownload: () => void
  onEdit: () => void
  onHelp: () => void
  onWatch: () => void
  onReportStream: () => void
}
export const ActionButtons: FC<ActionButtonsProps> = ({
  isLiked,
  event,
  onPurchase,
  onLike,
  onShare,
  onDownload,
  onEdit,
  onHelp,
  onWatch,
  onReportStream
}) => {
  const {
    showBuyStreamButton,
    showLoginButton,
    showLikeButton,
    showShareButton,
    showEditButton,
    showBroadcastInfoButton,
    showWatchButton,
    showDownloadButton
  } = useActionPanel()
  const dispatch = useDispatch()
  const currentBreakPoint = useCurrentBreakpoint()
  const showIconOnly = currentBreakPoint <= Breakpoint.xs
  const isTwoRowLayout = showBuyStreamButton || showLoginButton

  return (
    <div className={styles.ActionParentContainer}>
      <div
        className={classNames(styles.ActionButtonContainer, {
          [styles.IsFluid]: isTwoRowLayout
        })}
      >
        {showWatchButton && (
          <WatchButton
            onClick={onWatch}
            showIconOnly={showIconOnly}
            isFluid={isTwoRowLayout}
          />
        )}
        {showLikeButton && (
          <LikeButton
            onClick={onLike}
            showIconOnly={showIconOnly}
            isLiked={isLiked}
            isFluid={isTwoRowLayout}
          />
        )}
        {showShareButton && (
          <ShareButton
            onClick={onShare}
            showIconOnly={showIconOnly}
            isFluid={isTwoRowLayout}
          />
        )}
        <OptionsButton
          showDownloadButton={showDownloadButton}
          showEditButton={showEditButton}
          showBroadcastInfoButton={showBroadcastInfoButton}
          onDownload={onDownload}
          onEdit={onEdit}
          onBroadastInformation={() =>
            dispatch(userInterface.openBroadcastInformationModal())
          }
          onQuickEdit={() =>
            dispatch(userInterface.openQuickEditContentDialog())
          }
          onHelp={onHelp}
          onReportStream={onReportStream}
        />
      </div>
      <div
        className={classNames(styles.ActionButtonContainer, {
          [styles.IsFluid]: isTwoRowLayout
        })}
      >
        {showLoginButton && (
          <LoginButton onPurchase={onPurchase} isFluid={isTwoRowLayout} />
        )}
        {showBuyStreamButton && (
          <BuyStreamButton
            event={event}
            onPurchase={onPurchase}
            isFluid={isTwoRowLayout}
          />
        )}
      </div>
    </div>
  )
}
