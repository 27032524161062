import React from 'react'

const ErrorIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 293 233"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_282_22455" fill="white">
      <rect x="33" y="46" width="260" height="157" rx="2" />
    </mask>
    <rect
      x="33"
      y="46"
      width="260"
      height="157"
      rx="2"
      stroke="black"
      strokeWidth="10"
      mask="url(#path-1-inside-1_282_22455)"
    />
    <mask id="path-2-inside-2_282_22455" fill="white">
      <rect x="51" y="64" width="224" height="121" rx="2" />
    </mask>
    <rect
      x="51"
      y="64"
      width="224"
      height="121"
      rx="2"
      stroke="black"
      strokeWidth="10"
      mask="url(#path-2-inside-2_282_22455)"
    />
    <path
      d="M211 67L182.682 87.6386C181.978 88.1516 181.687 89.0611 181.962 89.8874L190.698 116.094C191.107 117.321 190.261 118.607 188.973 118.719L174.5 119.971M134 149L139.193 124.451C139.375 123.589 140.1 122.948 140.977 122.872L174.5 119.971M174.5 119.971L165.427 129.501C164.647 130.32 164.701 131.623 165.547 132.375L200 163"
      stroke="black"
      strokeWidth="5"
      strokeLinecap="round"
    />
    <path
      d="M119.5 3L162.086 45.5858C162.867 46.3668 164.133 46.3668 164.914 45.5858L207.5 3"
      stroke="black"
      strokeWidth="5"
      strokeLinecap="round"
    />
    <mask id="path-5-inside-3_282_22455" fill="white">
      <rect x="143" y="198.5" width="41" height="33" rx="2" />
    </mask>
    <rect
      x="143"
      y="198.5"
      width="41"
      height="33"
      rx="2"
      stroke="black"
      strokeWidth="10"
      mask="url(#path-5-inside-3_282_22455)"
    />
    <path
      d="M113 229.5H213"
      stroke="black"
      strokeWidth="5"
      strokeLinecap="round"
    />
    <circle cx="53" cy="180" r="50" fill="#F5F5F5" />
    <path
      d="M3.3125 180C3.3125 193.178 8.54742 205.816 17.8656 215.134C27.1838 224.453 39.8221 229.687 53 229.687C66.1779 229.687 78.8162 224.453 88.1344 215.134C97.4526 205.816 102.687 193.178 102.687 180C102.687 166.822 97.4526 154.184 88.1344 144.866C78.8162 135.547 66.1779 130.312 53 130.312C39.8221 130.312 27.1838 135.547 17.8656 144.866C8.54742 154.184 3.3125 166.822 3.3125 180V180Z"
      stroke="#2F3135"
      strokeWidth="6.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.668 213.898C22.7232 195.538 41.2467 155.638 89.6578 146.46"
      stroke="#2F3135"
      strokeWidth="6.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9004 146.963C35.7047 154.175 72.7517 173.816 80.5206 221.428"
      stroke="#2F3135"
      strokeWidth="6.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.4801 130.736C46.7346 136.141 45.7921 141.535 43.72 146.533C41.6478 151.531 38.4972 156.01 34.4932 159.648C30.4892 163.287 25.7308 165.997 20.5581 167.583C15.3853 169.17 9.92601 169.594 4.57031 168.826"
      stroke="#2F3135"
      strokeWidth="6.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.0767 229.679C31.4508 196.598 70.0923 158.668 102.678 179.077"
      stroke="#2F3135"
      strokeWidth="6.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ErrorIcon
