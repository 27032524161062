import React, { FC } from 'react'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { BroadcastThumbnailInterface } from '../broadcastCardTypes'
import { DetailsRow, BroadcastDetailsType } from '../DetailsRow'
import styles from './index.module.scss'
import GameThumbNail from './TeamVsTeam/GameThumbNail'
import BottomTimeLabel from '../BottomTimeLabel'
import { ThumbnailLabel } from '../ThumbnailLabel/ThumbnailLabel'
import { Tooltip } from '../../../Tooltip/Tooltip'
import { RootDomainLabel } from '../RootDomainLabel/RootDomainLabel'

interface Props {
  content: BroadcastThumbnailInterface
  size: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'keepRatio'
  showDetails: boolean
  isLive: boolean
  showRootDomainLabel?: boolean
}

const GameContent: FC<Props> = ({
  content,
  size,
  showDetails,
  isLive,
  showRootDomainLabel
}) => {
  const defaultThumbnail = 'assets/placeholder_live_solidsport'
  const replayDefaultThumbnail = 'assets/placeholder_replay_solidsport'
  const shouldShowImage =
    content.thumbnailImageUrl &&
    !content.thumbnailImageUrl.includes(defaultThumbnail) &&
    !content.thumbnailImageUrl.includes(replayDefaultThumbnail) &&
    content.awayTeam &&
    content.awayTeam !== null
  const title = content?.title

  return (
    <div
      className={classNames(styles.GameContent, styles[size], {
        [styles.WithBottomRow]: showDetails
      })}
      data-testid="game-thumbnail"
    >
      {shouldShowImage || !content.homeTeam?.name || !content.awayTeam ? (
        <div className={styles.ImageWrapper}>
          <img
            data-testid="game-thumbnail-img"
            src={content.thumbnailImageUrl}
            alt={
              content.homeTeam && content.awayTeam
                ? `${content.homeTeam.name} - ${content.awayTeam.name}`
                : t`thumbnail`
            }
          />
          <div className={styles.ThumbnailLabelContainer}>
            <ThumbnailLabel
              isLive={isLive}
              isReplay={content.isReplay}
              highlightFromDate={content.highlightFromDate}
              startAt={content.startTime}
              type={content.type}
              publishedAt={content.publishedAt}
            />
          </div>
          {(content.isReplay || content.highlightFromDate) &&
            content.duration > 0 && (
              <div className={styles.TimeLabelContainer}>
                <BottomTimeLabel durationInSeconds={content.duration} />
              </div>
            )}
          {showRootDomainLabel && content.rootDomain && (
            <div className={styles.RootDomainLabelContainer}>
              <RootDomainLabel label={content.rootDomain} />
            </div>
          )}
        </div>
      ) : (
        <GameThumbNail
          homeTeam={content.homeTeam}
          awayTeam={content.awayTeam}
          thumbnailSize={size}
          isReplay={content.isReplay}
          startTime={content.startTime}
          isLive={isLive}
          duration={content.duration}
          highlightFromDate={content.highlightFromDate}
          type={content.type}
          hasHoverEffect={showDetails}
          rootDomain={content.rootDomain}
          showRootDomainLabel={showRootDomainLabel}
        />
      )}
      {showDetails && (
        <div className={styles.BottomContainer}>
          <div data-testid="game-card-title" className={styles.Title}>
            {title}
          </div>
          <div className={styles.TooltipWrapper}>
            <Tooltip text={title} />
          </div>

          <DetailsRow
            broadcastType={BroadcastDetailsType.Game}
            viewers={content.viewers}
            views={content.views}
            showViewers={content.showViewers}
            showViews={content.showViews}
            live={isLive}
            hasHighlight={content.hasHighlight}
            highlightFromDate={content.highlightFromDate || 0}
            publishedAt={content.publishedAt}
            replay={content.isReplay}
            typeOfGame={content.typeOfGame}
            endTime={content.endTime}
            eventName={content.event?.name}
            locationName={content.location?.name}
          />
        </div>
      )}
    </div>
  )
}
export default GameContent
