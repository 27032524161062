import { Trans } from '@lingui/macro'
import React, { FC } from 'react'
import styles from './Referees.module.scss'
import { Referee } from 'api/gameStatistics/getTeamLineups'

interface Props {
  refereeList: Referee[]
}

export const Referees: FC<Props> = ({ refereeList }) => {
  return refereeList?.length > 0 ? (
    <div className={styles.Container} data-testid="referees-container">
      <div className={styles.Title}>
        <Trans>Referees</Trans>
      </div>
      <div className={styles.RefereList} data-testid="referee-list">
        {refereeList.map((referee: Referee) => (
          <div className={styles.RefereeName} key={referee.id}>
            {referee.name}
          </div>
        ))}
      </div>
    </div>
  ) : null
}
