import { ViewerInputParameters } from './viewerPage.interfaces'

export const getViewerParams = (parameters: ViewerInputParameters) => {
  const { company, ident, isGamePath, currentUrl } = parameters
  if (isGamePath) {
    return {
      company: company,
      gameIdent: ident,
      descriptionUrl: currentUrl
    }
  }
  return {
    company: company,
    mediaObjectSlug: ident,
    descriptionUrl: currentUrl
  }
}
