import React, { FC } from 'react'
import { t } from '@lingui/macro'
import PAYMENTS from '../../../../redux-store/payments/payments.constants'
import { PaymentPropsType } from '../../viewerPage.interfaces'
import BuyOptionBannerFactory, {
  BUY_OPTIONS
} from '../BuyOptionBanner/BuyOptionBannerFactory'
import { PaymentDetails } from '../PaymentModal'
import usePriceFromPreference from 'hooks/PriceFromPreference'
import styles from './BuyBannerView.module.scss'

interface PayPerStreamProps {
  payPerView: PaymentPropsType
  defaultCurrency: string
  mediaObjectId: string
  onSelect: (payment: PaymentPropsType, paymentDetails: PaymentDetails) => void
}

export const PayPerStreamView: FC<PayPerStreamProps> = ({
  payPerView,
  defaultCurrency,
  mediaObjectId,
  onSelect
}) => {
  const { price } = payPerView
  const ppvPrice = usePriceFromPreference(price!)
  const onPaymentSelect = () => {
    onSelect(payPerView, {
      type: PAYMENTS.TYPE_PPV,
      mediaObjectId,
      currency: ppvPrice!.currency,
      value: ppvPrice?.amount!
    })
  }
  return (
    <div className={styles.BuyViewContainer}>
      <div
        data-testid="buy-banner-per-stream-heading"
        className={styles.BannerHeading}
      >{t`Just buy the stream`}</div>
      <BuyOptionBannerFactory
        option={BUY_OPTIONS.PER_STREAM}
        onSelect={onPaymentSelect}
        amount={ppvPrice ? ppvPrice?.amount! : 0}
        currency={ppvPrice ? ppvPrice.currency : defaultCurrency}
      />
    </div>
  )
}
