import React, { FC } from 'react'

const LiveIcon: FC = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0932 7.91341L1.1782 1.03418C1.11153 0.995685 1.0282 1.0438 1.0282 1.12078V14.8792C1.0282 14.9562 1.11153 15.0043 1.1782 14.9658L13.0932 8.08661C13.1598 8.04812 13.1598 7.9519 13.0932 7.91341Z"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default LiveIcon
