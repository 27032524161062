import { t } from '@lingui/macro'
import React, { FC } from 'react'
import classNames from 'classnames'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import styles from '../ActionButtons.module.scss'
import TicketsIcon from '../../Icons/TicketsIcon'

interface LoginButtonProps {
  onPurchase: () => void
  isFluid: boolean
}

export const LoginButton: FC<LoginButtonProps> = ({ onPurchase, isFluid }) => (
  <div
    data-testid="ticket-btn"
    className={classNames({
      [styles.IsFluid]: isFluid
    })}
  >
    <IconTextButton
      externalStyle={styles.PurchaseButton}
      buttonSize="Medium"
      id="loginBtn"
      label={t`Watch`}
      onClick={onPurchase}
      type={ButtonType.CTA}
      isFluid={isFluid}
    >
      <div className={styles.TicketIcon}>
        <TicketsIcon />
      </div>
    </IconTextButton>
  </div>
)
