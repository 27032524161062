import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { NativeAd } from '../../../advertising/NativeAd/NativeAd'
import ContentContainer from '../../../components/ContentContainer'
import { getChannelAdTargeting } from '../../ChannelPage/Start/getChannelAdTargeting'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'

import styles from '../index.module.scss'

interface Props {
  mediaPlayerData: any
}

export const AboutStream: FC<Props> = ({ mediaPlayerData }) => {
  const description = mediaPlayerData?.mediaObject?.description
  const splitTextArray = description ? description.split('\n') : []
  const splitText = splitTextArray.map((text: string) => (
    <div key={text}>{text}</div>
  ))

  const channelDetails = useSelector(channelSelector).data
  const { parentChannelDomain, partnerDomain, sportCategory, countryCode } =
    getChannelAdTargeting(channelDetails)

  return (
    <>
      <ContentContainer
        hasLeftRightPadding
        hasTopBottomPadding={false}
        hasLightBackground
      >
        <NativeAd
          slotPath="/21792873324/Viewer_About_Slot"
          adId="div-gpt-ad-1680188725142-0"
          size={['fluid']}
          targetingOptions={{
            partnerDomain: partnerDomain,
            parentChannelDomain: parentChannelDomain,
            sportCategory: sportCategory,
            countryCode: countryCode
          }}
        />
      </ContentContainer>
      <div className={styles.Description}>
        <div className={styles.ContentContainer}>
          <div className={styles.Title}>
            {mediaPlayerData?.mediaObject?.title}
          </div>
          <div className={styles.Text}>
            {splitTextArray.length === 0 ? description : splitText}
          </div>
        </div>
      </div>
    </>
  )
}
