import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import LikeIcon from '../../Icons/LikeIcon'
import LikedIcon from '../../Icons/LikedIcon'
import styles from '../ActionButtons.module.scss'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../../hooks/CurrentBreakpoint'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import {
  decrementStreamUserLikeCount,
  incrementStreamUserLikeCount
} from 'redux-store/stream/stream.actions'

interface LikeButtonProps {
  showIconOnly: boolean
  isFluid: boolean
  onClick: () => void
  isLiked: boolean
}

export const LikeButton: FC<LikeButtonProps> = ({
  showIconOnly,
  isFluid,
  onClick,
  isLiked
}) => {
  const dispatch = useDispatch()
  const onLikeClick = () => {
    if (isLiked) {
      dispatch(decrementStreamUserLikeCount())
    } else {
      dispatch(incrementStreamUserLikeCount())
    }
    onClick()
  }

  const stream = useSelector(streamSelector)
  const likeCount = stream.data.mediaObject.user_like_counts

  const currentBreakPoint = useCurrentBreakpoint()
  const label = likeCount === 0 ? t`Like` : likeCount
  const isJustLikeIcon =
    (currentBreakPoint < Breakpoint.mobile &&
      currentBreakPoint > Breakpoint.sm) ||
    showIconOnly

  return (
    <div
      data-testid="like-btn"
      className={classNames({
        [styles.IsFluid]: isFluid
      })}
    >
      <IconTextButton
        buttonSize="Medium"
        id="likeBtn"
        label={isJustLikeIcon ? null : label}
        onClick={() => onLikeClick()}
        type={ButtonType.Ghost}
        isFluid={isFluid}
      >
        <div className={styles.IconStroke}>
          {isLiked ? <LikedIcon /> : <LikeIcon />}
        </div>
      </IconTextButton>
    </div>
  )
}
