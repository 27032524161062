import { t } from '@lingui/macro'
import classNames from 'classnames'
import React, { FC } from 'react'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import PreviewIcon from '../../Icons/PreviewIcon'
import styles from '../ActionButtons.module.scss'

interface WatchButtonProps {
  showIconOnly: boolean
  isFluid: boolean
  onClick: () => void
}

export const WatchButton: FC<WatchButtonProps> = ({
  showIconOnly,
  isFluid,
  onClick
}) => (
  <div
    data-testid="watch-btn"
    className={classNames({
      [styles.IsFluid]: isFluid
    })}
  >
    <IconTextButton
      buttonSize="Medium"
      id="watchBtn"
      label={showIconOnly ? null : t`Watch`}
      onClick={onClick}
      type={ButtonType.Ghost}
      isFluid={isFluid}
    >
      <div className={styles.IconStroke}>
        <PreviewIcon />
      </div>
    </IconTextButton>
  </div>
)
