import { FC, useEffect } from 'react'
import { setDefaultTheme } from '../redux-store/channelTheme/channelTheme.actions'

interface Props {
  company?: string
  dispatch: any
  channel: any
  setParentReleaseDate: any
  setComingSoonAttributes: any
  setIsChildOfThemedRoot: any
  setRootChannelType: any
  setCustomTheme: any
}

export const useRelevantTheme: FC<Props> = ({
  company,
  dispatch,
  channel,
  setParentReleaseDate,
  setComingSoonAttributes,
  setIsChildOfThemedRoot,
  setRootChannelType,
  setCustomTheme
}) => {
  useEffect(() => {
    if ((!channel.isLoading && channel.data) || !company) {
      if (channel.data?.isChildOfRootChannel && channel.data?.root_channel) {
        setCustomTheme(dispatch, channel.data?.root_channel.channel_theme)
        setParentReleaseDate(channel.data?.root_channel.release_date)
        setComingSoonAttributes({
          logoUrl: channel.data?.root_channel.logo_image_url,
          backgroundImageUrl: channel.data?.root_channel.background_image_url,
          vanityUrl: channel.data?.root_channel.root_domain,
          channelSlug: channel.data?.root_channel.subdomain
        })
        setRootChannelType(channel.data?.root_channel.type)
      } else if (channel.data?.isRootChannel && channel.data?.channel_theme) {
        setCustomTheme(dispatch, channel.data?.channel_theme)
      } else {
        dispatch(setDefaultTheme())
      }
      setIsChildOfThemedRoot(channel?.data?.isChildOfRootChannel || false)
    }
  }, [
    channel,
    dispatch,
    setComingSoonAttributes,
    setCustomTheme,
    setIsChildOfThemedRoot,
    setParentReleaseDate,
    setRootChannelType,
    company
  ])

  return null
}
