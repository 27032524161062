/* eslint-disable @typescript-eslint/indent */
import { getMediaObject } from '../../api/viewer/getMediaObject'
import { getStreamUrls } from '../../api/viewer/getStreamUrls'
import { VideoPlayerData, ViewerParameters } from './viewerPage.interfaces'
import { mapThumbnailObject } from './mapThumbnailObject'
import { Game, Team } from '../../interfaces'

export const getMediaObjectParams = (viewerParams: ViewerParameters) =>
  viewerParams.gameIdent
    ? {
        game_ident: viewerParams.gameIdent,
        company: viewerParams.company,
        description_url: viewerParams.descriptionUrl
      }
    : {
        company: viewerParams.company,
        media_object_slug: viewerParams.mediaObjectSlug,
        description_url: viewerParams.descriptionUrl
      }

export const getVideoPlayerData = async (
  viewerParams: ViewerParameters
): Promise<VideoPlayerData> => {
  try {
    const mediaObjectParams = getMediaObjectParams(viewerParams)
    const mediaObjectData = await getMediaObject(mediaObjectParams)

    const streamUrlData = await getStreamUrls(
      mediaObjectData,
      viewerParams.company!,
      viewerParams.hasAdminAccess
    )
    if (streamUrlData.geoBlock) {
      return {
        mediaObjectAvailable: false,
        game: undefined,
        mediaObject: mediaObjectData,
        thumbnailObject: null,
        streamUrl: streamUrlData
      }
    }
    if (mediaObjectData.type === 'game') {
      return {
        mediaObjectAvailable: false,
        game: mapMediaObjectGame(mediaObjectData),
        mediaObject: mediaObjectData,
        thumbnailObject: null,
        streamUrl: streamUrlData
      }
    }

    const mappedThumbnailObject = mapThumbnailObject(mediaObjectData)

    return {
      mediaObjectAvailable: true,
      mediaObject: mediaObjectData,
      game: undefined,
      thumbnailObject: mappedThumbnailObject,
      streamUrl: streamUrlData
    }
  } catch (err: any) {
    console.log('getVideoPlayerData err', err)
    throw new Error('getVideoPlayerData')
  }
}

const mapMediaObjectTeam = (input: Team) => ({
  name: input.name,
  id: input.id,
  slug: input.slug,
  shirt_colors: input.shirt_colors,
  country_code: input.country_code,
  badge_url: input.badge_url
})

const mapMediaObjectGame = (input: Game) => ({
  id: input.id,
  type: input.type,
  title: input.title,
  slug: input.display_company.subdomain,
  company: {
    subdomain: input.display_company.subdomain
  },
  display_company: {
    subdomain: input.display_company.subdomain
  },
  is_live: input.is_live,
  view_count: input.view_count,
  live_start_at: input.live_start_at,
  show_results: input.show_results,
  start_at: input.start_at,
  is_replay: input.is_replay,
  end_at: input.end_at,
  home_team: input.home_team ? mapMediaObjectTeam(input.home_team) : null,
  away_team: input.away_team ? mapMediaObjectTeam(input.away_team) : null,
  thumbnail: input.thumbnail,
  home_score: input.home_score,
  away_score: input.away_score,
  location: input.location,
  price: input.price,
  description: input.description,
  categories: input.categories
})
