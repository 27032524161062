import { LoginIcon } from 'assets/icons/LoginIcon'
import React, { FC } from 'react'
import IconTextButton, { ButtonType } from '../TextIconButton'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { t } from '@lingui/macro'

export const OpenLoginButton: FC = () => {
  const dispatch = useDispatch()
  const handleSingInClick = () => {
    dispatch(authenticationActions.openLoginLayer(undefined, 'login'))
  }
  return (
    <IconTextButton
      type={ButtonType.Secondary}
      onClick={handleSingInClick}
      id={'login-button'}
      buttonSize={'Medium'}
      label={t`Login`}
      isFluid
      data-testid="login-button"
    >
      <LoginIcon />
    </IconTextButton>
  )
}
