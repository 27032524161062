import { AccessRestrictionType } from '../../interfaces'
import { RootState } from '../store'
import { ViewType } from './stream.interface'

export const streamSelector = (state: RootState) => {
  return {
    ...state.stream,
    hasGame: !!state.stream?.data?.mediaObject?.game,
    canComment: !!state.stream?.data?.mediaObject?.enable_comments
  }
}

export const streamIsLoadingSelector = (state: RootState) =>
  state.stream.isLoading

export const streamAdminAccessSelector = (state: RootState) =>
  !!state.stream?.data?.streamUrl?.adminAccess

export const streamViewTypeSelector = (state: RootState): ViewType => {
  const { mediaObject } = state.stream?.data || {}

  if (mediaObject?.broadcast_message) {
    return ViewType.BROADCAST_MESSAGE
  }
  if (mediaObject?.is_live_and_started) {
    if (mediaObject?.live_start_at) {
      return ViewType.LIVE
    }
    return ViewType.NO_LIVESTREAM
  }
  if (mediaObject?.is_replay) {
    return ViewType.REPLAY
  }
  if (mediaObject?.highlight_source) {
    return ViewType.HIGHLIGHT
  }
  if (mediaObject?.type === 'video') {
    return ViewType.VIDEO
  }
  if (!mediaObject?.live_start_at) {
    return ViewType.NO_LIVESTREAM
  }
  return ViewType.UPCOMING
}

export const streamAccessRestrictionSelector = (
  state: RootState
): AccessRestrictionType => state.stream?.data?.mediaObject?.access_restriction
