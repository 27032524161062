import React, { FC } from 'react'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import PlusIcon from 'pages/ViewerPage/Chat/MessageInput/UploadButton/PlusIcon'
import { t } from '@lingui/macro'
interface Props {
  onClick: () => void
}

export const CreateNewBannerButton: FC<Props> = ({ onClick }) => {
  return (
    <IconTextButton
      id="create-start-page-banner"
      type={ButtonType.Ghost}
      label={t`Create start page banner`}
      buttonSize="Medium"
      onClick={onClick}
    >
      <PlusIcon />
    </IconTextButton>
  )
}
