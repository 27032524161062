import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'
interface Props {
  link: string
  rootDomain?: string
  children: React.ReactNode
}

export const ThumbnailLink: FC<Props> = ({ link, rootDomain, children }) => {
  if (rootDomain) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        target="_blank"
        href={`https://${rootDomain}${link}`}
        rel="noopener noreferrer"
        className={styles.BroadcastThumbnailLink}
      >
        {children}
      </a>
    )
  }
  return (
    <Link to={link} className={styles.BroadcastThumbnailLink}>
      {children}
    </Link>
  )
}
