import React, { FC, useEffect, useState } from 'react'
import { SignupStep1Form } from './Forms/SignupStep1Form'
import { TopTextSection } from 'components/Modals/Sections/TopTextSection/TopTextSection'
import { SignupStep2Form } from './Forms/SignupStep2Form'
import { t } from '@lingui/macro'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { SignupStep3Form } from './Forms/SignupStep3Form'
import useAuthentication from 'hooks/Authentication'
import { useDispatch, useSelector } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { SignupValues, getSignupToken } from './Signup'
import { SuccessContent } from 'components/Modals/SuccessModal/SuccessContent'
import { ClickableTextButton } from 'components/buttons/ClickableTextButton/ClickableTextButton'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { getSubTextForPhoneNumberForm } from './getTextForForm'
import {
  triggerGTMOnSkipAddPhoneNumber,
  triggerGTMPhoneNumberIsAdded
} from './gtmForSignup'

import styles from './SignupContent.module.scss'

export enum SignupStep {
  FirstStep = 'firstStep',
  SecondStep = 'secondStep',
  ThirdStep = 'thirdStep',
  Success = 'success'
}
interface Props {
  onLoginClick: () => void
  onSwitchStep: (step: SignupStep) => void
  onAllFormSuccess: () => void
}
export const SignupContent: FC<Props> = ({
  onLoginClick,
  onSwitchStep,
  onAllFormSuccess
}) => {
  const channel = useSelector(channelSelector)
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel
  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const channelSlug = channel?.data?.subdomain
  const channelName = channel?.data?.name
  const rootChannelName = isRootChannel
    ? channel?.data?.name
    : channel?.data?.root_channel?.name
  const channelType = channel?.data?.type
  const { error, user } = useAuthentication()
  const [showFirstStep, setShowFirstStep] = useState(true)
  const [showSecondStep, setShowSecondStep] = useState(false)
  const [showThirdStep, setShowThirdStep] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const dispatch = useDispatch()

  const firstStepTitle = t`Create new account`
  const firstStepSubTitle = isRootOrChildChannel
    ? t`Start creating your account to take part of the action on ${rootChannelName} Your account will be handled by Solidsport.`
    : t`Start creating your account to take part of the action on Solidsport, the leading sport streaming provider.`

  const [title, setTitle] = useState(firstStepTitle)
  const [subTitle, setSubTitle] = useState(firstStepSubTitle)
  const [showTopContent, setShowTopcontent] = useState(false)
  const breakpoint = useCurrentBreakpoint()
  const showTitleForFirststep = breakpoint > Breakpoint.sm

  const [signupValues, setSignupValues] = useState<SignupValues>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    emailConsent: false
  })
  useEffect(() => {
    dispatch(authenticationActions.clearState())
  }, [])

  const registerNewUser = (userValues: SignupValues) => {
    dispatch(
      authenticationActions.register(
        userValues.firstName,
        userValues.lastName,
        userValues.email,
        userValues.password,
        getSignupToken(),
        userValues.emailConsent
      )
    )
  }
  useEffect(() => {
    if (showFirstStep) {
      setShowTopcontent(showTitleForFirststep)
    }
  }, [showTitleForFirststep])

  const handleFirstStepSubmit = (
    firstName: string,
    lastName: string,
    email: string
  ) => {
    setShowFirstStep(false)
    onSwitchStep(SignupStep.SecondStep)
    setSignupValues({ firstName, lastName, email, password: '' })
    setShowTopcontent(true)
    setTitle(t`Choose password`)
    setSubTitle(t`Choose a strong password`)
    setShowSecondStep(true)
  }
  const handleSecondStepSubmit = (
    password: string,
    recieveEmailChecked: boolean
  ) => {
    const userValues = {
      ...signupValues,
      password,
      emailConsent: recieveEmailChecked
    }
    setSignupValues(userValues)
    registerNewUser(userValues)
  }

  useEffect(() => {
    if (user) {
      configureThirdStep()
    }
  }, [user])

  const configureThirdStep = () => {
    setShowSecondStep(false)
    setShowFirstStep(false)
    setShowThirdStep(true)
    onSwitchStep(SignupStep.ThirdStep)
    setTitle(t`Account successfully created!`)
    const subText = getSubTextForPhoneNumberForm(
      channelType,
      isRootOrChildChannel,
      rootChannelName
    )
    setSubTitle(subText)
  }

  const handleThirdStepSubmit = () => {
    triggerGTMPhoneNumberIsAdded(channelName, channelSlug, rootChannelName)
    setShowFirstStep(false)
    setShowThirdStep(false)
    setTitle('')
    setSubTitle('')
    onSwitchStep(SignupStep.Success)
    setShowSuccessMessage(true)
  }

  return (
    <div className={styles.Container}>
      {showTopContent && <TopTextSection title={title} subText={subTitle} />}
      {showFirstStep && (
        <SignupStep1Form
          onSubmitForm={(firstName: string, lastName: string, email: string) =>
            handleFirstStepSubmit(firstName, lastName, email)
          }
          initialValues={{
            firstName: signupValues.firstName,
            lastName: signupValues.lastName,
            email: signupValues.email,
            confirmEmail: signupValues.email
          }}
        />
      )}
      {showSecondStep && (
        <SignupStep2Form
          onSubmitForm={(password: string, recieveEmailChecked: boolean) =>
            handleSecondStepSubmit(password, recieveEmailChecked)
          }
          onBackClicked={() => {
            setShowFirstStep(true)
            setTitle(firstStepTitle)
            onSwitchStep(SignupStep.FirstStep)
            setSubTitle(firstStepSubTitle)
            setShowSecondStep(false)
          }}
          errorMessage={error?.message}
        />
      )}
      {showThirdStep && (
        <SignupStep3Form
          onSubmitFormSuccess={handleThirdStepSubmit}
          onSkipClicked={() => {
            triggerGTMOnSkipAddPhoneNumber(
              channelName,
              channelSlug,
              rootChannelName
            ),
              onAllFormSuccess()
          }}
        />
      )}
      {showSuccessMessage && (
        <SuccessContent
          title={t`Phone number was successfully added!`}
          subText=""
          buttonLabel={t`Continue`}
          onButtonClick={onAllFormSuccess}
          customStyle={styles.SuccessContent}
        />
      )}
      {!showThirdStep && !showSuccessMessage && (
        <div className={styles.LoginLinkContainer}>
          <ClickableTextButton
            defaultText={t`Do you already have an account?`}
            onClick={onLoginClick}
            clickableText={t`Login`}
          />
        </div>
      )}
    </div>
  )
}
