import React, { FC } from 'react'

const MeatballsIcon: FC = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.11765" cy="10.5005" r="2.11765" fill="#2F3135" />
      <circle cx="10.0005" cy="10.5005" r="2.11765" fill="#2F3135" />
      <circle cx="17.8833" cy="10.5005" r="2.11765" fill="#2F3135" />
    </svg>
  )
}

export default MeatballsIcon
