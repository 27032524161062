import { TwoColumModal } from 'features/customContent/NewContentModal/TwoColumnModal'
import React, { FC } from 'react'
import { InfoSection } from './InfoSection/InfoSection'
import { t } from '@lingui/macro'
import { AppInfoSection } from './AppInfoSection/AppInfoSection'
import { Divider } from 'components/page/header/navigationHeader/hamburgerMenuContent/divider/Divider'
import Button, { ButtonType } from 'components/buttons/button/Button'

import styles from './BroadcastInformationModal.module.scss'
interface Props {
  defaultOpen: boolean
  onClose: () => void
}

export const BroadcastInformationModal: FC<Props> = ({
  defaultOpen,
  onClose
}) => {
  return (
    <TwoColumModal
      modalHeader={t`Information`}
      onClose={onClose}
      defaultOpen={defaultOpen}
    >
      <InfoSection />
      <Divider />
      <AppInfoSection />
      <div className={styles.ButtonContainer}>
        <Button
          type={ButtonType.Secondary}
          label={t`Got it`}
          roleType="button"
          onClick={onClose}
          buttonSize="Medium"
        />
      </div>
    </TwoColumModal>
  )
}
