import { t } from '@lingui/macro'
import classNames from 'classnames'
import React, { FC } from 'react'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import ShareIcon from '../../Icons/ShareIcon'
import styles from '../ActionButtons.module.scss'

interface ShareButtonProps {
  showIconOnly: boolean
  isFluid: boolean
  onClick: () => void
}

export const ShareButton: FC<ShareButtonProps> = ({
  showIconOnly,
  isFluid,
  onClick
}) => (
  <div
    data-testid="share-btn"
    className={classNames({
      [styles.IsFluid]: isFluid
    })}
  >
    <IconTextButton
      buttonSize="Medium"
      id="shareBtn"
      label={showIconOnly ? null : t`Share`}
      onClick={onClick}
      type={ButtonType.Ghost}
      isFluid={isFluid}
    >
      <div className={styles.IconStroke}>
        <ShareIcon />
      </div>
    </IconTextButton>
  </div>
)
