import { Trans } from '@lingui/macro'
import React, { FC } from 'react'
import GeoBlockedIcon from './GeoBlockedIcon'
import styles from './GeoBlockedView.module.scss'

interface Props {
  title: string
  backgroundImage: string
}

const GeoBlockedView: FC<Props> = ({ title, backgroundImage }) => {
  return (
    <div
      className={styles.GeoBlockedView}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center'
      }}
    >
      <div className={styles.Icon}>
        <GeoBlockedIcon />
      </div>
      <div className={styles.Title}>
        {title}
        <div>
          <Trans>content unavailable</Trans>
        </div>
      </div>
      <div className={styles.SubText}>
        <Trans>
          The stream you are looking for is not available in your region.
        </Trans>
      </div>
    </div>
  )
}
export default GeoBlockedView
