import { useParams } from 'react-router-dom'
import { Company, TimelineObject } from '../interfaces'
import { RelatedArticlesResponseType } from '../interfaces/RelatedArticlesResponse.interface'
import URL from './URL'

export const TimelineObjectPath = (
  timelineObject: TimelineObject | RelatedArticlesResponseType,
  currentCompany: Company | undefined = undefined
): string => {
  let companyToUse = timelineObject.display_company.subdomain
  let mediaPathToUse = ''
  let idToUse = ''

  switch (timelineObject.type) {
    case 'game':
      mediaPathToUse = 'games/g'
      idToUse = timelineObject.ident
      break
    case 'media_object':
    case 'livestream':
    case 'video':
      mediaPathToUse = 'watch'
      idToUse = timelineObject.slug
      break
    case 'article':
      mediaPathToUse = 'news'
      idToUse = timelineObject.slug
      break
    default:
      break
  }

  if (currentCompany?.type === 'team') {
    companyToUse = currentCompany?.subdomain
  }

  return URL.build(companyToUse, mediaPathToUse, idToUse)
}

export const EventGamesTeamPath = (timelineObject: TimelineObject): string => {
  const { timeframe } = useParams<{ timeframe: string }>()
  return URL.build(
    timelineObject.event?.company?.subdomain,
    'event',
    timelineObject.event.slug,
    'games',
    timeframe || 'upcoming',
    timelineObject.home_team?.slug
  )
}
