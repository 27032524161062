import React, { FC } from 'react'

import SolidsportLogo from '../assets/images/Solid_Logotype.png'
import BasicHeader from '../components/page/basicHeader/Header'
import Header from '../components/page/header/index'
import { TopSpacer } from './TopSpacer'
import { AccountHeader } from 'components/page/header/AccountHeader/AccountHeader'

interface Props {
  currentUser: any
  showBasicHeader: boolean
  showAccountHeader: boolean
  shouldHaveTransparentBackground: boolean
  rootChannelParsedType: any
  addTopSpacer: boolean
  isRootOrChild: boolean
  isRootClubChannel: boolean
}

export const AppHeader: FC<Props> = ({
  currentUser,
  showBasicHeader,
  showAccountHeader,
  shouldHaveTransparentBackground,
  rootChannelParsedType,
  addTopSpacer,
  isRootOrChild,
  isRootClubChannel
}) => (
  <>
    {showBasicHeader && !showAccountHeader && (
      <BasicHeader headerImgPath={SolidsportLogo} />
    )}
    {showAccountHeader && <AccountHeader />}
    {!showBasicHeader && !showAccountHeader && (
      <Header
        currentUser={currentUser}
        transparentBackground={shouldHaveTransparentBackground}
        rootChannelType={rootChannelParsedType}
      />
    )}
    {addTopSpacer && (
      <TopSpacer
        isRootClubChannel={isRootClubChannel}
        isRootOrChild={isRootOrChild}
      />
    )}
  </>
)
