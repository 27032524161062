import React, { FC } from 'react'
import ContentSlider from '../ContentSlider'
import styles from './TeamSlider.module.scss'
import { Link } from 'react-router-dom'
import { DefaultTeamBadge } from 'components/DefaultTeamBadge/DefaultTeamBadge'
import ContentContainer from 'components/ContentContainer'

export interface Team {
  logoUrl: string
  name: string
  subDomain: string
}
interface Props {
  teams: Team[]
}

export const TeamSlider: FC<Props> = ({ teams }) => {
  return (
    <ContentContainer
      hasLeftRightPadding={false}
      hasTopBottomPadding={true}
      hasLightBackground={true}
    >
      <div className={styles.TeamSlider} data-testid="team-slider">
        <ContentSlider leftPadding={false} showArrows>
          <div className={styles.TeamsWrapper} data-testid="teams">
            {teams.map((team: Team, index: number) => (
              <Link
                to={`/${team.subDomain}`}
                className={styles.Team}
                key={`${team.subDomain}-${index}`}
              >
                {team.logoUrl ? (
                  <img src={team.logoUrl} alt={team.name} />
                ) : (
                  <DefaultTeamBadge teamName={team.name} />
                )}
                <div className={styles.TeamNameWrapper}>
                  <div className={styles.TeamName}>{team.name}</div>
                </div>
              </Link>
            ))}
          </div>
        </ContentSlider>
      </div>
    </ContentContainer>
  )
}
