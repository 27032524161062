import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { useQuery } from '@tanstack/react-query'

import { getStartPageBanner } from 'api/customContent/getStartPageBanner'
import { deleteStartPageBanner } from 'api/customContent/deleteStartPageBanner'
import { StartPageBannerManager } from './StartPageBannerManager/StartPageBannerManager'
import { Loader } from 'components/Loader/Loader'
import { ContentError } from './StartPageBannerManager/ContentError'
import { CreateNewBannerButton } from './StartPageBannerManager/CreateNewBannerButton'
import { BannerSelector } from './BannerSelector/BannerSelector'
import { ChannelStartBanner } from 'pages/ChannelPage/Start/ChannelStartBanner'
import { postBannerPublish } from 'api/customContent/postBannerPublish'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

import styles from './StartPageBannerList.module.scss'
import { StartBannerContent } from '../StartPageBanner/startPageBannerTypes'

enum StartPageListItemType {
  CustomContent = 'customContent',
  Subscription = 'subscription'
}

interface Props {
  channelSlug: string
}

export const StartPageBannerList: FC<Props> = ({ channelSlug }) => {
  const [removeIsLoading, setRemoveIsLoading] = useState(false)
  const [removeHasError, setRemoveHasError] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showNewContentModal, setShowNewContentModal] = useState(false)
  const [showRemoveContentModal, setShowRemoveContentModal] = useState(false)
  const [selectedBanner, setSelectedBanner] = useState<
    StartPageListItemType | undefined
  >()

  const {
    data,
    isLoading,
    isError,
    refetch
  }: {
    data: StartBannerContent | undefined
    isLoading: boolean
    isError: boolean
    refetch: () => void
  } = useQuery({
    queryKey: ['get-start-page-banner-content'],

    queryFn: () =>
      getStartPageBanner({
        channelSlug: channelSlug
      }),

    retry: false
  })

  const handleClose = (showStatusMessage: boolean) => {
    if (showStatusMessage) {
      refetch()
      setShowSuccessMessage(true)
      setSelectedBanner(undefined)
    }
    setShowNewContentModal(false)
  }

  const removeStartPageBanner = async () => {
    setRemoveIsLoading(true)
    const response = await deleteStartPageBanner(channelSlug)
    if (response.hasError) {
      setRemoveIsLoading(false)
      setRemoveHasError(true)
      return null
    }
    setRemoveIsLoading(false)
    refetch()
    setShowRemoveContentModal(false)
    setSelectedBanner(undefined)
  }

  const changeSelection = async (id: StartPageListItemType) => {
    const oldSelection = selectedBanner
    setSelectedBanner(id)
    const changeSelectionResponse = await postBannerPublish(
      channelSlug,
      id === StartPageListItemType.CustomContent
    )
    if (changeSelectionResponse.hasError) {
      setSelectedBanner(oldSelection)
    }
  }
  const channel = useSelector(channelSelector)
  const { store } = channel.data.meta_data
  const showSupporterBanner =
    store?.has_subscriptions ||
    store?.has_monthly_supporter ||
    store?.has_all_access

  const showCustomBanner =
    !!(!isError && !isLoading && data) || showNewContentModal

  useEffect(() => {
    if (
      !showCustomBanner &&
      (selectedBanner === undefined ||
        (showSupporterBanner && data && !data.published))
    ) {
      setSelectedBanner(StartPageListItemType.Subscription)
    } else {
      if (
        selectedBanner !== StartPageListItemType.CustomContent &&
        showCustomBanner &&
        data &&
        data.published
      ) {
        setSelectedBanner(StartPageListItemType.CustomContent)
      }
    }
  }, [isLoading, showSupporterBanner, showCustomBanner, data])

  return (
    <div className={styles.Container}>
      <div
        className={classNames(styles.BannerManager, {
          [styles.Selected]:
            selectedBanner === StartPageListItemType.CustomContent &&
            data !== null
        })}
      >
        {isError && <ContentError />}
        {isLoading && <Loader />}
        {showCustomBanner ? (
          <BannerSelector
            id={StartPageListItemType.CustomContent}
            isSelected={selectedBanner === StartPageListItemType.CustomContent}
            onChangeSelection={() =>
              changeSelection(StartPageListItemType.CustomContent)
            }
          >
            <StartPageBannerManager
              data={data!}
              handleClose={handleClose}
              removeIsLoading={removeIsLoading}
              removeHasError={removeHasError}
              removeStartPageBanner={removeStartPageBanner}
              showNewContentModal={showNewContentModal}
              showSuccessMessage={showSuccessMessage}
              showRemoveContentModal={showRemoveContentModal}
              setShowNewContentModal={setShowNewContentModal}
              setShowRemoveContentModal={setShowRemoveContentModal}
              setShowSuccessMessage={setShowSuccessMessage}
            />
          </BannerSelector>
        ) : (
          <CreateNewBannerButton onClick={() => setShowNewContentModal(true)} />
        )}
      </div>
      {showSupporterBanner && (
        <div>
          <div
            className={classNames(styles.BannerManager, {
              [styles.Selected]:
                selectedBanner === StartPageListItemType.Subscription
            })}
          >
            <BannerSelector
              id={StartPageListItemType.Subscription}
              isSelected={selectedBanner === StartPageListItemType.Subscription}
              onChangeSelection={() =>
                changeSelection(StartPageListItemType.Subscription)
              }
            >
              <ChannelStartBanner
                showBanner={true}
                eventPassAvailable={false}
                subscriptionsAvailable={true}
                channelName={t`this channel`}
                channelSlug={channelSlug}
                isSupporter={false}
                hasEventPass={false}
                onBannerClose={() => null}
                onBannerClick={() => null}
                noContainer
              />
            </BannerSelector>
          </div>
        </div>
      )}
    </div>
  )
}
