import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { t } from '@lingui/macro'
import { VideoPlayerData } from '../viewerPage.interfaces'
import VideoPlayer from '../../../components/video/VideoPlayer'
import { ActionPanel } from '../ActionPanel/ActionPanel'
import ShareModal from '../../../components/Modals/ShareModal'
import { PaymentModal } from '../PaymentModal/PaymentModal'
import useCurrentUser from '../../../hooks/CurrentUser'
import authenticationActions from '../../../redux-store/authentication/authentication.actions'
import styles from './VideoPlayerSection.module.scss'
import { EventSummary } from '../ActionPanel/ActionPanel.interface'
import { onHelpClick } from './onHelpClick'
import { AccessRestrictionType } from 'interfaces'

interface Props {
  mediaPlayerData: VideoPlayerData
  isLiked: boolean
  onLike: () => void
  onDownload: () => void
  onWatch: () => void
  onReportStream: () => void
}

export const VideoPlayerSection: FC<Props> = ({
  mediaPlayerData,
  isLiked,
  onLike,
  onWatch,
  onDownload,
  onReportStream
}) => {
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false)
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false)
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const broadcastChannel = mediaPlayerData.mediaObject.company.name
  const broadcastTitle = mediaPlayerData.mediaObject.title
  const {
    mediaObject: { published_at, live_start_at, is_replay, type, slug, company }
  } = mediaPlayerData
  const ActionPanelEvent: EventSummary = {
    liveStartsAt: live_start_at || 0,
    publishedAt: published_at || 0,
    isReplay: mediaPlayerData?.mediaObject?.is_replay || false,
    isLive: mediaPlayerData?.mediaObject?.is_live || false,
    title: mediaPlayerData?.mediaObject.title || 'Broadcast',
    isVideo: type === 'video',
    minEventAmount: 0,
    amountCurrency: '',
    accessRestriction: mediaPlayerData?.mediaObject?.access_restriction
  }
  const channelName = mediaPlayerData.mediaObject.company.name
  const accessRestriction = mediaPlayerData?.mediaObject?.access_restriction
  const paymentModalDescription = [
    AccessRestrictionType.SubscriptionRequired
  ].includes(accessRestriction)
    ? t`Get access to this stream and all other exclusive content from ${channelName} with a subscription.`
    : t`Get full access to this stream from ${channelName}.`

  // eslint-disable-next-line consistent-return
  const handleOnPurchase = () => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      return false
    }
    setPaymentModalIsOpen(true)
  }
  const onEdit = () => {
    const baseURL = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}/admin`
    const trailURL = `${slug}/edit?select_company=${company.subdomain}`

    if (type === 'livestream' || (type === 'video' && is_replay)) {
      window.location.href = `${baseURL}/livestreams/${trailURL}`
    } else if (type === 'video') {
      window.location.href = `${baseURL}/videos/${trailURL}`
    }
  }
  return (
    <div className={styles.VideoPlayerSectionContainer}>
      <div className={styles.VideoPlayer}>
        <VideoPlayer
          mediaObject={mediaPlayerData.mediaObject}
          streamUrls={mediaPlayerData.streamUrl!.urls}
          autoPlay={false}
        />
      </div>
      <div className={styles.ActionPanelContainer}>
        <ActionPanel
          broadcastMessage={mediaPlayerData?.mediaObject?.broadcast_message}
          isReport={false}
          isVideoPlaying
          isLiked={isLiked}
          event={ActionPanelEvent}
          onDownload={onDownload}
          onLike={onLike}
          onShare={() => setShareModalIsOpen(true)}
          onWatch={onWatch}
          onEdit={onEdit}
          onHelp={() => onHelpClick(broadcastChannel, broadcastTitle)}
          onPurchase={handleOnPurchase}
          onReportStream={onReportStream}
        />
      </div>
      {shareModalIsOpen && (
        <ShareModal
          defaultOpen={shareModalIsOpen}
          mediaId={mediaPlayerData?.mediaObject?.id}
          mediaTitle={
            mediaPlayerData?.mediaObject?.game?.title ||
            mediaPlayerData?.mediaObject?.title
          }
          companySlug={
            mediaPlayerData?.mediaObject?.game?.display_company?.subdomain
          }
          onClose={() => setShareModalIsOpen(false)}
        />
      )}
      {paymentModalIsOpen && mediaPlayerData?.streamUrl?.paymentMethods && (
        <PaymentModal
          mediaObjectId={mediaPlayerData?.mediaObject?.id}
          paymentOptions={mediaPlayerData?.streamUrl?.paymentMethods}
          defaultCurrency="SEK"
          channelSlug={mediaPlayerData.mediaObject.company.subdomain}
          title={mediaPlayerData.mediaObject.title}
          description={paymentModalDescription}
          onClose={() => setPaymentModalIsOpen(false)}
        />
      )}
    </div>
  )
}
