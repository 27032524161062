import React, { FC } from 'react'
import styles from './ScoreboardDetails.module.scss'
import { useSelector } from 'react-redux'
import { isSwedishHandballSelector } from 'redux-store/channel/channel.selectors'
import { RefereeSection } from './RefereeSection'

interface Props {
  location?: string
}

export const ScoreboardDetails: FC<Props> = ({ location }) => {
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  const showGameLineups = isSwedishHandball
  if (!showGameLineups && !location) return null
  return (
    <div className={styles.DetailsContainer}>
      {location && (
        <div
          data-testid="game-location"
          className={styles.ScoreboardDetailsContainer}
        >
          <div className={styles.DetailsItem}>{location}</div>
        </div>
      )}
      {showGameLineups && <RefereeSection />}
    </div>
  )
}
