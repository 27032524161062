import React, { FC } from 'react'
import { Trans } from '@lingui/macro'

import { PurchaseContainer } from './PurchaseContainer/PurchaseContainer'
import { getSubscriptionsAndAllAccess } from 'api/channel/purchases/getSubscriptionsAndAllAccess'
import styles from './PurchaseContainer/purchaseContainer.module.scss'

interface Props {
  channelSlug: string
}

export const Subscriptions: FC<Props> = ({ channelSlug }) => (
  <PurchaseContainer
    channelSlug={channelSlug}
    apiCall={getSubscriptionsAndAllAccess}
  >
    <div className={styles.PurchaseHeading}>
      <h3>
        <Trans>Choose your subscription</Trans>
      </h3>
      <div className={styles.PurchaseTagLine}>
        <Trans>
          Get the most out of our channel by choosing one of the subscriptions!
        </Trans>
      </div>
    </div>
  </PurchaseContainer>
)
