import React, { FC, useEffect } from 'react'
import styles from './UserAccountPage.module.scss'
import { SidebarNavigation } from 'components/SidebarNavigation/SidebarNavigation'
import { UserAccountRoutes } from './UserAccountRoutes'

import { DefaultFooter } from 'components/page/footer/defaultFooter/DefaultFooter'
import { locales } from 'translations/i18n'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { UserAccountMenuLinks } from './UserAccountMenuLinks'
import useCurrentUser from 'hooks/CurrentUser'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useScrollToTop } from 'helpers/useScrollToTop'

export const UserAccountPage: FC = () => {
  const companyName = 'Solidsport AB'
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const currentUser = useCurrentUser()
  const history = useHistory()
  useScrollToTop()
  useEffect(() => {
    if (!currentUser) {
      history.push('/calendar')
    }
  }, [currentUser])

  return currentUser ? (
    <div className={styles.PageContainer}>
      <Helmet>
        <title>Solidsport Account</title>
      </Helmet>
      {!isMobile && (
        <div className={styles.Sidebar}>
          <SidebarNavigation
            menuLinks={UserAccountMenuLinks}
            showSignOutButton={true}
            onSignOutClick={() => null}
          />
        </div>
      )}
      <div className={styles.Content}>
        <UserAccountRoutes />
        <DefaultFooter companyName={companyName} languages={locales} />
      </div>
    </div>
  ) : null
}
