import React, { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ContentContainer from '../../../components/ContentContainer'
import { Start } from '../Start/Start'
import Clubs from '../Clubs'
import Districts from '../Districts'

import Teams from '../Teams'
import ChannelVideos from '../Videos/ChannelVideos'
import Leagues from '../Leagues'
import Events from '../Events'
import { ChannelTypes } from '../channelType'
import { BuyAccess } from '../Purchase/BuyAccess'
import { Subscriptions } from '../Purchase/Subscriptions'
import { EventGroups } from '../EventGroups/EventGroups'

import { ChannelGamesRoute } from './ChannelGamesRoute'
import { News } from '../News/News'
import { channelCustomPageSelector } from 'redux-store/channel/channel.selectors'
import { useSelector } from 'react-redux'
import { CustomPageRoutes } from './CustomPageRoutes'
import { PlayerStatistics } from 'features/statistics/PlayerStatistics/PlayerStatistics'

interface Props {
  channelSlug: string
  channelType: ChannelTypes
  isScheduleAdmin: boolean
  isAdmin: boolean
  isViewingAsUser: boolean
  isRootPage: boolean
  channel: any
  onChangeTab: (destination: string) => void
}

export const ChannelPageRoutes: FC<Props> = ({
  channelSlug,
  channelType,
  isScheduleAdmin,
  isAdmin,
  isViewingAsUser,
  isRootPage,
  channel,
  onChangeTab
}) => {
  const showMultistageTournaments = [
    ChannelTypes.event,
    ChannelTypes.league
  ].includes(channelType)

  const customPages = useSelector(channelCustomPageSelector)
  const RedirectToAccessPage = [
    ChannelTypes.association,
    ChannelTypes.district,
    ChannelTypes.league,
    ChannelTypes.event
  ].includes(channelType)
  const hasRecentArticles = channel.data.meta_data.content.has_recent_articles
  return (
    <Switch>
      <Route exact path="/:company/pass">
        {RedirectToAccessPage ? (
          <Redirect to={`/${channelSlug}/access`} />
        ) : (
          <Redirect to={`/${channelSlug}/subscriptions`} />
        )}
      </Route>
      <Route exact path="/:company/access">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding={false}
          hasTopBottomPadding={false}
        >
          {channelSlug && channelSlug !== '' && (
            <BuyAccess channelSlug={channelSlug} />
          )}
        </ContentContainer>
      </Route>
      <Route exact path="/:company/subscriptions">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding={false}
          hasTopBottomPadding={false}
        >
          {channelSlug && channelSlug !== '' && (
            <Subscriptions channelSlug={channelSlug} />
          )}
        </ContentContainer>
      </Route>
      <Route exact path="/:company/clubs">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding={false}
        >
          {channelSlug && channelSlug !== '' && (
            <Clubs
              channelSlug={channelSlug}
              isAdmin={isAdmin}
              channelType={channel?.data?.type}
            />
          )}
        </ContentContainer>
      </Route>
      <Route exact path="/:company/teams">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding={false}
        >
          {channelSlug && channelSlug !== '' && (
            <Teams channelSlug={channelSlug} channelType={channelType} />
          )}
        </ContentContainer>
      </Route>

      <Route exact path="/:company/districts">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding={false}
        >
          {channelSlug && channelSlug !== '' && (
            <Districts channelSlug={channelSlug} isAdmin={isAdmin} />
          )}
        </ContentContainer>
      </Route>

      <Route exact path="/:company/leagues">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding={false}
        >
          {channelSlug && channelSlug !== '' && (
            <Leagues channelSlug={channelSlug} />
          )}
        </ContentContainer>
      </Route>
      <Route exact path="/:company/groups">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding={false}
        >
          {channelSlug && channelSlug !== '' && (
            <EventGroups channelSlug={channelSlug} />
          )}
        </ContentContainer>
      </Route>
      <Route path="/:company/games">
        <ChannelGamesRoute
          showMultistageTournaments={showMultistageTournaments}
          isScheduleAdmin={isScheduleAdmin}
          channelSlug={channelSlug}
          channelType={channelType}
        />
      </Route>

      <Route exact path="/:company/videos">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding
        >
          {channelSlug && channelSlug !== '' && (
            <ChannelVideos channelSlug={channelSlug} />
          )}
        </ContentContainer>
      </Route>

      <Route exact path="/:company/events">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding
        >
          {channelSlug && channelSlug !== '' && (
            <Events channelSlug={channelSlug} channelType={channelType} />
          )}
        </ContentContainer>
      </Route>

      <Route exact path="/:company/news">
        <ContentContainer
          hasLightBackground
          hasLeftRightPadding
          hasTopBottomPadding
        >
          {channelSlug && channelSlug !== '' && (
            <News channelSlug={channelSlug} />
          )}
        </ContentContainer>
      </Route>
      <Route exact path="/:company/stats">
        <ContentContainer
          hasLightBackground={true}
          hasLeftRightPadding={false}
          hasTopBottomPadding={true}
        >
          <PlayerStatistics />
        </ContentContainer>
      </Route>
      <Route exact path="/:company/stats/:eventslug">
        <ContentContainer
          hasLightBackground={true}
          hasLeftRightPadding={false}
          hasTopBottomPadding={true}
        >
          <PlayerStatistics />
        </ContentContainer>
      </Route>
      <Route exact path="/:company">
        {channelSlug && channelSlug !== '' && (
          <Start
            channelSlug={channelSlug}
            isRootPage={isRootPage}
            channelType={channelType}
            isViewingAsUser={isViewingAsUser}
            onChangeTab={onChangeTab}
            hasRecentArticles={hasRecentArticles}
          />
        )}
      </Route>
      <CustomPageRoutes customPages={customPages} channelSlug={channelSlug} />
    </Switch>
  )
}
