/* eslint-disable @typescript-eslint/indent */
import { Honeybadger } from '@honeybadger-io/react'
import { queryString } from '../helpers/generalHelpers'
import { saveStateToStorage } from '../helpers/localStorageHelper'
import process from 'process'
import decodeJWT from '../helpers/decodeJWT'
import { User } from '../interfaces'
import { FeatureFlagsAvailable } from '../featureFlags/FeatureFlagsAvailable'

export type ApiEndpointOptions = {
  path: String
  method?: String
  params?: Object
  headers?: Object
  file?: any
  credentials?: string
  authenticatable?: boolean
}

export type ApiStatusResponse = {
  status: number
}

export type ApiDataResponse = ApiStatusResponse & {
  status: number
  data: any
  totalCount: number
  title: string
}

export type ApiResponse = Partial<ApiDataResponse> & ApiStatusResponse

export type ApiErrorResponse = {
  message: string
  description: string
  status: number
  code: number // TODO: Not currently used, but should be eventually when backend is ready for it.
}

export type ApiError = Partial<ErrorEvent & ApiErrorResponse> &
  (ErrorEvent | ApiErrorResponse)

const getAuthorizationForHeaders = (headers: any) => {
  const newHeaders: any = { ...headers }
  const appToken = localStorage.getItem('appToken')
  if (appToken) {
    newHeaders.Authorization = appToken.replace(/"/g, '')
  }
  return newHeaders
}

const DEFAULT_OPTIONS = () => {
  return {
    method: 'GET',
    path: '/',
    params: {},
    file: null,
    headers: getAuthorizationForHeaders({})
  }
}

// Log out the user
const endUserSession = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('appToken')
  localStorage.removeItem('tokenDate')
  if (localStorage.getItem(FeatureFlagsAvailable.PoweredBy) !== 'true') {
    window.location.reload()
  }
}

const handleFetchResponse = (response: any) => {
  return new Promise<ApiResponse>((resolve, reject) => {
    response
      .json()
      .catch((e: any) => {
        if (response.ok) {
          const formatedResponse: ApiStatusResponse = {
            status: response.status
          }
          resolve(formatedResponse)
        } else {
          const error: ApiError = {
            message: e.message,
            description: e.message,
            status: response.status,
            code: 0,
            error: e
          }
          reject(error)
        }
      })
      .then((data: any) => {
        if (response.ok) {
          const successResponse: ApiResponse = {
            status: response.status,
            data: data,
            totalCount: response.headers.get('x-total-count'),
            title: response.headers.get('x-title')
          }
          resolve(successResponse)
        } else {
          let errorResponse: ApiErrorResponse
          if (data) {
            errorResponse = {
              message: data.message,
              description: data.description,
              status: response.status,
              code: data.code
            }
          } else {
            errorResponse = {
              message: '',
              description: '',
              status: response.status,
              code: 0
            }
            Honeybadger.notify(response, {
              name: 'ApiEndpointError',
              message: 'Response data error'
            })
          }
          reject(errorResponse)
        }
      })
  })
}

const SERVER_URL = process.env.REACT_APP_API_ENDPOINT
const PROTOCOLL = process.env.REACT_APP_PROTOCOLL
const ApiEndpoint = {
  CREDENTIALS: {
    OMIT: 'omit',
    SAME_ORIGIN: 'same-origin',
    INCLUDE: 'include'
  },
  METHODS: {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH'
  },
  call: async (opts: ApiEndpointOptions): Promise<ApiResponse> => {
    return new Promise<ApiResponse>((resolve, reject) => {
      const apiCall = (retrying?: boolean) => {
        const default_options = DEFAULT_OPTIONS()
        const { path, method, file, credentials } = {
          ...default_options,
          ...opts
        }
        const headers = { ...default_options.headers, ...opts.headers }
        const params = { ...default_options.params, ...opts.params }

        let url = `${PROTOCOLL}${SERVER_URL}`
        if (opts.authenticatable && localStorage.getItem('appToken')) {
          url += `session/${path}`
        } else {
          url += path
        }

        let options: any = {
          method: method,
          headers: {
            Accept: 'application/json',
            'Content-Language': sessionStorage.getItem('language'),
            ...headers
          }
        }

        if (credentials) {
          options.credentials = credentials
        }

        if (!file)
          options.headers['Content-Type'] = 'application/json; charset=UTF-8'

        if (Object.keys(params).length !== 0) {
          if (
            method === ApiEndpoint.METHODS.GET ||
            method === ApiEndpoint.METHODS.PUT
          ) {
            url = `${url}?${queryString(params)}`
          } else {
            if (file) {
              Object.keys(params).map((key) =>
                file.append(key, (params as any)[key])
              )
            }
            options = {
              ...options,
              body: file || JSON.stringify(params)
            }
          }
        } else if (file) {
          options = {
            ...options,
            body: file
          }
        }
        fetch(url, options)
          .then((response) => {
            if (response.status === 401) {
              if (retrying) {
                endUserSession()
              } else {
                validation(() => apiCall(true), endUserSession)
              }
            } else {
              handleFetchResponse(response).then(resolve).catch(reject)
            }
          })
          .catch((error: ApiError) => {
            reject(error)
          })
      }

      const validation = (success: () => void, failure: () => void) => {
        if (localStorage.getItem('appToken')) {
          validateToken()
            .then((tokenIsValid) => {
              if (!tokenIsValid) {
                endUserSession()
                // Let the callee handle the error
                const authorizationError: ApiError = {
                  message: 'Token expired',
                  description: 'Token expired',
                  status: 401,
                  code: 0,
                  error: null
                }
                reject(authorizationError)
                return
              }
              success()
            })
            .catch((error: ApiError) => {
              reject(error)
            })
        } else {
          failure()
        }
      }

      validation(apiCall, apiCall)
    })
  }
}

// Token Logic
// TODO: Move to a different file
let SETTING_NEW_TOKEN = false
enum GetTokenStatus {
  Proccesing = 1,
  Success,
  Failure
}

type SessionData = {
  user: User
  token: string
}

type GetTokenResponse = {
  status: GetTokenStatus
  data?: SessionData
}

export const getSession = async (): Promise<ApiResponse> => {
  return new Promise<ApiResponse>((resolve, reject) => {
    getToken().then((tokenResponse) => {
      if (tokenResponse.status === GetTokenStatus.Success) {
        resolve(tokenResponse as ApiResponse)
      } else {
        const error: ApiError = {
          message: 'Could not get login token.',
          description: 'Could not get login token.',
          status: 400,
          code: 0,
          error: null
        }
        reject(error)
      }
    })
  })
}

const getToken = async (): Promise<GetTokenResponse> => {
  return new Promise((resolve) => {
    if (SETTING_NEW_TOKEN) {
      resolve({ status: GetTokenStatus.Proccesing })
      return
    }

    SETTING_NEW_TOKEN = true

    const options: any = {
      credentials: ApiEndpoint.CREDENTIALS.INCLUDE,
      headers: getAuthorizationForHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      })
    }

    fetch(`${PROTOCOLL}${SERVER_URL}session/renew`, options)
      .then((response) => {
        if (response.status >= 400) {
          resolve({ status: GetTokenStatus.Failure })
          throw Error
        }
        return response.json()
      })
      // eslint-disable-next-line
      .then((data) => {
        if (data.token === undefined) return false
        resolve({ status: GetTokenStatus.Success, data: data })
      })
      .catch((error: ApiError) => {
        if (error.type) {
          Honeybadger.notify(error.type, {
            name: 'ApiEndpointError - getToken',
            message: 'Error getting token'
          })
        }
        resolve({ status: GetTokenStatus.Failure })
      })
      .finally(() => {
        SETTING_NEW_TOKEN = false
      })
  })
}

export const validateToken = async () => {
  return new Promise<boolean>((resolve) => {
    ;(function call() {
      const handleTokenResponse = (tokenResponse: GetTokenResponse) => {
        switch (tokenResponse.status) {
          case GetTokenStatus.Proccesing:
            setTimeout(call, 1)
            break
          case GetTokenStatus.Success:
            if (tokenResponse.data) {
              saveStateToStorage('user', tokenResponse.data.user)
              saveStateToStorage('appToken', tokenResponse.data.token)
              saveStateToStorage('tokenDate', Date.now())
              resolve(true)
            } else {
              resolve(false)
            }
            break
          case GetTokenStatus.Failure:
          default:
            resolve(false)
        }
      }
      // TODO: Get expiry from backend
      let jwtExpiry
      try {
        if (window.location.origin.includes(':3000')) {
          const td = Number(localStorage.getItem('tokenDate'))
          if (td) {
            jwtExpiry = td + 10 * 1000
          } else {
            jwtExpiry = Date.now()
          }
        } else {
          jwtExpiry = decodeJWT(localStorage.getItem('appToken')).exp * 1000
        }
      } catch (e) {
        getToken().then((tokenResponse: GetTokenResponse) =>
          handleTokenResponse(tokenResponse)
        )
        return
      }
      const currentTime = Date.now()
      const tokenTimeString = localStorage?.getItem('tokenDate')

      if (!tokenTimeString) {
        getToken().then((tokenResponse: GetTokenResponse) =>
          handleTokenResponse(tokenResponse)
        )
        return
      }

      if (currentTime >= jwtExpiry) {
        getToken().then((tokenResponse: GetTokenResponse) =>
          handleTokenResponse(tokenResponse)
        )
        return
      }
      resolve(true)
    })()
  })
}

export default ApiEndpoint
