import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import styles from './MessageInput.module.scss'
import ChatSubmitButton from './ChatSubmitButton/ChatSubmitButton'
import { SpammingStatus } from '../InputContainer/InputContainer'

interface Props {
  userIsBlocked: boolean
  onSuccess: (messageText: string, file: string) => void
  spammingStatus?: number
}
interface MessageValues {
  messageText: string
  fileName: string
}
const MessageInput: FC<Props> = ({
  userIsBlocked,
  onSuccess,
  spammingStatus = SpammingStatus.None
}) => {
  const initialValues: MessageValues = {
    messageText: '',
    fileName: ''
  }
  const messageTextSchema = Yup.object().shape({
    messageText: Yup.string()
      .trim()
      .max(240, t`You have reached the limit of 240 characters.`)
  })
  const handleSubmit = async (
    values: MessageValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    onSuccess(values.messageText, values.fileName)
    formikHelpers.setSubmitting(false)
    formikHelpers.resetForm()
  }

  const placeholderText = () => {
    if (userIsBlocked) {
      return t`The channel admin has blocked you from this chat`
    }
    if (spammingStatus === SpammingStatus.SameMessage) {
      return t`Please write something new`
    }
    if (spammingStatus === SpammingStatus.TooManyMessages) {
      return t`Please wait a few seconds`
    }
    return t`Write something`
  }
  return (
    <div className={styles.InputContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={messageTextSchema}
        validateOnChange
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className={styles.FormContainer}>
            <div className={styles.TextFieldContainer}>
              <Field
                name="messageText"
                placeholder={placeholderText()}
                className={styles.TextField}
                disabled={userIsBlocked}
              />
              {errors.messageText && touched.messageText ? (
                <div className={styles.ErrorText}>{errors.messageText}</div>
              ) : null}
            </div>
            <div className={styles.ButtonContainer}>
              {/*
              This is hidden until we add the functionality for uploading file
              <UploadButton
                id="fileName"
                onChange={(event) => {
                  setFieldValue('fileName', event.target.value)
                }}
              /> */}
              {!userIsBlocked && <ChatSubmitButton label={t`send`} />}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default MessageInput
