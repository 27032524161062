import { mapBroadcastThumbnailResponse } from './mapBroadcastThumnbailFromResponse'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

import { PromotedCategory } from '../../pages/ChannelPage/Start/PromotedCategories/promotedCateoryTypes'

export const getTop3PromotedCategoriesForChannel = async (
  channelSlug: string
) => {
  try {
    const result = await getPromotedCategoriesForChannel({
      slug: channelSlug
    })
    const categories: PromotedCategory[] = result.data.map((cat: any) => ({
      category: cat.category,
      thumbnails: mapBroadcastThumbnailResponse(
        // @ts-ignore
        cat.timeline_objects
      )
    }))
    return categories.slice(0, 3)
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const getPromotedCategoriesForChannel = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page?: number
  perPageCount?: number
}) => {
  try {
    const params = {
      company: slug
    }
    const pageParams =
      page && perPageCount ? { ...params, page, perPageCount } : params

    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/promoted_categories',
      params: pageParams
    })

    const responseData = await resultResponse
    return { data: responseData.data, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
