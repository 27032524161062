import React, { FC } from 'react'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useCountdown } from '../../../../hooks/Countdown'
import { ViewType } from '../../../../redux-store/stream/stream.interface'
import styles from './ActionLabel.module.scss'
import { getActionLabelText } from './getActionLabelText'
import { EventSummary } from '../ActionPanel.interface'
import { useCountdownTime } from 'components/live/Countdown/useCountdownTime'

interface ActionLabelTextProps {
  event: EventSummary
  viewType: ViewType
  isVideoPlaying: boolean
}

export const ActionLabelText: FC<ActionLabelTextProps> = ({
  event,
  viewType,
  isVideoPlaying
}) => {
  // Subscribes to changes made to countdown time from the backend
  const countdownTime = useCountdownTime(event.liveStartsAt)

  // Calculates the current remaining time
  const { remainingTime } = useCountdown(countdownTime)

  const isEventToday = dayjs.unix(event.liveStartsAt).isToday()
  const isCountingDown =
    viewType === ViewType.UPCOMING && isEventToday && remainingTime

  const text = isCountingDown
    ? `${t`Broadcast starts in`} ${remainingTime}`
    : getActionLabelText(event, viewType, isVideoPlaying)

  return (
    <div
      data-testid="action-label-text"
      className={classNames(
        styles.ActionLabelText,
        {
          [styles.ActionLabelTextIsLive]:
            viewType === ViewType.LIVE && !isVideoPlaying
        },
        {
          [styles.ActionLabelTextIsPlaying]: isVideoPlaying
        }
      )}
    >
      {text}
    </div>
  )
}
