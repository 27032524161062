import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { t } from '@lingui/macro'
import SpacedList from '../SpacedList'
import { Event } from '../cardTypes'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'

import styles from './event.module.scss'
import SolidsportLogo from '../../../page/header/navigationHeader/hamburgerMenuContent/topContainer/SolidsportLogo'

interface Props {
  content: Event
}

const SearchResultCardEvent: FC<Props> = ({ content }) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint > Breakpoint.mobile

  return (
    <div className={styles.Container}>
      <div
        data-testid="search-result-image-container"
        className={styles.ImageChannelContainer}
      >
        {content.imageUrl ? (
          <img
            className={styles.Image}
            src={content.imageUrl}
            alt={content.title}
          />
        ) : (
          <div className={styles.Image}>
            <SolidsportLogo />
          </div>
        )}
      </div>
      <div className={styles.ContentContainer}>
        <div className={styles.EventTitle}>
          <Link to={content.targetUrl}>{content.title}</Link>
        </div>
        <div className={styles.EventTeams}>
          <span>{t`Cuplag`}: </span>
          <span className={styles.EventTeamsText}>
            {content.teams.join(', ')}
          </span>
        </div>
        <div className={styles.BottomFrame}>
          {isMobile ? (
            <SpacedList
              list={[
                content.sport,
                `${t`Games`} (${content.games})`,
                `${t`Followers`} (${content.followers})`,
                `${t`Monthly supporters`} (${content.supporters})`
              ]}
            />
          ) : (
            <SpacedList list={[content.sport]} />
          )}

          {content.comingGameUrl && !isMobile && (
            <Link to={content.comingGameUrl}>
              <span className={styles.ComingGame}>{t`Coming game`}</span>
            </Link>
          )}
          {content.liveGameUrl && (
            <Link to={content.liveGameUrl}>
              <span className={styles.IsLive}>{t`Live right now`}</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchResultCardEvent
