import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Link, useParams } from 'react-router-dom'

import { IconButton } from '@mui/material'
import SettingsIcon from './SettingsIcon'
import HeaderLink, { MenuLink } from '../../menuLink/MenuLink'
import CountryLanguage from './countryLanguage/CountryLanguage'
import GlobalHeaderLogo from './GlobalHeaderLogo'
import { User } from '../../../../interfaces'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'
import { languageSelector } from '../../../../redux-store/language/language.selectors'
import { countrySelector } from '../../../../redux-store/country/country.selectors'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'

import styles from './TopHeader.module.scss'
import { useChannelTheme } from '../../../../pages/ChannelPage/Theme/useChannelTheme'

interface Props {
  links: MenuLink[]
  currentUser?: User
  showLanguage: boolean
}
export const TopHeader: FC<Props> = ({ links, currentUser, showLanguage }) => {
  const { company } = useParams<{ company: string | undefined }>()

  const country = useSelector(countrySelector)
  const language = useSelector(languageSelector)
  const currentBreakpoint = useCurrentBreakpoint()
  const showLanguageSelector = showLanguage && currentBreakpoint > Breakpoint.sm

  const showLoggedInAs = currentBreakpoint <= Breakpoint.sm && currentUser
  const channel = useSelector(channelSelector)
  const [isRootPage, setIsRootPage] = useState(false)
  const { channelThemeIsLoading } = useChannelTheme()

  useEffect(() => {
    if (channel.data && !channelThemeIsLoading) {
      setIsRootPage(
        company &&
          channel &&
          channel.data &&
          company === channel.data.subdomain &&
          channel.data.isRootChannel
      )
    }
  }, [channel, company, channelThemeIsLoading])

  const hideForFirstRelease = true

  const getTopHeaderNavLink = (menuName: string) =>
    `top-header-nav-link-${menuName.toLowerCase()}`

  return isRootPage ? null : (
    <div className={styles.Container} data-testid="top-header-container">
      <Link to="/" className={styles.LogoContainer}>
        <GlobalHeaderLogo data-testid="top-header-logo" />
      </Link>
      <div className={styles.RightSide} data-testid="top-header-menu">
        {links.map((link) => (
          <div
            key={link.translationText}
            className={styles.MenuItemContainer}
            data-testid={getTopHeaderNavLink(link.translationText)}
          >
            <div className={styles.MenuItem}>
              <HeaderLink {...link} />
            </div>
          </div>
        ))}

        {showLanguageSelector && (
          <div className={styles.CountryLanguageSelector}>
            <div className={styles.CountryLanguageContainer}>
              <CountryLanguage country={country} language={language} />
            </div>
            {!hideForFirstRelease && currentBreakpoint > Breakpoint.sm && (
              <div className={styles.SettingsButtonContainer}>
                <IconButton
                  className={styles.SettingsButton}
                  onClick={() => console.log('settings clicked')}
                >
                  <SettingsIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}
        {showLoggedInAs && (
          <div className={styles.LoggedInStatus}>
            <Trans>Logged in as:</Trans> {currentUser?.firstname}
          </div>
        )}
      </div>
    </div>
  )
}
