import * as yup from 'yup'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'
export const generalStreamInfoValidationSchema = () =>
  yup.object({
    title: yup.string().required(t`The title of the stream is required`),
    description: yup.string(),
    startTime: yup
      .date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value
        }

        const result = dayjs(originalValue, 'dd-MM-yyyy HH:mm')
        return result
      })
      .typeError(t`please enter a valid date, in the format (dd-MM-yyyy HH:mm)`)
      .required(t`Start time is required`),
    endTime: yup
      .date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value
        }
        const result = dayjs(originalValue, 'dd-MM-yyyy HH:mm')
        return result
      })
      .typeError(t`please enter a valid date, in the format (dd-MM-yyyy HH:mm)`)
      .required(t`End time is required`)
      .min(yup.ref('startTime'), t`End time must be after start time`)
  })
