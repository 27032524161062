import SwedenFlag from './header/topHeader/flags/se'
import NorwayFlag from './header/topHeader/flags/no'
import DenmarkFlag from './header/topHeader/flags/dk'
import FinlandFlag from './header/topHeader/flags/fi'
import IcelandFlag from './header/topHeader/flags/is'
import GermanyFlag from './header/topHeader/flags/de'
import AustriaFlag from './header/topHeader/flags/at'
import GlobalFlag from './header/topHeader/flags/global'

export interface Country {
  shortCode: CountryCode
  flag: () => JSX.Element
}

export type CountryCode = 'SE' | 'NO' | 'DK' | 'FI' | 'IS' | 'DE' | 'AT' | 'XX'

export const countryFlags: Country[] = [
  {
    shortCode: 'SE',
    flag: SwedenFlag
  },
  {
    shortCode: 'NO',
    flag: NorwayFlag
  },
  {
    shortCode: 'DK',
    flag: DenmarkFlag
  },
  {
    shortCode: 'FI',
    flag: FinlandFlag
  },
  {
    shortCode: 'IS',
    flag: IcelandFlag
  },
  {
    shortCode: 'DE',
    flag: GermanyFlag
  },
  {
    shortCode: 'AT',
    flag: AustriaFlag
  },
  {
    shortCode: 'XX',
    flag: GlobalFlag
  }
]
