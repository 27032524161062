/* eslint-disable @typescript-eslint/indent */
import { MediaObject } from '../../interfaces'

const emptyShirtColor = [
  {
    class: '',
    style: ''
  }
]
const emptyTeam = {
  name: '',
  badge_url: '',
  slug: '',
  id: 0,
  country_code: '',
  shirt_colors: emptyShirtColor
}
export const mapThumbnailObject = (mediaObject: MediaObject) => {
  const data = {
    id: mediaObject.ident,
    channelName: mediaObject.company.name && mediaObject.company.name,
    channelImg: '',
    channelUrl: mediaObject.company.subdomain && mediaObject.company.subdomain,
    targetUrl: 'TimelineObjectPath(mediaObject)',
    country: 'SE',
    type: mediaObject.game ? 'game' : mediaObject.type,
    duration: mediaObject.duration,
    startTime: mediaObject.live_start_at,
    endTime: mediaObject.live_end_at,
    live: mediaObject.is_live_and_started,
    publishedAt: mediaObject.published_at,
    highlightFromDate: mediaObject.highlight_source
      ? mediaObject.published_at
      : null,
    hasHighlight: Boolean(mediaObject.highlight_slug),
    isReplay: mediaObject.is_replay,
    viewers: mediaObject.active_view_count,
    showViewers: false,
    views: mediaObject.view_count,
    showViews: false,
    title: mediaObject.title,
    thumbnailImageUrl: mediaObject.thumbnail,
    typeOfGame:
      (mediaObject.categories &&
        mediaObject.categories.length > 0 &&
        mediaObject.categories[0]?.name) ||
      '',
    homeTeam:
      mediaObject.game && mediaObject?.game?.home_team
        ? {
            name: mediaObject?.game?.home_team.name,
            badge_url: mediaObject?.game.home_team?.badge_url,
            slug: mediaObject?.game.home_team?.slug,
            id: mediaObject?.game.home_team?.id,
            country_code: 'se',
            shirt_colors: emptyShirtColor
          }
        : emptyTeam,
    awayTeam:
      mediaObject.game && mediaObject.game?.away_team
        ? {
            name: mediaObject.game?.away_team?.name,
            badge_url: mediaObject.game?.away_team?.badge_url,
            slug: mediaObject.game?.away_team?.slug,
            id: mediaObject.game?.away_team?.id,
            country_code: 'se',
            shirt_colors: emptyShirtColor
          }
        : emptyTeam
  }
  return data
}
