import { TimelineObjectPath } from '../../helpers/PathHelper'
import { TimelineObject } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getLatestVideoContent = async () => {
  // sportstage.se/api/play_v1/timeline_objects/collections/videos?page=1&per_page=12
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: '/timeline_objects/collections/videos',
      params: { page: 1, per_page: 12 }
    })
    const responseData = await resultResponse
    const mapped = mapBroadcastThumbnailResponse(responseData.data)
    return mapped
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapBroadcastThumbnailResponse = (apiResponse: any) =>
  apiResponse.map((latestVideo: TimelineObject) => ({
    id: latestVideo.ident,
    channelName:
      latestVideo.display_company && latestVideo.display_company.name,
    channelImg: '',
    channelUrl:
      latestVideo.display_company && latestVideo.display_company.subdomain,
    targetUrl: TimelineObjectPath(latestVideo),
    country: latestVideo.country,
    type: latestVideo.type,
    startTime: latestVideo.published_at,
    endTime: latestVideo.live_end_at,
    live: latestVideo.is_live,
    highlightFromDate: latestVideo.highlight_from_date,
    hasHighlight: latestVideo.has_highlight,
    isReplay: latestVideo.is_replay,
    viewers: latestVideo.number_of_viewers || 0,
    showViewers: true,
    views: latestVideo.number_of_views || 0,
    showViews: false,
    title: latestVideo.title,
    thumbnailImageUrl: latestVideo.thumbnail,
    duration: latestVideo.duration
  }))
