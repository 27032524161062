import React, { FC } from 'react'
import classNames from 'classnames'

import styles from './TopSpacer.module.scss'

interface Props {
  isRootOrChild: boolean
  isRootClubChannel: boolean
}

export const TopSpacer: FC<Props> = ({ isRootClubChannel, isRootOrChild }) => (
  <div
    className={classNames({
      [styles.TopPadding]: !isRootOrChild,
      [styles.TopPaddingWithoutTopMenu]: isRootOrChild,
      [styles.TopPaddingThemedMobile]: isRootOrChild,
      [styles.TopPaddingThemedMobileWithoutMenuLinks]: isRootClubChannel
    })}
  />
)
