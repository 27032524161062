import React, { FC, useState } from 'react'
import { SearchIcon } from './SearchIcon'
import styles from './MobileSearch.module.scss'
import { MobileSearchField } from './MobileSearchField/MobileSearchField'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { ColorType } from 'components/Searchfield/SearchField'

interface Props {
  onSearch: (searchInput: string) => void
  backgroundIsTransparentAtStart: boolean
  hasBackground: boolean
  searchFieldColor: ColorType
}

export const MobileSearch: FC<Props> = ({
  onSearch,
  backgroundIsTransparentAtStart,
  hasBackground,
  searchFieldColor
}) => {
  const [showSearchField, setShowSearchField] = useState(false)
  const [searchFieldIsFocused, setSearchFieldIsFocused] = useState(false)
  const onSearchIconClick = () => {
    setShowSearchField(!showSearchField)
    setSearchFieldIsFocused(true)
  }
  const handleOnSearch = (searchInput: string) => {
    onSearch(searchInput)
    setShowSearchField(false)
  }
  const channel = useSelector(channelSelector)
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel

  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const isRootClubChannel = channel?.data?.isRootClubChannel
  return (
    <>
      <div
        role="button"
        onClick={onSearchIconClick}
        className={classNames(styles.SearchIcon, {
          [styles.SearchIconLight]:
            !hasBackground && backgroundIsTransparentAtStart,
          [styles.SearchIconDark]: hasBackground
        })}
      >
        <SearchIcon />
      </div>
      {showSearchField && (
        <div
          className={classNames(styles.SearchFieldContainer, {
            [styles.IsRootOrChildChannel]: isRootOrChildChannel,
            [styles.IsRootClubChannel]: isRootClubChannel
          })}
        >
          <MobileSearchField
            onSearch={handleOnSearch}
            searchFieldIsFocused={searchFieldIsFocused}
            searchFieldColor={searchFieldColor}
            onBackArrowClick={() => setShowSearchField(false)}
          />
        </div>
      )}
    </>
  )
}
