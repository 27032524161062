import { AccessRestrictionType } from '../../../interfaces'
import { VideoPlayerData } from '../viewerPage.interfaces'

export const getPriceDependingOnAccessRestriciton = (
  accessRestriction: AccessRestrictionType,
  mediaPlayerData: VideoPlayerData
) => {
  const ppvPriceFromGame = mediaPlayerData?.mediaObject?.game?.price
  const ppvPriceFromStream =
    mediaPlayerData?.streamUrl?.paymentMethods?.ppv?.price
  const subscriptionPriceFromStream =
    mediaPlayerData?.streamUrl?.paymentMethods?.subscriptions[0]?.price
  const priceIsUndefined = { undefined }
  const noPrice = { SEK: 0 }

  const deafultPrice =
    ppvPriceFromGame ||
    ppvPriceFromStream ||
    subscriptionPriceFromStream ||
    priceIsUndefined

  const subscriptionRequiredPrice =
    subscriptionPriceFromStream || priceIsUndefined

  switch (accessRestriction) {
    case AccessRestrictionType.SubscriptionRequired:
      return subscriptionRequiredPrice
    case AccessRestrictionType.LoginRequired:
    case AccessRestrictionType.None:
      return noPrice
    case AccessRestrictionType.PaymentRequired:
    default:
      return deafultPrice
  }
}
