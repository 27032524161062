/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { PaymentPropsType } from '../../viewerPage.interfaces'
import BuyOptionBannerFactory, {
  BUY_OPTIONS
} from '../BuyOptionBanner/BuyOptionBannerFactory'
import PAYMENTS from '../../../../redux-store/payments/payments.constants'
import styles from './BuyBannerView.module.scss'
import { PaymentDetails } from '../PaymentModal'
import usePriceFromPreference from 'hooks/PriceFromPreference'
import { getTimeFrame } from 'helpers/getTimeFrame'
import { ChannelTypes } from 'pages/ChannelPage/channelType'

interface NormalSubscriptionViewProps {
  subscriptions: PaymentPropsType[]
  defaultCurrency: string
  isRootChannel: boolean
  channelType: ChannelTypes
  onSelect: (payment: PaymentPropsType, paymentDetails: PaymentDetails) => void
}

const NormalSubscriptionView: FC<NormalSubscriptionViewProps> = ({
  subscriptions,
  defaultCurrency,
  isRootChannel,
  channelType,
  onSelect
}) => {
  const normalSubscriptions =
    subscriptions && subscriptions.length > 0
      ? subscriptions.filter((subs) =>
          subs?.all_access ? !subs.all_access : true
        )
      : null

  const bannerHeading =
    [ChannelTypes.club, ChannelTypes.team].includes(channelType) &&
    !isRootChannel
      ? t`Become a monthly supporter`
      : t`Buy a subscription`
  return (
    <>
      {normalSubscriptions && normalSubscriptions.length > 0 && (
        <div className={styles.BuyViewContainer}>
          <div
            data-testid="buy-banner-normal-subscription-heading"
            className={styles.BannerHeading}
          >
            {bannerHeading}
          </div>
          <div className={styles.SubscriptionSection}>
            {normalSubscriptions.map((subscription) => {
              const { price, title, timeframe } = subscription
              const subscriptionPrice = usePriceFromPreference(price!)
              const subscriptionType = subscription.subscription_type
              const isMonthlySupporterType =
                subscriptionType === 'monthly_supporter'

              const onPaymentSelect = () => {
                onSelect(subscription, {
                  type: PAYMENTS.TYPE_SUBSCRIPTION,
                  subscription,
                  value: subscriptionPrice?.amount!,
                  currency: subscriptionPrice?.currency!,
                  mediaObjectTitle: title
                })
              }
              return (
                <BuyOptionBannerFactory
                  key={title + timeframe!}
                  onSelect={onPaymentSelect}
                  option={
                    isMonthlySupporterType
                      ? BUY_OPTIONS.MONTHLY_SUPPORTER
                      : BUY_OPTIONS.SUBSCRIPTION
                  }
                  channelType={subscription.partner_type_name}
                  channelName={subscription.company?.name}
                  amount={subscriptionPrice ? subscriptionPrice?.amount! : 0}
                  currency={
                    subscriptionPrice
                      ? subscriptionPrice.currency
                      : defaultCurrency
                  }
                  duration={getTimeFrame(timeframe) || ''}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default NormalSubscriptionView
