import React, { FC } from 'react'

const PreviewIcon: FC = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2084 1.07677C8.17741 1.00877 4.00842 3.82577 1.38742 6.71077C1.1123 7.0162 0.960052 7.4127 0.960052 7.82377C0.960052 8.23484 1.1123 8.63134 1.38742 8.93677C3.95141 11.7608 8.10842 14.6428 12.2084 14.5738C16.3084 14.6428 20.4664 11.7608 23.0324 8.93677C23.3075 8.63134 23.4598 8.23484 23.4598 7.82377C23.4598 7.4127 23.3075 7.0162 23.0324 6.71077C20.4084 3.82577 16.2394 1.00877 12.2084 1.07677Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9584 7.82574C15.9582 8.56738 15.7381 9.2923 15.3259 9.90885C14.9138 10.5254 14.328 11.0059 13.6428 11.2896C12.9575 11.5732 12.2036 11.6474 11.4762 11.5026C10.7488 11.3578 10.0807 11.0005 9.5564 10.476C9.03205 9.95155 8.675 9.28336 8.53039 8.55596C8.38577 7.82856 8.4601 7.07461 8.74395 6.38944C9.02781 5.70428 9.50846 5.11867 10.1251 4.70666C10.7418 4.29465 11.4668 4.07474 12.2084 4.07474C12.701 4.07461 13.1888 4.17155 13.6439 4.36002C14.099 4.54849 14.5124 4.8248 14.8607 5.17315C15.209 5.5215 15.4852 5.93507 15.6735 6.39021C15.8619 6.84536 15.9587 7.33316 15.9584 7.82574Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PreviewIcon
