import { getIsSpecialDomain } from '../getCustomChannelUrl'
import { FeatureFlagsAvailable } from '../featureFlags/FeatureFlagsAvailable'

export const getRedirect = (
  poweredByKey: boolean | null,
  redirectData: any,
  now: number,
  currentDomain: string,
  setFlag: (flag: FeatureFlagsAvailable, value: boolean) => void,
  development: boolean | null = false
) => {
  const isSpecialDomain = getIsSpecialDomain(currentDomain)
  const channelReleaseDate = redirectData?.unixReleaseDate

  const isBeforeChannelRelease = channelReleaseDate
    ? channelReleaseDate >= now
    : false

  // if it is poweredby and after release, set poweredby key == true
  if (!isSpecialDomain && !isBeforeChannelRelease) {
    setFlag(FeatureFlagsAvailable.PoweredBy, true)
  }

  // poweredby and before release
  if (isBeforeChannelRelease && !isSpecialDomain) {
    setFlag(FeatureFlagsAvailable.ComingSoon, false)
    return {
      isPrerelease: isBeforeChannelRelease,
      url: redirectData.redirectUrl
    }
  }

  // no redirect
  if (!redirectData.redirect) {
    return null
  }

  // if is solidport and before release, dont redirect and
  // remove poweredby key if it exists
  if (isSpecialDomain && isBeforeChannelRelease) {
    if (poweredByKey) {
      setFlag(FeatureFlagsAvailable.ComingSoon, false)
      setFlag(FeatureFlagsAvailable.PoweredBy, false)
      return {
        isPrerelease: false,
        keyRemoved: true,
        url: redirectData.redirectUrl
      }
    }
    return null
  }

  if (development) {
    return null
  }

  // if we need to redirect and there is no key,
  // set the key and provide redirect detatils
  if (redirectData.redirect) {
    if (poweredByKey === null || !poweredByKey) {
      setFlag(FeatureFlagsAvailable.PoweredBy, true)
    }
    const { redirectUrl } = redirectData!

    return {
      url: redirectUrl,
      isPrerelease: isBeforeChannelRelease
    }
  }
  return null
}
