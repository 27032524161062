import { RootState } from '../store'

export const reportContentDialogIsOpenSelector = (state: RootState) =>
  state.userInterface.reportContentModalIsOpen

export const broadcastInformationModalIsOpenSelector = (state: RootState) =>
  state.userInterface.broadcastInformationModalIsOpen

export const quikEditDialogIsOpenSelector = (state: RootState) =>
  state.userInterface.quickEditModalIsOpen
