import React, { FC } from 'react'
import styles from './LinkIconButton.module.scss'
import TextIconButton, { IconButtonProps, ButtonType } from '../TextIconButton'

interface LinkButtonProps extends Omit<IconButtonProps, 'onClick'> {
  href: string
  children: React.ReactNode
  linkOnClick?: () => void
}

export const LinkIconButton: FC<LinkButtonProps> = ({
  href,
  linkOnClick,
  ...props
}) => {
  return (
    <a className={styles.LinkIconButton} href={href} onClick={linkOnClick}>
      <TextIconButton {...props} onClick={() => null} />
    </a>
  )
}

export { ButtonType }
