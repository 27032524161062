import React, { FC, useCallback, useState, useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'
import authenticationActions from '../../../../redux-store/authentication/authentication.actions'
import Button, { ButtonType } from '../../../buttons/button/Button'

import { User } from '../../../../interfaces'
import HamburgerMenuIcon from './HamburgerMenuIcon'
import { MenuLink } from '../../menuLink/MenuLink'

import ChannelLogo from './ChannelLogo'
import { withTheme } from '../../../../pages/ChannelPage/Theme/withTheme'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'

import { useComponentVisible } from '../../../../helpers/useComponentVisible'
import { HeaderMenuItems } from './HeaderMenuItems'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'
import { Search } from '../Search/Search'
import { UserImageButton } from './UserImgButton/UserImageButton'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import { HamburgerMenu } from './HamburgerMenu/HamburgerMenu'

import styles from './NavigationHeader.module.scss'

interface NavigationHeaderProps {
  currentUser?: User
  links: MenuLink[]
  backgroundIsTransparentAtStart: boolean
  rootChannelType: ChannelTypes
}
const NavigationHeader: FC<NavigationHeaderProps> = ({
  currentUser,
  backgroundIsTransparentAtStart,
  rootChannelType
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const dispatch = useDispatch()

  const [hasBackground, setHasBackground] = useState(false)

  const channel = useSelector(channelSelector)
  const channelSlug = channel?.data?.subdomain
  const isRootPage = channel?.data?.isRootChannel

  const isChildOfThemedRoot = channel?.data?.isChildOfRootChannel
  const rootChannelSlug = isChildOfThemedRoot
    ? channel?.data?.root_channel?.subdomain
    : channelSlug

  const [isRootPageorChild, setIsRootPageorChild] = useState(
    isRootPage || isChildOfThemedRoot
  )

  const [useTheme, setUseTheme] = useState(isRootPageorChild)
  const [rootChannelSubdomain, setRootChannelSubdomain] = useState(
    channel?.data?.parentChannelSubdomain
  )
  const [logo, setLogo] = useState(channel?.data?.parentLogoUrl)

  useEffect(() => {
    if (channel.data || !channelSlug) {
      setIsRootPageorChild(
        channel?.data?.isRootChannel || channel?.data?.isChildOfRootChannel
      )
      setRootChannelSubdomain(channel?.data?.parentChannelSubdomain)
      setLogo(channel?.data?.parentLogoUrl)
    }
  }, [channel, channelSlug])

  useEffect(() => {
    setUseTheme(isRootPageorChild)
  }, [isRootPageorChild])

  const { isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const isMobile = currentBreakpoint < Breakpoint.md
  useEffect(() => {
    const changeBackground = () => {
      if (useTheme) return
      if (!isMobile) {
        if (window.scrollY >= 96) {
          setHasBackground(true)
        } else {
          setHasBackground(false)
        }
      } else if (window.scrollY >= 56) {
        setHasBackground(true)
      } else {
        setHasBackground(false)
      }
    }
    if (backgroundIsTransparentAtStart) {
      window.addEventListener('scroll', changeBackground)
    } else {
      window.removeEventListener('scroll', changeBackground)
      setHasBackground(true)
    }
  }, [backgroundIsTransparentAtStart, useTheme, isMobile])

  const getBackgroundClass = useCallback(() => {
    if (useTheme) {
      return 'ThemedBackground'
    }
    if (!backgroundIsTransparentAtStart) {
      return 'HasBackgroundFromStart'
    }
    return hasBackground ? 'HasBackground' : 'NoBackground'
  }, [hasBackground, useTheme, backgroundIsTransparentAtStart])

  const setButtonType = () => {
    if (!backgroundIsTransparentAtStart) {
      return isRootPageorChild
        ? ButtonType.ThemedInverted
        : ButtonType.CreateAccount
    }
    if (hasBackground) {
      return isRootPageorChild
        ? ButtonType.ThemedInverted
        : ButtonType.CreateAccount
    }
    return ButtonType.GhostInverted
  }

  return (
    <div
      className={classNames(
        styles.NavigationHeader,
        styles[getBackgroundClass()],
        {
          [styles.NavigationHeaderWithoutTopHeader]: isRootPageorChild,
          [styles.NavigationHeaderWithTopHeader]: !isRootPageorChild,
          [styles.NavigationHeaderWithoutMenuItems]:
            channel?.data?.isRootClubChannel
        }
      )}
      data-testid="navigation-menu-container"
    >
      {isComponentVisible && (
        <HamburgerMenu isOpen={isComponentVisible} onClose={toggleMenu} />
      )}
      {isRootPageorChild && isMobile && logo && (
        <ChannelLogo
          logoPath={logo}
          channelName={channel?.name}
          rootChannelSubdomain={rootChannelSubdomain}
        />
      )}
      <div className={styles.HeaderContent}>
        <div className={styles.LeftContent}>
          {isRootPageorChild && !isMobile && logo && (
            <ChannelLogo
              logoPath={logo}
              channelName={channel.name}
              rootChannelSubdomain={rootChannelSubdomain}
            />
          )}

          <HeaderMenuItems
            rootChannelType={rootChannelType}
            isRootPageorChild={isRootPageorChild}
            hasBackground={hasBackground}
            useTheme={useTheme}
            backgroundIsTransparentAtStart={backgroundIsTransparentAtStart}
            rootChannelSubdomain={rootChannelSubdomain}
          />
        </div>
        <div className={styles.RightContainer}>
          <Search
            hasBackground={hasBackground}
            backgroundIsTransparentAtStart={backgroundIsTransparentAtStart}
            rootChannelSlug={rootChannelSlug}
            isRootOrChildChannel={isRootPageorChild}
          />
          {!isMobile &&
            (currentUser ? (
              <UserImageButton onClick={toggleMenu} currentUser={currentUser} />
            ) : (
              <div className={styles.ButtonsContainer}>
                {currentBreakpoint > Breakpoint.md && (
                  <Button
                    data-testid="create-account-button"
                    label={t`Create account`}
                    type={setButtonType()}
                    buttonSize="Medium"
                    onClick={() =>
                      dispatch(
                        authenticationActions.openLoginLayer(
                          undefined,
                          'signup'
                        )
                      )
                    }
                  />
                )}
                <Button
                  data-testid="login-button"
                  label={t`Login`}
                  type={
                    isRootPageorChild
                      ? ButtonType.ThemedPrimary
                      : ButtonType.Primary
                  }
                  buttonSize="Medium"
                  onClick={() =>
                    dispatch(
                      authenticationActions.openLoginLayer(undefined, 'login')
                    )
                  }
                />
              </div>
            ))}
          {currentBreakpoint <= Breakpoint.sm && (
            <IconButton
              id="hamburger-menu-button"
              customStyle={classNames(styles.MenuButton, {
                [styles.HamburgerMenuIconLight]:
                  !hasBackground && backgroundIsTransparentAtStart,
                [styles.HamburgerMenuIconDark]: hasBackground
              })}
              onClick={() => toggleMenu()}
            >
              <HamburgerMenuIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}
NavigationHeader.defaultProps = {
  currentUser: undefined
}
export default withTheme(NavigationHeader)
