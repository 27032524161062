import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'
import { Honeybadger } from '@honeybadger-io/react'
import { useHistory } from 'react-router-dom'

import ErrorIcon from './ErrorIcon'
import Button, { ButtonType } from '../../../components/buttons/button/Button'
import styles from './error.module.scss'

interface Props {
  backgroundImage: string
  homePage: string
}

const Error: FC<Props> = ({ backgroundImage, homePage }) => {
  const history = useHistory()

  const naviagateHome = () => {
    history.push(homePage)
  }
  const handleStartPageClick = () => {
    naviagateHome()
  }
  const handleReportErrorClick = () => {
    Honeybadger.notify('user has reported an error with the viewer page')
    naviagateHome()
  }

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center'
      }}
      className={styles.ErrorContainer}
    >
      <div className={styles.ContentContainer}>
        <div className={styles.ErrorIcon}>
          <ErrorIcon />
        </div>
        <h2>
          <Trans>Something went wrong with the broadcast</Trans>
        </h2>
        <p>
          <Trans>
            There is a problem with the broadcast right now, try to reload the
            page and see if that fixes it. If it is still not working, report it
            to our support.
          </Trans>
        </p>
        <p>
          <Trans>To return to start page click on the button below.</Trans>
        </p>
        <div className={styles.ButtonContainer}>
          <Button
            label={t`Go back to start page`}
            buttonSize="Medium"
            type={ButtonType.Primary}
            onClick={handleStartPageClick}
          />
          <Button
            label={t`Report`}
            buttonSize="Medium"
            type={ButtonType.Secondary}
            onClick={handleReportErrorClick}
          />
        </div>
      </div>
    </div>
  )
}

export default Error
