import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import ChatMessageComponent from './ChatMessage/UserMessage/ChatMessage'
import { getChatMessages } from '../../../api/viewer/Chat/getChatMessages'
import { ChatMessage } from '../../../interfaces/ChatMessage.interface'
import useInfiniteScroll from '../../../hooks/InfiniteScroll'

import styles from './Chat.module.scss'
import ReporterMessage from './ChatMessage/ReporterMessage/ReporterMessage'
import { reporterText } from './getReporterText'
import { reporterImage } from './ChatMessage/ReporterMessage/getReporterImage'
import { MediaObject } from '../../../interfaces'
import subscriberActions from '../../../redux-store/NchanSubscriber/subscriber.actions'
import InputContainer from './InputContainer/InputContainer'
import {
  newMessageSelector,
  hideMessageSelector
} from '../../../redux-store/NchanSubscriber/reducer'
import ChatHeader from './ChatHeader/ChatHeader'
import { hideChatMessage } from '../../../api/viewer/Chat/hideChatMessage'
import { blockUserFromChat } from '../../../api/viewer/Chat/blockUserFromChat'

interface ChatProps {
  mediaObject: MediaObject
}

const Chat: FC<ChatProps> = ({ mediaObject }) => {
  const infiniteScrollRef = useRef(null)
  const isTopReached = useInfiniteScroll(infiniteScrollRef)

  const dispatch = useDispatch()
  const [messages, setMessages] = useState<ChatMessage[][]>([[]])
  const [chatData, setChatData] = useState([])

  const mediaObjectId = mediaObject.id
  const newMessage = useSelector(newMessageSelector)
  const hideMessage = useSelector(hideMessageSelector)
  const per_page = 20
  const [page, setPage] = useState(1)

  useEffect(() => {
    const newMessageId = newMessage && newMessage.message_id
    const foundMessage = messages
      .flat()
      .find((msg: ChatMessage) => msg.message_id === newMessageId)

    if (newMessageId && !foundMessage) {
      const newMsgs = messages
      newMsgs[0] = [newMessage, ...messages[0]]
      setMessages(newMsgs)
      dispatch(subscriberActions.removeNewMessage())
    }
  }, [newMessage])

  useEffect(() => {
    setMessages([[]])
  }, [mediaObjectId])

  useEffect(() => {
    if (hideMessage) {
      setMessages((preMessages) =>
        preMessages.map((page: ChatMessage[]) =>
          page.filter(
            (message: ChatMessage) =>
              message.message_id !== hideMessage.message_id
          )
        )
      )
    }
  }, [hideMessage])

  useEffect(() => {
    if (isTopReached && !(chatData.length === 0)) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [isTopReached, chatData])

  const fetchMessages = useCallback(async () => {
    const result = await getChatMessages(mediaObjectId, {
      page: page,
      per_page: per_page
    })
    if (result.length > 0 && page > 0) {
      setChatData(result)
      const msgs = messages
      msgs[page - 1] = result
      setMessages(msgs)
    }
  }, [page, mediaObjectId])

  useEffect(() => {
    if (mediaObjectId) {
      fetchMessages()
    }
  }, [mediaObjectId, fetchMessages])

  const hideMessageInChat = (messageId: number) => {
    const hideMessageParameters = { messageId: messageId, mediaObjectId }
    hideChatMessage(hideMessageParameters)
  }
  const blockUserInChat = (messageId: number) => {
    blockUserFromChat({ messageId, mediaObjectId })
  }

  return (
    <div className={styles.Chat}>
      <InputContainer mediaObjectId={mediaObjectId} />

      <div className={styles.MessagesContainer} data-testid="message-container">
        {mediaObject.game && messages.length === 0 && (
          <>
            <ChatHeader
              title={t`Cheer on your team!`}
              subText={t`Chat before, during and after the match`}
              thumbnailHeader={t`Who do you support?`}
              mediaObject={mediaObject}
            />
          </>
        )}
        {messages &&
          messages.length > 0 &&
          messages.flat().map((message) => (
            <div
              data-testid="chat-messages"
              key={`msg-${message.message_id}`}
              className={classNames(styles.Message)}
            >
              {message.activity_log && (
                <div className={styles.AlignRight}>
                  <ReporterMessage
                    reporterName={message.activity_log.team_name}
                    reporterImgPath={reporterImage(message.activity_log)}
                    text={reporterText(message.activity_log)}
                    publishedTime={message.time}
                  />
                </div>
              )}
              {!message.activity_log && (
                <ChatMessageComponent
                  ident={message.message_id}
                  userName={message.user_name}
                  userImgPath={message.thumbnail}
                  text={message.message_text}
                  chatImages={message.images[0]}
                  chatVideos={message.videos[0]}
                  isAdmin={message.is_admin}
                  isSuperAdmin={message.is_super_admin}
                  publishedTime={message.time}
                  onHide={(id: number) => hideMessageInChat(id)}
                  onBlock={(id: number) => blockUserInChat(id)}
                />
              )}
            </div>
          ))}
        <div ref={infiniteScrollRef} />
      </div>
    </div>
  )
}
export default Chat
