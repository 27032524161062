import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

import { Company } from '../interfaces/Company.interface'
import { MediaObject } from 'interfaces/MediaObject.interface'

import { metaFriendlyString } from 'helpers/generalHelpers'
import { tagsAndCategories } from '../helpers/tagsAndCategories'
import { getRichVideoMetaData } from './getRichVideoMetaData'
import { getRichBreadcrumbMetaData } from './getRichBreadcrumbMetaData'
import { BreadCrumbLink } from 'components/Breadcrumb/breadcrumbLink'

interface Props {
  channel: Company
  mediaObject: MediaObject
  breadcrumbs: BreadCrumbLink[]
}

export const VideoMeta: FC<Props> = ({ channel, mediaObject, breadcrumbs }) => {
  return (
    <Helmet prioritizeSeoTags>
      {mediaObject.description && (
        <meta
          name="description"
          content={metaFriendlyString(mediaObject.description)}
        />
      )}
      {(mediaObject.tags || mediaObject.categories) && (
        <meta
          name="keywords"
          content={tagsAndCategories(mediaObject, ['Streaming']).toString()}
        />
      )}
      <meta
        property="og:title"
        content={metaFriendlyString(mediaObject.title)}
      />
      <meta property="og:type" content="video.other" />
      <meta property="og:url" content={mediaObject.link} />
      <meta property="og:image" content={mediaObject.thumbnail} />
      <meta property="og:image:secure_url" content={mediaObject.thumbnail} />
      <meta property="og:site_name" content={channel.name} />
      <meta
        property="og:description"
        content={metaFriendlyString(mediaObject.description)}
      />
      <meta property="og:locale" content={navigator.language} />
      <meta
        name="twitter:title"
        content={metaFriendlyString(mediaObject.title)}
      />
      <meta
        name="twitter:description"
        content={metaFriendlyString(mediaObject?.description)}
      />
      <meta
        name="twitter:image"
        content={
          mediaObject?.thumbnail || `${window.location.origin}/logo512.png`
        }
      />
      <script type="application/ld+json">
        {getRichVideoMetaData(mediaObject, channel)}
      </script>
      <script type="application/ld+json">
        {getRichBreadcrumbMetaData(breadcrumbs)}
      </script>
    </Helmet>
  )
}
