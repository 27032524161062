import React, { FC, memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'
import styles from './channelName.module.scss'
import { Spacer } from './Spacer'
import { setHeaderFontLoaded } from '../../../../redux-store/channelTheme/channelTheme.actions'

import { channelFontsSelector } from '../../../../redux-store/channelFonts/channelFonts.selectors'
import { useThemeFont } from '../../Theme/useThemeFont'
import { useChannelTheme } from '../../Theme/useChannelTheme'
import { ChannelTypes } from '../../channelType'
import { ChannelTypeLabel } from './TypeLabel/ChannelTypeLabel'

interface Props {
  isRootPage: boolean
  channelName: string
  channelType: ChannelTypes
}

const ChannelName: FC<Props> = ({ isRootPage, channelName, channelType }) => {
  const [loadedFont, setLoadedFont] = useState<string>()
  const { channelTheme, channelThemeHeaderFontIsLoaded } = useChannelTheme()
  const customFontTheme = channelTheme && channelTheme.channelHeadingFontFamily
  const channelFontList = useSelector(channelFontsSelector)
  const font = channelFontList.find((f) => f.identifier === customFontTheme)

  useThemeFont({
    channelName,
    customFontTheme,
    isFontLoaded: channelThemeHeaderFontIsLoaded,
    customFont: font,
    setLoadedAction: setHeaderFontLoaded,
    setLoadedFont
  })

  useEffect(() => {}, [loadedFont])
  return (
    <>
      {!isRootPage && <Spacer />}
      <div
        className={classNames({
          [styles.ChannelName]: !isRootPage,
          [styles.ChannelNameNoImage]: isRootPage,
          [styles.NotThemed]: !isRootPage
        })}
      >
        <h1
          className={classNames({
            [styles.ChannelHeaderText]:
              isRootPage && channelThemeHeaderFontIsLoaded
          })}
        >
          {channelName}
        </h1>
        {channelType && !isRootPage && (
          <ChannelTypeLabel channelType={channelType} />
        )}
      </div>
    </>
  )
}

export default memo(ChannelName)
