import React, { FC } from 'react'
import { User } from '../../../interfaces'
import NavigationHeader from './navigationHeader/NavigationHeader'
import { TopHeader } from './topHeader/TopHeader'
import { links } from './menuLinks'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'

interface HeaderProps {
  currentUser?: User
  transparentBackground: boolean
  rootChannelType: ChannelTypes | undefined
}
const Header: FC<HeaderProps> = ({
  currentUser,
  transparentBackground,
  rootChannelType
}) => (
  <>
    <TopHeader currentUser={currentUser} links={links} showLanguage={false} />
    <NavigationHeader
      currentUser={currentUser}
      links={links}
      backgroundIsTransparentAtStart={transparentBackground}
      rootChannelType={rootChannelType}
    />
  </>
)
Header.defaultProps = {
  currentUser: undefined
}
export default Header
