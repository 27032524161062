import React, { FC } from 'react'
import { Breadcrumb } from '../../../components/Breadcrumb/Breadcrumb'
import { crumbList } from '../Breadcrumb/crumbList'
import GeoBlockedView from './GeoBlockedView'
import styles from './GeoBlockedView.module.scss'
interface Props {
  isRootChannel: boolean
  parentChannelSubdomain: string
  channelName: string
  channelSlug: string
  broadcastSlug: string
  broadcastTitle: string
  backgroundImage: any
}

export const GeoBlock: FC<Props> = ({
  channelName,
  channelSlug,
  broadcastSlug,
  isRootChannel,
  parentChannelSubdomain,
  backgroundImage,
  broadcastTitle
}) => {
  const crumbs = crumbList({
    channelName,
    channelSlug,
    broadcastTitle,
    broadcastSlug,
    isRootChannel,
    parentChannelSubdomain
  })
  return (
    <>
      <div className={styles.BreadcrumbContainer}>
        <Breadcrumb pathList={crumbs} />
      </div>
      <GeoBlockedView
        title={broadcastTitle}
        backgroundImage={backgroundImage}
      />
    </>
  )
}
