import React, { FC, useState, useCallback, useMemo, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { getListOfCustomCategories } from '../../../api/channel/getListOfCustomCategories'
import { groupVideosByDate } from '../../../api/channel/getVideosForChannel'
import { useDataApi } from '../../../api/useDataApi'
import ChronologicalVideoList from '../../../components/lists/ChronologicalVideoList/ChronologicalVideoList'
import CategoryFieldDropdown from './Dropdown/CategoryFieldDropdown'
import styles from './ChannelVideos.module.scss'
import { getDefaultCategoriesList } from './Dropdown/getDefaultCategoryList'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { getCorrectApiforFilter } from './getCorrectApiForFilter'
import { Loader } from '../../../components/Loader/Loader'
import { mergeVideoDataObjects } from '../../SchedulePage/mergeVideoDataObjects'
import LoadMoreSection from '../../../components/LoadMoreSection/LoadMoreSection'
import { scrollElementInToview } from '../../../helpers/scrollElementInToView'

interface Props {
  channelSlug: string
}

const ChannelVideos: FC<Props> = ({ channelSlug }) => {
  const PER_PAGE_COUNT = 15
  const [page, setPage] = useState(1)
  const [videoData, setVideoData] = useState<any>({})
  const channel = useSelector(channelSelector)
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel
  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const isRootClubChannel = channel?.data?.isRootClubChannel
  const defaultCategories = getDefaultCategoriesList(
    channel?.data?.meta_data?.content?.has_highlights
  )
  const [categorySlug, setCategorySlug] = useState(defaultCategories[0].id)
  const topOfVideo = 'top-of-video'
  const { isLoading, isError, data } = useDataApi({
    apiCall: useCallback(getCorrectApiforFilter(categorySlug), [categorySlug]),
    parameters: useMemo(
      () => ({
        category: categorySlug,
        slug: channelSlug,
        page,
        perPageCount: PER_PAGE_COUNT
      }),
      [page, channelSlug, categorySlug]
    )
  })
  const categoryData = useDataApi({
    apiCall: useCallback(getListOfCustomCategories, []),
    parameters: useMemo(
      () => ({
        slug: channelSlug
      }),
      [channelSlug]
    )
  })
  const getListForDropdown = () => {
    if (isRootOrChildChannel) {
      return categoryData.data
    }
    return null
  }
  const totalPages = data?.totalCount ? data?.totalCount / PER_PAGE_COUNT : 0
  useEffect(() => {
    if (!isLoading && !isError && data && data.data) {
      const grouped = groupVideosByDate(data.data)
      const newVideoData = mergeVideoDataObjects(videoData, grouped)
      setVideoData(newVideoData)
    }
  }, [isLoading, isError, data])
  if (isError) {
    return (
      <div className={styles.ErrorMessage}>
        <Trans>Something went wrong, try reloading the page.</Trans>
      </div>
    )
  }
  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }

  const handleSelectedItem = (id: string) => {
    if (id !== categorySlug) {
      scrollElementInToview(topOfVideo)
      setVideoData({})
      setPage(1)
      setCategorySlug(id)
    }
  }
  return (
    <div>
      <div
        className={classNames(styles.DropdownField, {
          [styles.IsThemedPage]: isRootOrChildChannel,
          [styles.IsNotThemedPage]: !isRootOrChildChannel,
          [styles.IsLGBPage]: isRootClubChannel
        })}
      >
        <CategoryFieldDropdown
          handleSelectedItem={(id) => handleSelectedItem(id)}
          dropdownList={getListForDropdown()}
        />
      </div>
      {Object.keys(videoData).length === 0 && !isLoading ? (
        <div className={styles.NoContentExistContainer}>
          <div className={styles.TextContainer}>
            <Trans>There is no content for this category right now...</Trans>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.TopTag} id={topOfVideo} />
          <ChronologicalVideoList
            list={videoData}
            showRootDomainLabel={false}
          />
          {isLoading && (
            <div className={styles.VideoListLoader}>
              <Loader />
            </div>
          )}
          <LoadMoreSection
            currentPage={page}
            totalPages={totalPages}
            onClick={increasePageNumber}
            scrollId={topOfVideo}
          />
        </>
      )}
    </div>
  )
}

export default ChannelVideos
