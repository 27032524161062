import { Channel } from '../../../api/channel/getChannel'
import { ChannelTypes } from '../channelType'

export const getBannerDisplayData = (
  channelDetails: Channel,
  channelType: ChannelTypes
) => {
  const subscriptionsAvailable =
    channelDetails &&
    channelDetails.type &&
    [ChannelTypes.club, ChannelTypes.team].includes(
      ChannelTypes[channelType]
    ) &&
    channelDetails?.meta_data.store.has_monthly_supporter &&
    !channelDetails?.isRootClubChannel
  const eventPassAvailable =
    channelDetails &&
    channelDetails.type &&
    ChannelTypes[channelType] === ChannelTypes.event &&
    channelDetails?.meta_data.store?.has_all_access &&
    !channelDetails?.isRootChannel

  return { eventPassAvailable, subscriptionsAvailable }
}
