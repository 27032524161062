import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  hasLivestream: boolean
  gameSlug: string
  children?: React.ReactNode
}
const DecideLink: FC<Props> = ({ hasLivestream, gameSlug, children }) =>
  hasLivestream ? <Link to={gameSlug}>{children}</Link> : <>{children}</>
export default DecideLink
