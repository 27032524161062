import React from 'react'
import { Trans } from '@lingui/macro'

import './index.scss'

const HoneybadgerError: React.FC = () => (
  <div className="ErrorContainer">
    <h2>
      <Trans>Oops! There was an error in the application.</Trans>
    </h2>
    <p>
      <Trans>
        We&apos;ve been notified about this issue and we&apos;ll take a look at
        it as soon as we can.
      </Trans>
    </p>
  </div>
)

export default HoneybadgerError
