const ONE_HOUR = 3600
const ONE_DAY = ONE_HOUR * 24
const DATETIME = {
  ONE_HOUR,
  ONE_DAY,
  TWO_DAYS: ONE_DAY * 2,
  SIX_DAYS: ONE_DAY * 6,
  ONE_WEEK: ONE_DAY * 7
}

export default DATETIME
