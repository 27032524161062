import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { deleteComment } from '../../../api/viewer/Comments/deleteComment'
import { getComments } from '../../../api/viewer/Comments/getComments'
import useInfiniteScroll from '../../../hooks/InfiniteScroll'
import { Comment, MediaObject } from '../../../interfaces'
import CommentBubble from './CommentBubble/CommentBubble'

import styles from './Comments.module.scss'
import CommentsInput from './CommentsInput/CommentsInput'

interface CommentsProps {
  mediaObject: MediaObject
}
const Comments: FC<CommentsProps> = ({ mediaObject }) => {
  const infiniteScrollRef = useRef(null)
  const isTopReached = useInfiniteScroll(infiniteScrollRef)
  const [commentsData, setCommentsData] = useState([])
  const [comments, setComments] = useState<Comment[]>([])
  const [page, setPage] = useState(1)
  const per_page = 10

  const fetchComments = useCallback(async () => {
    const result = await getComments(mediaObject.id, {
      page: page,
      per_page: per_page
    })
    const filteredResult: any = result.filter(
      (item: any) => !comments.map((c) => c.id).includes(item)
    )
    setCommentsData(filteredResult)
    setComments((prevMessages) => prevMessages.concat(filteredResult))
  }, [page, mediaObject.id])

  const addPostedComment = (comment: Comment) => {
    setComments([comment, ...comments])
  }

  useEffect(() => {
    fetchComments()
  }, [mediaObject.id, fetchComments])

  useEffect(() => {
    if (isTopReached && !(commentsData && commentsData.length === 0)) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [isTopReached, commentsData.length])

  const deleteCommentWithId = (messageId: string) => {
    deleteComment({ messageId })
    setComments(comments.filter((com: Comment) => com.id !== messageId))
  }
  return mediaObject ? (
    <div className={styles.Comments}>
      <CommentsInput
        mediaObjectId={mediaObject.id}
        onPostedComment={(comment) => addPostedComment(comment)}
      />
      <div
        className={styles.CommentsContainer}
        data-testid="comments-container"
      >
        {comments &&
          comments.length > 0 &&
          comments.map((comment: Comment) => (
            <div className={styles.Comments} key={comment.id}>
              <CommentBubble
                ident={comment.id}
                userName={comment.user.name}
                userImgPath={comment.user.thumbnail}
                text={comment.content}
                isAdmin={comment.user.is_admin}
                isSuperAdmin={comment.user.is_super_admin}
                publishedTime={comment.created_at}
                onDelete={(id) => deleteCommentWithId(id)}
              />
            </div>
          ))}
        <div ref={infiniteScrollRef} />
      </div>
    </div>
  ) : null
}
export default Comments
