import React, { FC } from 'react'
import classNames from 'classnames'
import {
  BroadcastThumbnailInterface,
  BroadcastThumbnailType
} from '../broadcastCardTypes'
import { DetailsRow, BroadcastDetailsType } from '../DetailsRow'
import styles from './index.module.scss'
import BottomTimeLabel from '../BottomTimeLabel'
import { ThumbnailLabel } from '../ThumbnailLabel/ThumbnailLabel'
import { Tooltip } from '../../../Tooltip/Tooltip'
import { RootDomainLabel } from '../RootDomainLabel/RootDomainLabel'

interface Props {
  content: BroadcastThumbnailInterface
  size: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'keepRatio'
  showDetails: boolean
  isLive: boolean
  showRootDomainLabel?: boolean
}

const BroadcastContent: FC<Props> = ({
  content,
  size,
  showDetails,
  isLive,
  showRootDomainLabel
}) => {
  const isVideo = content.type === BroadcastThumbnailType.Video
  const getBroadcastType = () => {
    if (content.isReplay) {
      return BroadcastDetailsType.Video
    }
    if (content.highlightFromDate) {
      return BroadcastDetailsType.Highlight
    }
    if (isVideo) {
      return BroadcastDetailsType.Video
    }
    return BroadcastDetailsType.Broadcast
  }
  return (
    <div
      className={classNames(styles.Broadcast, styles[size], {
        [styles.HasBottomContainer]: showDetails
      })}
    >
      <div className={styles.ImageWrapper}>
        <img src={content.thumbnailImageUrl} alt={content.type} />
        <div className={styles.ThumbnailLabelContainer}>
          <ThumbnailLabel
            isLive={isLive}
            isReplay={content.isReplay}
            highlightFromDate={content.highlightFromDate}
            startAt={content.startTime}
            type={content.type}
            publishedAt={content.publishedAt}
          />
        </div>
        {(content.isReplay || content.highlightFromDate || isVideo) &&
          content.duration && (
            <div className={styles.TimeLabelContainer}>
              <BottomTimeLabel durationInSeconds={content.duration} />
            </div>
          )}
        {showRootDomainLabel && content.rootDomain && (
          <div className={styles.RootDomainLabelContainer}>
            <RootDomainLabel label={content.rootDomain} />
          </div>
        )}
      </div>
      {showDetails && (
        <div className={styles.BottomContainer}>
          <div data-testid="broadcast-thumbnail-title" className={styles.Title}>
            {content.title}
          </div>
          {content.title && (
            <div className={styles.TooltipWrapper}>
              <Tooltip text={content.title} />
            </div>
          )}
          <DetailsRow
            broadcastType={getBroadcastType()}
            viewers={content.viewers}
            views={content.views}
            showViewers={content.showViewers}
            showViews={content.showViews}
            live={isLive}
            hasHighlight={content.hasHighlight}
            highlightFromDate={content.highlightFromDate || 0}
            publishedAt={content.publishedAt}
            replay={content.isReplay}
            endTime={content.endTime}
            categoryName={
              content.categories && content.categories.length > 0
                ? content.categories[0]?.name
                : ''
            }
          />
        </div>
      )}
    </div>
  )
}
export default BroadcastContent
