import React, { FC } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import styles from './LabelAndDate.module.scss'

interface Props {
  label: string
  labelSlug: string
  startTime: number
}

export const LabelAndDate: FC<Props> = ({ label, labelSlug, startTime }) => {
  const dateString = dayjs.unix(startTime).format('dddd D MMMM, H:mm')
  return (
    <div className={styles.LabelAndDateContainer}>
      <div className={styles.Label} data-testid="display-label">
        {labelSlug ? (
          <Link to={`/${labelSlug}`}>{label}</Link>
        ) : (
          <span>{label}</span>
        )}
      </div>
      {startTime && (
        <div className={styles.Date} data-testid="display-date">
          {dateString}
        </div>
      )}
    </div>
  )
}
