import { Dispatch } from 'redux'
import {
  fetchStreamError,
  fetchStreamStart,
  fetchStreamSuccess
} from '../../redux-store/stream/stream.actions'
import { getVideoPlayerData } from './getVideoPlayerData'
import { ViewerParameters } from './viewerPage.interfaces'

export const createGetStreamActions = async (
  dispatch: Dispatch<any>,
  mediaObjectParams: ViewerParameters
) => {
  try {
    dispatch(fetchStreamStart())
    const videoPlayerData = await getVideoPlayerData(mediaObjectParams)
    dispatch(fetchStreamSuccess(videoPlayerData))
  } catch (err: any) {
    dispatch(fetchStreamError())
  }
}
