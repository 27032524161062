import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { MediaObject } from '../../../interfaces'
import { streamSelector } from '../../../redux-store/stream/stream.selectors'
import { Scoreboard } from './Scoreboard/Scoreboard'
import styles from './Statistics.module.scss'
import { getChannelAdTargeting } from '../../ChannelPage/Start/getChannelAdTargeting'
import ContentContainer from '../../../components/ContentContainer'
import { NativeAd } from '../../../advertising/NativeAd/NativeAd'

export const Statistics = () => {
  const stream = useSelector(streamSelector)
  const channelDetails = useSelector(channelSelector)

  const mediaObject: MediaObject = stream?.data?.mediaObject
  const { parentChannelDomain, partnerDomain, sportCategory, countryCode } =
    getChannelAdTargeting(channelDetails.data)

  const targetingOptions = useMemo(
    () => ({
      partnerDomain,
      parentChannelDomain,
      sportCategory,
      countryCode
    }),
    [channelDetails]
  )

  return (
    <div className={styles.Statistics}>
      <ContentContainer
        hasLeftRightPadding
        hasTopBottomPadding={false}
        hasLightBackground
      >
        <NativeAd
          slotPath="/21792873324/Viewer_Statistics_Slot"
          adId="div-gpt-ad-1680188725142-0"
          size={['fluid']}
          targetingOptions={targetingOptions}
        />
      </ContentContainer>
      {mediaObject && (
        <Scoreboard
          game={mediaObject.game}
          leagueName={mediaObject?.event_schedule?.name}
        />
      )}
    </div>
  )
}
