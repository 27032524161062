import { t } from '@lingui/macro'
import { LinkType, MenuLink } from '../menuLink/MenuLink'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

export const links: MenuLink[] = [
  // {
  //   type: LinkType.Route,
  //   link: '/',
  //   translationText: t`Watch`
  // },
  {
    type: LinkType.AnchorTag,
    link: 'https://www.solidsport.com/en/products',
    translationText: t`Products`,
    linkTarget: LinkTarget.NewWindow
  },
  {
    type: LinkType.AnchorTag,
    link: 'https://solidsport.freshdesk.com/support/home',
    translationText: t`Support`,
    linkTarget: LinkTarget.NewWindow
  },
  {
    type: LinkType.AnchorTag,
    link: 'https://press.solidsport.com/',
    translationText: t`News`,
    linkTarget: LinkTarget.NewWindow
  }
  /*
  {
    type: LinkType.Route,
    link: '/broadcast',
    translationText: t`broadcast live`
  },
  {
    type: LinkType.AnchorTag,
    link: ['https://www.solidsport.com', 'faq'],
    translationText: t`FAQ`
  },
  {
    type: LinkType.AnchorTag,
    link: ['https://www.solidsport.com', t`streaming-school`],
    translationText: t`Streaming School`
  }
  */
]
