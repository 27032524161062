import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'

import DefaultUserImage from '../../../../../assets/images/default_user_image.png'
import superAdminImage from '../../../../../assets/images/solidsport-logo-86x86.png'
import styles from './ChatMessage.module.scss'
import useAdminAccess from '../../../../../hooks/AdminAccess'
import { getDecodedHTMLContent } from '../../../getDecodedHTMLContent'
import { getMessageTimeStamp } from '../getMessageTimeStamp'

interface Props {
  ident: number
  userName: string
  userImgPath: string
  text: string
  chatImages: string
  chatVideos: string
  isAdmin: boolean
  isSuperAdmin: boolean
  publishedTime: number
  onHide: (id: number) => void
  onBlock: (id: number) => void
}
const ChatMessage: FC<Props> = ({
  ident,
  userName,
  userImgPath,
  text,
  chatImages,
  chatVideos,
  isAdmin,
  isSuperAdmin,
  publishedTime,
  onHide,
  onBlock
}) => {
  const userImage = isSuperAdmin
    ? superAdminImage
    : userImgPath || DefaultUserImage

  const adminOrUser = isAdmin ? `${userName} (Admin)` : userName
  const displayedUserName = isSuperAdmin ? 'Solidsport' : adminOrUser
  const userImageAlt = isSuperAdmin ? 'Solidsport icon' : userName

  const timeStamp = getMessageTimeStamp(publishedTime)
  const messageText = getDecodedHTMLContent(text)

  const adminAccess = useAdminAccess()
  const showAdminButtons = adminAccess.hasChannelAccess
  const showBlockButton = showAdminButtons && !(isAdmin || isSuperAdmin)
  return (
    <div
      className={styles.ChatMessageContainer}
      data-testid="chat-user-message"
    >
      <img className={styles.UserImage} src={userImage} alt={userImageAlt} />
      <div className={styles.UserNameAndTextBox}>
        <div className={styles.UserName}>{displayedUserName}</div>
        <div className={styles.ChatBoxContainer}>
          <div className={styles.Triangle} />
          <div className={styles.ChatMessageText}>
            <div className={styles.text} data-testid="chat-user-message-text">
              {messageText}
            </div>
            {chatImages && (
              <img
                className={styles.ChatImage}
                src={chatImages}
                alt={t`chatImage added by ${userName}`}
              />
            )}
            {chatVideos && (
              <video className={styles.ChatVideo} src={chatVideos} controls />
            )}
            <div className={styles.TimeStamp}>{timeStamp}</div>
          </div>
        </div>
        {showAdminButtons && (
          <div className={styles.AdminFunctionality}>
            <div
              className={styles.Button}
              role="button"
              onClick={() => onHide(ident)}
              onKeyPress={() => onHide(ident)}
              tabIndex={0}
            >
              <Trans>Hide</Trans>
            </div>
            {showBlockButton && (
              <div
                className={styles.Button}
                role="button"
                onClick={() => onBlock(ident)}
                onKeyPress={() => onBlock(ident)}
                tabIndex={0}
              >
                <Trans>Block</Trans>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default ChatMessage
