import STREAM from './stream.constants'
import { StreamUrls } from '../../pages/ViewerPage/viewerPage.interfaces'

export const fetchStreamStart = () => ({
  type: STREAM.STREAM_FETCH_START
})
export const fetchStreamSuccess = (data: any) => ({
  type: STREAM.STREAM_FETCH_SUCCESS,
  data
})
export const fetchStreamError = () => ({
  type: STREAM.STREAM_FETCH_ERROR
})
export const setVoucherPaymentStart = () => ({
  type: STREAM.STREAM_MAKE_VOUCHER_PAYMENT_START
})
export const setVoucherPaymentSuccess = (streamUrl: StreamUrls) => ({
  type: STREAM.STREAM_MAKE_VOUCHER_PAYMENT,
  data: { streamUrl }
})
export const setVoucherPaymentError = () => ({
  type: STREAM.STREAM_MAKE_VOUCHER_PAYMENT_ERROR
})
export const clearStream = () => ({
  type: STREAM.CLEAR_STREAM
})
export const incrementStreamUserLikeCount = () => ({
  type: STREAM.INCREMENT_STREAM_USER_LIKE_COUNT
})
export const decrementStreamUserLikeCount = () => ({
  type: STREAM.DECREMENT_STREAM_USER_LIKE_COUNT
})
