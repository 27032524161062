import { BroadcastThumbnailInterface } from '../../components/cards/BroadcastThumbnail/broadcastCardTypes'
import { TimelineObjectPath } from '../../helpers/PathHelper'
import { TimelineObject } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { mapTeamDataFromResponse } from './mapTeamFromResponse'

// https://sportstage.se/api/play_v1/timeline_objects/collections/highlights?per_page=16&page=1&company=mathias

export const getHighlightsForVideo = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/highlights',
      params: { company: slug, page: page, per_page: perPageCount }
    })
    const responseData = await resultResponse

    const map = mapHighlightsResponse(responseData.data)
    return { data: map, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapHighlightsResponse = (
  apiResponse: any
): BroadcastThumbnailInterface[] =>
  apiResponse.map((latestVideo: TimelineObject) => ({
    id: latestVideo.ident,
    channelName:
      latestVideo.display_company && latestVideo.display_company.name,
    channelImg: '',
    channelUrl:
      latestVideo.display_company && latestVideo.display_company.subdomain,
    targetUrl: TimelineObjectPath(latestVideo),
    country: latestVideo.country,
    type: latestVideo.type,
    startTime: latestVideo.live_start_at
      ? latestVideo.live_start_at
      : latestVideo.published_at,
    endTime: latestVideo.live_end_at,
    publishedAt: latestVideo.published_at,
    live: false,
    awayTeam: mapTeamDataFromResponse(latestVideo.away_team, 2),
    homeTeam: mapTeamDataFromResponse(latestVideo.home_team, 1),
    highlightFromDate: latestVideo.highlight_from_date,
    hasHighlight: latestVideo.has_highlight,
    isReplay: latestVideo.is_replay,
    viewers: latestVideo.number_of_viewers,
    showViewers: false,
    views: latestVideo.number_of_views,
    showViews: false, // latestVideo.show_views_or_viewers,
    title: latestVideo.title,
    thumbnailImageUrl: latestVideo.thumbnail,
    duration: latestVideo.duration
  }))
