/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback, FC } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { Breakpoint, useCurrentBreakpoint } from '../../hooks/CurrentBreakpoint'
import ViewerPageError from './Error/Error'
import { VideoPlayerData, ViewerParameters } from './viewerPage.interfaces'
import { getViewerParams } from './getViewerParams'
import menuItems, {
  ViewerPageMenuOption
} from './ViewerPageSubmenu/viewerPageSubmenuLinks'
import { channelSelector } from '../../redux-store/channel/channel.selectors'
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb'
import useCurrentUser from '../../hooks/CurrentUser'
import MobileSolidBackground from './BroadcastSection/MobileSolidBackground.png'
import SolidBackground from './BroadcastSection/SolidBackground.png'

import { ViewerPageComponent } from './ViewerPageComponent'
import { getBroadcastPath } from './getBroadcastPath'
import { StreamSubmenu } from './StreamSubmenu'
import ReportPage from './ReportPage/ReportPage'
import { paymentsSelector } from '../../redux-store/payments/payments.selectors'
import {
  streamIsLoadingSelector,
  streamSelector
} from '../../redux-store/stream/stream.selectors'

import { getSelectedLink } from './getSelectedLink'
import { getBreadcrumbTitle } from './getBreadcrumbTitle'
import { createGetStreamActions } from './createGetStreamActions'
import { useScrollToTop } from '../../helpers/useScrollToTop'
import MEDIA_OBJECT from '../../constants/media_object.constants'
import { ViewerPageTitle } from './ViewerPageTitle/ViewerPageTitle'
import { clearStream } from '../../redux-store/stream/stream.actions'
import { useChannelDataStatus } from '../ChannelPage/useChannelDataStatus'
import useCompanyParam from '../../hooks/CompanyParam'
import { RootState } from '../../redux-store/store'
import rolesActions from '../../redux-store/roles/roles.actions'
import { Loader } from 'components/Loader/Loader'
import { VideoMeta } from 'meta/VideoMeta'
import { crumbList } from './Breadcrumb/crumbList'
import { GeoBlock } from './GeoBlockedView/GeoBlock'

import { getViewerPageUrl } from './getViewerPageUrl'
import subscriberActions from 'redux-store/NchanSubscriber/subscriber.actions'
import { BannerAdSection } from './BannerAdSection/BannerAdSection'

import styles from './index.module.scss'

interface Props {
  isPaymentModalDefaultOpen: boolean
}
const ViewerPage: FC<Props> = ({ isPaymentModalDefaultOpen }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const company = useCompanyParam()
  const { ident } = useParams<{ ident?: string }>()
  const channel = useSelector(channelSelector)
  const roles = useSelector((state: RootState) => state.roles)
  const { channelDataIsReady } = useChannelDataStatus(channel, company)
  const currentBreakPoint = useCurrentBreakpoint()
  const { unlockedId, paymentsOpen } = useSelector(paymentsSelector)
  useScrollToTop()
  const windowLocation = window.location
  const viewParamsCallback = useCallback(getViewerParams, [])
  const [parameters, setParameters] = useState<ViewerParameters | undefined>(
    undefined
  )
  const [isGamePath, setIsGamePath] = useState(
    location.pathname.includes('/games/g/')
  )
  useEffect(() => {
    if (location) {
      setIsGamePath(location.pathname.includes('/games/g/'))
    }
  }, [location])

  const viewAsAdmin = roles.isViewingAsAdmin
  const setViewAsAdmin = () => dispatch(rolesActions.setViewingAsAdmin())

  const getCurrentUrl = useCallback(() => {
    return window.origin + location.pathname
  }, [ident])

  useEffect(() => {
    if (channelDataIsReady) {
      setParameters({
        ...viewParamsCallback({
          isGamePath,
          company,
          ident,
          currentUrl: getCurrentUrl()
        }),
        hasAdminAccess: viewAsAdmin
      })
    }
  }, [
    company,
    ident,
    viewParamsCallback,
    channelDataIsReady,
    isGamePath,
    getCurrentUrl,
    viewAsAdmin
  ])

  const stream = useSelector(streamSelector)
  const mediaPlayerData: VideoPlayerData = stream.data
  const { hasGame, canComment } = stream

  const [isLiked, setIsLiked] = useState(false)

  const streamIsLoading = useSelector(streamIsLoadingSelector)

  useEffect(() => {
    if (mediaPlayerData?.mediaObject?.event_source_uri) {
      dispatch(
        subscriberActions.connect(
          mediaPlayerData?.mediaObject?.event_source_uri
        )
      )
    }
    return () => {
      dispatch(subscriberActions.disconnect())
    }
  }, [mediaPlayerData?.mediaObject?.event_source_uri])

  const getVideoPlayerDataObject = useCallback(
    async (mediaObjectParams: ViewerParameters) => {
      if (!streamIsLoading) {
        await createGetStreamActions(dispatch, mediaObjectParams)
      }
    },
    [dispatch, streamIsLoading]
  )

  useEffect(() => {
    if (parameters) {
      getVideoPlayerDataObject(parameters)
    }
    return () => {
      dispatch(clearStream())
    }
  }, [currentUser, parameters, dispatch])

  useEffect(() => {
    if (paymentsOpen && unlockedId && parameters) {
      getVideoPlayerDataObject(parameters)
    }
  }, [paymentsOpen, unlockedId, parameters])

  useEffect(() => {
    if (viewAsAdmin && currentUser && parameters?.hasAdminAccess) {
      getVideoPlayerDataObject(parameters)
    }
  }, [viewAsAdmin, currentUser, parameters])

  const { pathname } = location

  const selectedLink = getSelectedLink(menuItems, pathname)

  const fallbackMenuId = ViewerPageMenuOption.AboutStream

  const initialSelectedMenu = selectedLink ? selectedLink.id : fallbackMenuId
  const [selectedMenu, setSelectedMenu] = useState(initialSelectedMenu)

  useEffect(() => {
    const items = menuItems.filter((link) =>
      link.id === ViewerPageMenuOption.Chat ? canComment : true
    )
    if (items.length === 1) {
      setSelectedMenu(items[0].id)
    } else {
      setSelectedMenu(initialSelectedMenu)
    }
  }, [canComment, selectedLink, initialSelectedMenu])

  const broadcastPath =
    mediaPlayerData &&
    getBroadcastPath(pathname, channel?.data?.subdomain, mediaPlayerData)
  const handleSelectSubmenu = (id: number) => {
    setSelectedMenu(id)
  }
  const backgroundImage =
    currentBreakPoint > Breakpoint.sm ? SolidBackground : MobileSolidBackground

  const mediaObject = mediaPlayerData?.mediaObject
  const breadcrumbTitle = getBreadcrumbTitle(mediaPlayerData)
  if (mediaPlayerData?.streamUrl?.geoBlock) {
    return (
      <GeoBlock
        isRootChannel={channel?.data?.isRootChannel}
        parentChannelSubdomain={channel?.data?.parentChannelSubdomain}
        channelName={channel?.data?.name}
        channelSlug={channel?.data?.subdomain}
        broadcastTitle={mediaObject?.title}
        broadcastSlug={windowLocation.pathname}
        backgroundImage={backgroundImage}
      />
    )
  }
  if (stream.isError) {
    return (
      <div className={styles.ViewerPageContainer}>
        <ViewerPageError
          homePage={`/${channel?.data.subdomain}` || '/'}
          backgroundImage={backgroundImage}
        />
      </div>
    )
  }
  if (stream.isLoading) {
    return (
      <div className={styles.LoadingState}>
        <Loader />
      </div>
    )
  }

  const showChat =
    mediaObject?.type === MEDIA_OBJECT.TYPES.LIVESTREAM ||
    mediaObject?.is_replay

  const crumbs = crumbList({
    isRootChannel: channel?.data?.isRootChannel,
    parentChannelSubdomain: channel?.data?.parentChannelSubdomain,
    channelName: channel?.data?.name,
    channelSlug: channel?.data?.subdomain,
    broadcastTitle: breadcrumbTitle,
    broadcastSlug: windowLocation.pathname
  })

  let PageMetaData = <></>
  try {
    PageMetaData =
      channel?.data && mediaObject ? (
        <VideoMeta
          channel={channel.data}
          mediaObject={mediaObject}
          breadcrumbs={crumbs}
        />
      ) : (
        <></>
      )
  } catch (e) {
    console.log('Could not set headerData in Viewer Page', e)
  }

  const title = mediaObject?.title
  const rootDomain =
    channel?.data?.root_domain || channel?.data?.root_channel?.root_domain
  const viewerPageUrl = getViewerPageUrl(mediaObject, rootDomain)

  return (
    <div className={styles.ViewerPageContainer}>
      {PageMetaData}
      <ViewerPageTitle
        title={title}
        rootDomain={rootDomain}
        viewerPageUrl={viewerPageUrl}
      />

      <div className={styles.BreadcrumbContainer}>
        <Breadcrumb pathList={crumbs} />
      </div>
      <BannerAdSection />
      {mediaPlayerData?.mediaObjectAvailable && (
        <ViewerPageComponent
          isLoading={stream.isLoading}
          mediaPlayerData={mediaPlayerData}
          isLiked={isLiked}
          setIsLiked={setIsLiked}
          viewAsAdmin={viewAsAdmin}
          setViewAsAdmin={setViewAsAdmin}
          viewerParameters={parameters!}
          currentUser={currentUser}
          company={company}
          ident={ident}
          isPaymentModalDefaultOpen={isPaymentModalDefaultOpen}
        />
      )}
      {mediaPlayerData && !mediaPlayerData?.mediaObjectAvailable && (
        <ReportPage
          game={mediaPlayerData!.game!}
          companySlug={channel?.data?.subdomain}
        />
      )}
      {broadcastPath && (
        <StreamSubmenu
          mediaObject={mediaObject}
          selectedMenu={selectedMenu}
          broadcastPath={broadcastPath}
          shouldHaveChat={showChat}
          mediaPlayerData={mediaPlayerData}
          handleSelectSubmenu={handleSelectSubmenu}
          canComment={canComment}
          hasGame={hasGame}
        />
      )}
    </div>
  )
}

export default ViewerPage
