import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import { Field, FormikProps } from 'formik'
import { Price } from 'interfaces/Price.interface'
import styles from './PriceFields.module.scss'

interface Props {
  formik: FormikProps<any>
  prices: Price
}

export const PriceFields: FC<Props> = ({ formik, prices }) => {
  return (
    <div className={styles.PriceFields}>
      {Object.entries(prices).map(([currency]) => {
        return (
          <Field
            component={TextInput}
            key={currency}
            id={`price.${currency}`}
            name={`price.${currency}`}
            label={t`${currency} incl. tax`}
            placeholder={t`Write price in ${currency} here`}
            onChange={formik.handleChange}
            textValue={formik.values.price[currency]}
            isValid={!formik.errors.price}
            validationMessage={formik.errors.price}
            isRequired={false}
          />
        )
      })}
    </div>
  )
}
