import { ActivityLog } from '../../../../../interfaces'
import MessageCirkle from './ReporterIcons/message-circle.png'
import StopWatchIcon from './ReporterIcons/stop-watch.png'

import RotatedWhistleIcon from './ReporterIcons/rotated-whistle.png'

export const reporterImage = (activityLog: ActivityLog) => {
  switch (activityLog.activity_type) {
    case 'comment':
      return MessageCirkle
    case 'game_start':
      return RotatedWhistleIcon
    case 'game_end':
      return StopWatchIcon
    case 'overtime':
      return StopWatchIcon
    case 'period_start':
      return StopWatchIcon
    case 'period_end':
      return StopWatchIcon
    case 'penalty':
      return RotatedWhistleIcon
    case 'score':
      return activityLog.badge
    default:
      return activityLog.badge
  }
}
