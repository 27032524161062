import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { languageSelector } from 'redux-store/language/language.selectors'
import { locales } from 'translations/i18n'
import styles from './LanguageMenuLink.module.scss'
import { Locale } from 'components/page/footer/themedFooter/ThemedFooter'
import { EarthIcon } from 'assets/icons/EarthIcon'

interface Props {
  onClick: () => void
}

export const LanguageMenuLink: FC<Props> = ({ onClick }) => {
  const currentLanguage = useSelector(languageSelector)

  const selectedLanguageLabel = locales.find(
    (language: Locale) => language.abbr === currentLanguage
  )
  return (
    <div
      className={styles.LanguageMenuLink}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
    >
      <EarthIcon />
      {selectedLanguageLabel?.name}
    </div>
  )
}
