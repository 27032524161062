/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { t } from '@lingui/macro'
import PAYMENTS from '../../../../redux-store/payments/payments.constants'
import { PaymentPropsType } from '../../viewerPage.interfaces'
import BuyOptionBannerFactory, {
  BUY_OPTIONS
} from '../BuyOptionBanner/BuyOptionBannerFactory'
import styles from './BuyBannerView.module.scss'

import { PaymentDetails } from '../PaymentModal'
import usePriceFromPreference from 'hooks/PriceFromPreference'
import { getTimeFrame } from 'helpers/getTimeFrame'

interface AllAccessViewProps {
  subscriptions: PaymentPropsType[]
  defaultCurrency: string
  onSelect: (payment: PaymentPropsType, paymentDetails: PaymentDetails) => void
}

export const AllAccessView: FC<AllAccessViewProps> = ({
  subscriptions,
  defaultCurrency,
  onSelect
}) => {
  const allAccessSubscriptions =
    subscriptions && subscriptions.length > 0
      ? subscriptions.filter((subs) => subs.all_access)
      : null
  return (
    <>
      {allAccessSubscriptions && allAccessSubscriptions.length > 0 && (
        <div className={styles.BuyViewContainer}>
          <div
            data-testid="buy-banner-all-access-heading"
            className={styles.BannerHeading}
          >{t`Buy access to all streams`}</div>
          <div className={styles.SubscriptionSection}>
            {allAccessSubscriptions.map((subscription) => {
              const { price, title, timeframe } = subscription
              const subscriptionPrice = usePriceFromPreference(price!)
              const onPaymentSelect = () => {
                onSelect(subscription, {
                  type: PAYMENTS.TYPE_SUBSCRIPTION,
                  subscription,
                  value: subscriptionPrice!.amount!,
                  currency: subscriptionPrice!.currency!,
                  mediaObjectTitle: title
                })
              }
              return (
                <BuyOptionBannerFactory
                  key={title}
                  option={BUY_OPTIONS.ALL_ACCESS}
                  amount={subscriptionPrice ? subscriptionPrice?.amount! : 0}
                  onSelect={onPaymentSelect}
                  currency={
                    subscriptionPrice
                      ? subscriptionPrice.currency
                      : defaultCurrency
                  }
                  duration={getTimeFrame(timeframe) || ''}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
