import React, { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Submenu } from '../../../../components/page/Submenu/Submenu'
import { contentManagerSubmenuLinks } from './contentManagerSubmenuLinks'
import { getSelectedLink } from '../../../ViewerPage/getSelectedLink'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import { rolesSelector } from '../../../../redux-store/roles/roles.reducer'
import { RolesState } from '../../../../redux-store/roles/roles.interface'
import { getFilteredContentManagerMenuLinks } from './getFilteredContentManagerMenuLinks'
import { getDefaultSelectedLink } from './getDefaultSelectedLink'

interface Props {
  hasActiveIntegration: boolean
}
export const ContentManagerSubmenu: FC<Props> = ({ hasActiveIntegration }) => {
  const location = useLocation()
  const channel = useSelector(channelSelector)
  const channelType = channel.data?.type
  const channelSlug = channel.data?.subdomain
  const roles: RolesState = useSelector(rolesSelector)

  const { pathname } = location
  const selectedLink = getSelectedLink(contentManagerSubmenuLinks, pathname)

  const [selectedMenu, setSelectedMenu] = useState(
    !selectedLink
      ? getDefaultSelectedLink(hasActiveIntegration, channelType)
      : selectedLink.id
  )

  const handleSelectSubmenu = (id: number) => {
    setSelectedMenu(id)
  }
  const filteredMenuItems = getFilteredContentManagerMenuLinks(
    contentManagerSubmenuLinks,
    roles,
    channelType,
    hasActiveIntegration
  )
  return (
    <Submenu
      selectedItemId={selectedMenu}
      menuItems={filteredMenuItems}
      onItemSelect={(id: number) => handleSelectSubmenu(id)}
      currentPage={`${channelSlug}/contentmanager`}
    />
  )
}
