/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'

import { AboutStream } from './AboutStream/AboutStream'
import GameInteractions from './GameInteractions'
import { Statistics } from './Statistics/Statistics'
import { ViewerPageSubmenu } from './ViewerPageSubmenu/ViewerPageSubmenu'
import { Lineups } from './Lineups/Lineups'
import { useSelector } from 'react-redux'
import { isSwedishHandballSelector } from 'redux-store/channel/channel.selectors'

interface Props {
  mediaObject: any
  selectedMenu: number
  broadcastPath: string
  shouldHaveChat: boolean
  mediaPlayerData: any
  handleSelectSubmenu: (id: number) => void
  canComment: boolean
  hasGame: boolean
}

export const StreamSubmenu: FC<Props> = ({
  mediaObject,
  selectedMenu,
  broadcastPath,
  shouldHaveChat,
  mediaPlayerData,
  handleSelectSubmenu,
  canComment,
  hasGame
}) => {
  const handleItemSelect = (id: number) => handleSelectSubmenu(id)
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  const showGameLineups = isSwedishHandball

  return (
    <>
      <ViewerPageSubmenu
        onItemSelect={handleItemSelect}
        selectedItemId={selectedMenu}
        broadcastPath={broadcastPath}
        pageHasChat={shouldHaveChat}
        canComment={canComment}
        hasGame={hasGame}
      />
      <Switch>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/chat',
            '/:company/watch/:ident/chat',
            '/:company/games/g/:ident/comments',
            '/:company/watch/:ident/comments'
          ]}
        >
          {canComment ? (
            <GameInteractions mediaObject={mediaObject} />
          ) : (
            <AboutStream mediaPlayerData={mediaPlayerData} />
          )}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/statistics',
            '/:company/watch/:ident/statistics'
          ]}
        >
          {hasGame && <Statistics />}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/lineup',
            '/:company/watch/:ident/lineup'
          ]}
        >
          {showGameLineups && <Lineups />}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident',
            '/:company/watch/:ident',
            '/:company/games/g/:ident/about',
            '/:company/games/g/:ident/payment',
            '/:company/watch/:ident/about',
            '/:company/watch/:ident/payment'
          ]}
        >
          <AboutStream mediaPlayerData={mediaPlayerData} />
        </Route>
      </Switch>
    </>
  )
}
