import { GameScheduleEventCardType } from 'components/cards/GameScheduleCard/gameScheduleType'
import { TimelineObject } from 'interfaces'
import { createTeamAbbreviation } from './createTeamAbbreviation'

export const mapEventResponse = (
  response: TimelineObject[],
  isUpcoming: boolean
) =>
  response.map((gameEvent: TimelineObject): GameScheduleEventCardType => {
    const channelSlug =
      gameEvent?.display_company.subdomain ||
      gameEvent?.event?.company?.subdomain
    const eventSlug = gameEvent.event.slug
    return {
      id: gameEvent.ident,
      channelName: gameEvent?.display_company.name,
      channelSlug,
      label: gameEvent?.event?.name || gameEvent?.sport?.name,
      labelSlug: gameEvent?.event?.company?.subdomain,
      startTime: gameEvent.start_at,
      endTime: gameEvent.end_at,
      hasLivestream: gameEvent.has_livestream,
      hasReplay: gameEvent.is_replay,
      hasBeenPlayed: !isUpcoming,
      showResult: isUpcoming ? false : gameEvent.show_results,
      highlightSlug: gameEvent.highlight_slug,
      homeTeam: mapTeam({
        name: gameEvent.home_team.name,
        slug: gameEvent.home_team.slug,
        logo: gameEvent.home_team.badge_image,
        scoreValue: gameEvent.home_score
      }),
      awayTeam: mapTeam({
        name: gameEvent.away_team.name,
        slug: gameEvent.away_team.slug,
        logo: gameEvent.away_team.badge_image,
        scoreValue: gameEvent.away_score
      }),
      eventClass: mapClass(gameEvent.groups, channelSlug, eventSlug),
      location:
        gameEvent.location?.name && gameEvent.event
          ? {
              link:
                gameEvent.event.slug &&
                `/${gameEvent.event?.company?.subdomain}/games/event/${gameEvent.event.slug}/locations/${gameEvent.location.slug}`,
              text: gameEvent.location.name
            }
          : undefined
    }
  })

interface TeamResponse {
  name: string
  slug: string
  logo: { lg: string }
  scoreValue: number
}

const mapTeam = ({ name, slug, logo, scoreValue }: TeamResponse) => ({
  name: name === null ? '-' : name,
  slug: slug,
  logo: logo.lg,
  score: scoreValue === null ? 0 : scoreValue,
  abbreviation: createTeamAbbreviation(name)
})

const mapClass = (groups: any, subdomain: string, eventSlug: string) => {
  if (groups && groups.length > 0) {
    const link = `/${subdomain}/games/event/${eventSlug}/classes/${groups[0].slug}`
    const firstLevel = {
      text: groups[0].name,
      link
    }
    return groups.length === 1
      ? firstLevel
      : {
          text: groups[1].name,
          link: `${firstLevel.link}/groups/${groups[1].slug}`
        }
  }
  return undefined
}
