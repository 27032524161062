import { uiConstants } from './userInterface.reducer'

const openMenu = () => ({
  type: uiConstants.OPEN_MENU
})

const closeMenu = () => ({
  type: uiConstants.CLOSE_MENU
})

const openReportContentDialog = () => ({
  type: uiConstants.OPEN_REPORT_CONTENT_DIALOG
})

const closeReportContentDialog = () => ({
  type: uiConstants.CLOSE_REPORT_CONTENT_DIALOG
})
const openBroadcastInformationModal = () => ({
  type: uiConstants.OPEN_BROADCAST_INFROMATION_MODAL
})
const closeBroadcastInformationModal = () => ({
  type: uiConstants.CLOSE_BROADCAST_INFROMATION_MODAL
})
const openQuickEditContentDialog = () => ({
  type: uiConstants.OPEN_QUICK_EDIT_DIALOG
})
const closeQuickEditContentDialog = () => ({
  type: uiConstants.CLOSE_QUICK_EDIT_DIALOG
})
export const userInterface = {
  openMenu,
  closeMenu,
  openReportContentDialog,
  closeReportContentDialog,
  openBroadcastInformationModal,
  closeBroadcastInformationModal,
  openQuickEditContentDialog,
  closeQuickEditContentDialog
}
