/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { t } from '@lingui/macro'
import usePriceFromPreference from '../../../hooks/PriceFromPreference'
import { ActionPanel } from '../ActionPanel/ActionPanel'
import { PaymentModal } from '../PaymentModal/PaymentModal'
import { VideoPlayerData } from '../viewerPage.interfaces'
import { BroadcastSectionDetails } from './BroadcastSectionDetails/BroadcastSectionDetails'
import { CompetitionInterface } from './BroadcastSectionDetails/CompetionText/competitionInterface'
import authenticationActions from '../../../redux-store/authentication/authentication.actions'
import useCurrentUser from '../../../hooks/CurrentUser'
import ShareModal from '../../../components/Modals/ShareModal'
import { EventSummary } from '../ActionPanel/ActionPanel.interface'
import { getPriceDependingOnAccessRestriciton } from './getPriceDependingOnAccessRestriciton'
import { onHelpClick } from '../VideoPlayerSection/onHelpClick'

import styles from './BroadcastSection.module.scss'
import { AccessRestrictionType } from 'interfaces'

interface Props {
  channelName: string
  title: string
  producer: string | undefined
  competition: CompetitionInterface
  mediaPlayerData: VideoPlayerData
  onWatch: () => void
  onDownload: () => void
  onLike: () => void
  isLiked: boolean
  onReportStream: () => void
  isPaymentModalDefaultOpen: boolean
}

export const BroadcastSection: FC<Props> = ({
  channelName,

  title,
  producer,
  competition,
  mediaPlayerData,
  onWatch,
  onDownload,
  onLike,
  isLiked,
  onReportStream,
  isPaymentModalDefaultOpen
}) => {
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false)
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false)
  const accessRestriction = mediaPlayerData?.mediaObject?.access_restriction
  const broadcastChannel = mediaPlayerData?.mediaObject?.company.name
  const broadcastTitle = mediaPlayerData?.mediaObject?.title
  const priceToTransform = getPriceDependingOnAccessRestriciton(
    accessRestriction,
    mediaPlayerData
  )

  const price = usePriceFromPreference(priceToTransform)

  const {
    mediaObject: {
      live_start_at,
      published_at,
      is_replay,
      is_live_and_started,
      type,
      slug,
      company
    }
  } = mediaPlayerData
  const event: EventSummary = {
    liveStartsAt: live_start_at,
    publishedAt: published_at,
    isReplay: is_replay,
    isLive: is_live_and_started,
    homeTeamName: '',
    awayTeamName: '',
    isVideo: type === 'video',
    title: mediaPlayerData.mediaObject.title,
    minEventAmount: price.amount ? price.amount : 0,
    amountCurrency: price.currency ? price.currency : 'SEK',
    accessRestriction: accessRestriction
  }

  const currentUser = useCurrentUser()
  useEffect(() => {
    if (isPaymentModalDefaultOpen) {
      handleOnPurchase()
    }
  }, [isPaymentModalDefaultOpen])
  const dispatch = useDispatch()
  // eslint-disable-next-line consistent-return
  const handleOnPurchase = () => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      if (currentUser) {
        setPaymentModalIsOpen(true)
      }

      return false
    }
    setPaymentModalIsOpen(true)
  }

  const handleOnShare = () => {
    setShareModalIsOpen(true)
  }

  const onEdit = () => {
    const baseURL = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}/admin`
    const trailURL = `${slug}/edit?select_company=${company.subdomain}`

    if (type === 'livestream' || (type === 'video' && is_replay)) {
      window.location.href = `${baseURL}/livestreams/${trailURL}`
    } else if (type === 'video') {
      window.location.href = `${baseURL}/videos/${trailURL}`
    }
  }
  const paymentModalDescription = [
    AccessRestrictionType.SubscriptionRequired
  ].includes(accessRestriction)
    ? t`Get access to this stream and all other exclusive content from ${channelName} with a subscription.`
    : t`Get full access to this stream from ${channelName}.`
  return (
    <div className={styles.BroadcastSection}>
      <BroadcastSectionDetails
        title={title}
        producer={producer}
        competition={competition}
        thumbnailContent={mediaPlayerData.thumbnailObject!}
      />
      <div
        className={styles.ActionPanelContainer}
        data-testid="viewer-page-action-panel"
      >
        <ActionPanel
          broadcastMessage={mediaPlayerData?.mediaObject?.broadcast_message}
          isReport={false}
          isVideoPlaying={false}
          isLiked={isLiked}
          event={event}
          onDownload={onDownload}
          onLike={onLike}
          onShare={handleOnShare}
          onWatch={onWatch}
          onEdit={onEdit}
          onHelp={() => onHelpClick(broadcastChannel, broadcastTitle)}
          onPurchase={handleOnPurchase}
          onReportStream={onReportStream}
        />
      </div>
      {shareModalIsOpen && (
        <ShareModal
          defaultOpen={shareModalIsOpen}
          mediaId={mediaPlayerData?.mediaObject?.id}
          mediaTitle={
            mediaPlayerData?.mediaObject?.game?.title ||
            mediaPlayerData?.mediaObject?.title
          }
          companySlug={
            mediaPlayerData?.mediaObject?.game?.display_company?.subdomain
          }
          onClose={() => setShareModalIsOpen(false)}
        />
      )}
      {paymentModalIsOpen &&
        mediaPlayerData.mediaObjectAvailable &&
        mediaPlayerData.streamUrl!.paymentMethods && (
          <PaymentModal
            mediaObjectId={mediaPlayerData?.mediaObject?.id}
            paymentOptions={mediaPlayerData?.streamUrl!.paymentMethods}
            defaultCurrency="SEK"
            channelSlug={mediaPlayerData.mediaObject.company.subdomain}
            title={mediaPlayerData.mediaObject.title}
            description={paymentModalDescription}
            onClose={() => setPaymentModalIsOpen(false)}
          />
        )}
    </div>
  )
}
