import React, { FC } from 'react'

const DownloadIcon: FC = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.001 0.75V12.75"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50101 8.25L12.001 12.75L16.501 8.25"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.251 12.75V14.25C23.251 15.0456 22.9349 15.8087 22.3723 16.3713C21.8097 16.9339 21.0467 17.25 20.251 17.25H3.75101C2.95536 17.25 2.1923 16.9339 1.62969 16.3713C1.06708 15.8087 0.751007 15.0456 0.751007 14.25V12.75"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadIcon
