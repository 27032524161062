import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import {
  TextFormModal,
  TextFormModalSize
} from '../../../components/Modals/TextFormModal/TextFormModal'
import { PaymentMethods, PaymentPropsType } from '../viewerPage.interfaces'

import BuyBannersView from './Views/BuyBannersView'
import paymentActions from '../../../redux-store/payments/payments.actions'
import PAYMENTS from '../../../redux-store/payments/payments.constants'

import styles from './PaymentModal.module.scss'

export interface PaymentDetails {
  type: PAYMENTS
  mediaObjectId?: string
  mediaObjectTitle?: string
  subscription?: any
  value: number
  currency: string
}

interface PaymentModalProps {
  paymentOptions: PaymentMethods
  defaultCurrency: string
  title: string
  channelSlug: string
  description: string
  onClose: () => void
  mediaObjectId: string
}

export const PaymentModal: FC<PaymentModalProps> = ({
  paymentOptions,
  defaultCurrency,
  title,
  channelSlug,
  description,
  mediaObjectId,
  onClose
}) => {
  const dispatch = useDispatch()
  const onSelect = (
    payment: PaymentPropsType,
    paymentDetails: PaymentDetails
  ) => {
    const { type, value, currency } = paymentDetails

    /*
    // commented out for now, we need to add back later
    // when Tag Manager specs are better defined
    TagManager.dataLayer({
      dataLayer: {
        event: 'addToCart',
        currency: currency,
        items: type,
        value: value
      }
    })
    */
    dispatch(
      paymentActions.openPayment({
        type,
        value,
        currency,
        mediaObjectId,
        mediaObjectTitle: title,
        subscription: payment
      })
    )
    onClose()
  }
  return (
    <TextFormModal
      title={title}
      description={description}
      subTitle={t`BUY STREAM`}
      onClose={onClose}
      defaultOpen
      showSupportSection
      modalSize={TextFormModalSize.Large}
    >
      <div className={styles.PaymentContainer}>
        <BuyBannersView
          mediaObjectId={mediaObjectId}
          paymentOptions={paymentOptions}
          defaultCurrency={defaultCurrency}
          channelSlug={channelSlug}
          onSelect={onSelect}
          onClose={onClose}
        />
      </div>
    </TextFormModal>
  )
}
