import React, { FC, useCallback, useMemo } from 'react'

import ContentContainer from 'components/ContentContainer'
import { getTeamLineups } from 'api/gameStatistics/getTeamLineups'
import { useDataApi } from 'api/useDataApi'
import { Loader } from 'components/Loader/Loader'

import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { MediaObject } from 'interfaces'
import { Lineup, TeamLineups } from './TeamLineups/TeamLineups'
import { mapLineupData } from './mapLineupData'

export const Lineups: FC = () => {
  const stream = useSelector(streamSelector)
  const mediaObject: MediaObject = stream?.data?.mediaObject
  const gameId = mediaObject.game.id
  const parameters = useMemo(() => ({ gameId: gameId }), [gameId])
  const {
    data: lineups,
    isLoading,
    isError
  } = useDataApi({
    apiCall: useCallback(getTeamLineups, []),
    parameters
  })

  if (isLoading) return <Loader />

  if (isError || !lineups || lineups.length === 0) return null

  const gameHasBeenPlayed = mediaObject.game.is_replay
  const homeTeamLineup: Lineup = mapLineupData(
    lineups.homeTeam.playerList,
    lineups.homeTeam.coachList,
    mediaObject?.game?.home_team
  )

  const awayTeamLineup: Lineup = mapLineupData(
    lineups.awayTeam.playerList,
    lineups.awayTeam.coachList,
    mediaObject?.game?.away_team
  )
  return (
    <ContentContainer
      hasLeftRightPadding
      hasTopBottomPadding={true}
      hasLightBackground
    >
      <TeamLineups
        homeTeamLineup={homeTeamLineup}
        awayTeamLineup={awayTeamLineup}
        gameHasBeenPlayed={gameHasBeenPlayed}
        refereeList={lineups.referees}
      />
    </ContentContainer>
  )
}
