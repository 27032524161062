import React, { FC } from 'react'
import styles from './DefaultTeamBadge.module.scss'
import { ShieldIcon } from './ShieldIcon'

interface Props {
  teamName: string
  abbreviation?: string
}

export const DefaultTeamBadge: FC<Props> = ({ teamName, abbreviation }) => {
  const firstLetter = teamName.charAt(0)
  const abbreviationToDisplay = abbreviation && abbreviation.substring(0, 3)
  return (
    <div className={styles.Shield} data-testid="shield-container">
      <div className={styles.Text}>
        {abbreviation ? abbreviationToDisplay : firstLetter}
      </div>
      <ShieldIcon />
    </div>
  )
}
