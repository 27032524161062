import React, { FC } from 'react'
import styles from './index.module.scss'

interface Props {
  type: string
  name: string
}
const CompetitionText: FC<Props> = ({ type, name }) => {
  return (
    <div className={styles.CompetionTextContainer}>
      {type} <div className={styles.Name}>{name}</div>
    </div>
  )
}
export default CompetitionText
