import { t } from '@lingui/macro'
import { BreadCrumbLink } from '../../../components/Breadcrumb/breadcrumbLink'

interface CrumbListParams {
  isRootChannel: boolean
  parentChannelSubdomain: string
  channelName: string
  channelSlug: string
  broadcastTitle: string
  broadcastSlug: string
}

export const crumbList = ({
  channelName,
  channelSlug,
  broadcastSlug,
  isRootChannel,
  parentChannelSubdomain,
  broadcastTitle
}: CrumbListParams) => {
  const homeLink = !isRootChannel ? parentChannelSubdomain : ''
  const crumbs: BreadCrumbLink[] = [
    {
      name: t`Home`,
      link: homeLink === undefined || homeLink === null ? '' : homeLink
    },
    { name: channelName, link: channelSlug },
    { name: broadcastTitle, link: broadcastSlug }
  ]
  const pathList =
    isRootChannel || !parentChannelSubdomain ? crumbs.slice(1) : crumbs

  const filteredPathList = pathList.filter((path) => path.name !== undefined)
  return filteredPathList
}
