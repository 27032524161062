import React, { FC, useState } from 'react'
import { LineupItemProps } from '../LineupItem/LineupItem'
import styles from './TeamLineup.module.scss'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { Toggle } from 'components/Toggle/Toggle'
import { t } from '@lingui/macro'
import { MobileLineups } from './MobileLineups'
import { DesktopLineups } from './DesktopLineups'
import { Referees } from '../Referees/Referees'
import { Referee } from 'api/gameStatistics/getTeamLineups'
export interface Lineup {
  teamName: string
  teamLogo: string
  playerList: LineupItemProps[]
  coachList: LineupItemProps[]
}
interface Props {
  homeTeamLineup: Lineup
  awayTeamLineup: Lineup
  gameHasBeenPlayed: boolean
  refereeList: Referee[]
}

export const TeamLineups: FC<Props> = ({
  homeTeamLineup,
  awayTeamLineup,
  gameHasBeenPlayed,
  refereeList
}) => {
  const [homeTeamSelected, setHomeTeamSelected] = useState(true)

  const breakPoint = useCurrentBreakpoint()
  const showToggleButtons = breakPoint <= Breakpoint.sm
  const handleToggleClick = () => {
    setHomeTeamSelected(!homeTeamSelected)
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        {showToggleButtons && (
          <div className={styles.ToggleContainer}>
            <Toggle
              firstLabel={t`Home team`}
              secondLabel={t`Away team`}
              firstButtonSelected={homeTeamSelected}
              onClick={() => handleToggleClick()}
            />
          </div>
        )}
        <div className={styles.InnerContainer}>
          {showToggleButtons ? (
            <MobileLineups
              homeTeamLineup={homeTeamLineup}
              awayTeamLineup={awayTeamLineup}
              showHomeTeam={homeTeamSelected}
              gameHasBeenPlayed={gameHasBeenPlayed}
            />
          ) : (
            <DesktopLineups
              homeTeamLineup={homeTeamLineup}
              awayTeamLineup={awayTeamLineup}
              gameHasBeenPlayed={gameHasBeenPlayed}
            />
          )}
        </div>
        {refereeList.length > 0 && <Referees refereeList={refereeList} />}
      </div>
    </div>
  )
}
