import React, { FC } from 'react'
import { t } from '@lingui/macro'
import ReplayIcon from '../../../../assets/icons/ReplayIcon'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'

interface Props {
  id: string
  channelSlug: string
}
export const ReplayButton: FC<Props> = ({ id, channelSlug }) => (
  <LinkIconButton
    type={ButtonType.Secondary}
    buttonSize="Small"
    label={t`Replay`}
    id="replay"
    href={`/${channelSlug}/games/g/${id}`}
  >
    <ReplayIcon />
  </LinkIconButton>
)
